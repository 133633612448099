// Chakra imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormLabel,
  Icon,
  Link,
  Select,
  SimpleGrid,
  Text,
  background,
  useColorModeValue
} from '@chakra-ui/react'
import axios from '../../../api/axios'
// Assets
// Custom components
import IconBox from 'components/icons/IconBox'
import React, { useState, useEffect } from 'react'
import Doacoes from 'views/public/default/components/Doacoes'
import SignInButtons from 'views/public/default/components/SignInButtons'
import { TimeLineHome } from 'components/dataDisplay/timeLine'
import SignInForm from '../../auth/signIn/components/SignInForm'
import dog from 'assets/img/dog.jpg'

export default function PublicHome() {
  // Chakra Color Mode
  const brandColor = useColorModeValue('brand.500', 'white')
  const boxBg = useColorModeValue('#f2f6ef', 'whiteAlpha.100')

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <Flex
          p={5}
          direction="column"
          style={{
            // backgroundImage: `url(${dog})`,
            backgroundSize: 'cover',
            backgroundColor: 'rgba(255, 255, 255, 0.0)', // Overlay branco com 50% de opacidade
            borderRadius: '15px',
            backgroundPositionY: 'top',
            justifyContent: 'space-between',
            height: '500px',
            alignItems: 'center'
          }}
        >
          <SignInForm />
        </Flex>
        <Flex
          p={5}
          direction="column"
          // style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '15px' }}
          style={{
            backgroundImage: `url(${dog})`,
            backgroundSize: 'cover',
            backgroundColor: 'rgba(255, 255, 255, 0.5)', // Overlay branco com 50% de opacidade
            borderRadius: '15px',
            backgroundPositionY: 'top',
            justifyContent: 'space-between',
            height: '500px'
          }}
        >
          <Text fontSize={{ base: '24px', sm: '36px', md: '48px', xl: '64px' }} fontWeight={800}>
            Saúde preventiva.
            <Text fontWeight={800} fontSize="20px" mb="30px" w="70%">
              Ofereça um cuidado premium aos seus clientes com a primeira plataforma de pós vendas e
              relacionamento para clínicas veterinárias.
            </Text>
          </Text>
          <Link
            href="/public/sobre"
            variant="outline"
            style={{
              background: 'rgba(255, 255, 255, 0.3)',
              border: '1px solid #ffffff',
              borderRadius: '20px',
              height: '80px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            fontSize="24px"
            fontWeight={800}
          >
            Conheça mais
          </Link>
        </Flex>
      </SimpleGrid>
    </Box>
  )
}
