import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import { getUserAuthentication, getUserAccountId } from 'hooks/authService'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Flex,
  HStack,
  Input,
  Link,
  Textarea,
  SimpleGrid,
  Select,
  Stack,
  useToast,
  useColorModeValue,
  Text
} from '@chakra-ui/react'
import Card from '../../../components/card/Card'

export default function FakeComponent() {
  // const { accountData } = props
  const textColor = useColorModeValue('navy.700', 'white')

  // const userAccountId = getUserAccountId()

  // const [accountData, setAccountData] = useState({})

  // useEffect(() => {
  //   const getAccountData = async () => {
  //     try {
  //       const { data } = await axios(`/accounts/${userAccountId}`, { headers: authHeader() })
  //       setAccountData(data)
  //     } catch (error) {
  //       console.log('Erro ao carregar dados da conta: ', error.response.data)
  //     }
  //   }

  //   if (userAccountId) {
  //     return getAccountData()
  //   }
  // }, [])

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Card>
        <Text color={textColor}>Verifique backlog de desenvolvimento</Text>
      </Card>
    </Box>
  )
}
