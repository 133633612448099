// Chakra imports
import { Box, Flex, Text, Select, useColorModeValue, SimpleGrid } from '@chakra-ui/react'
// Custom components
import Card from 'components/card/Card.js'
import DonutChart from 'components/charts/DonutChart'
import { HSeparator } from 'components/separator/Separator'
import { VSeparator } from 'components/separator/Separator'
import React from 'react'

export default function DonutChartCardSpecies(props) {
  const { height, weight, labels, pieChartOptions, pieChartData, ...rest } = props

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const cardColor = useColorModeValue('white', 'navy.700')
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset')
  return (
    <Box align="center" direction="column" w="100%" {...rest}>
      {labels && (
        <Flex justifyContent="space-between" alignItems="center" w="100%">
          <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
            Especies
          </Text>
        </Flex>
      )}
      <Flex flexDirection="column-reverse">
        <DonutChart h={height} w={weight} chartData={pieChartData} chartOptions={pieChartOptions} />
        {!labels && <Text style={{ position: 'relative', top: '70px' }}>Especies</Text>}
        {labels && (
          <Flex flexDirection="column">
            {pieChartOptions.labels.length > 0 &&
              pieChartOptions.labels.map((item, index) => (
                <>
                  <Card py="0" pl="15px" mb="6px" bg={pieChartOptions.colors[index]}>
                    <Flex direction="row" align="center" alignItems="center" py="2px">
                      {/* <Flex align="center" alignItems="center"> */}
                      <Flex h="30px" w="30px" mr="6px">
                        {/* <Text fontSize="lg" color="white" fontWeight="700">
                          {pieChartData[index]}
                        </Text> */}
                      </Flex>
                      <Text fontSize="xs" color="white" fontWeight="700" mr="10px">
                        {item}
                      </Text>
                      {/* </Flex> */}
                    </Flex>
                  </Card>
                  {/* <HSeparator mx={{ base: '20px', xl: '20px', '2xl': '20px' }} /> */}
                </>
              ))}
          </Flex>
        )}
      </Flex>
    </Box>
  )
}
