import { Flex, Text } from '@chakra-ui/react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { FaWhatsapp } from 'react-icons/fa'

export default function WhatsAppConectionStatus({ settings }) {
  const [connectionState, setConnectionState] = useState()

  useEffect(() => {
    getWhatsAppStatus()
  }, [])

  async function getWhatsAppStatus() {
    try {
      const response = await axios.get(
        `https://wapi.vetsales.com.br/instance/connectionState/${settings.api_instance_name}`,
        {
          headers: {
            'Content-Type': 'application/json',
            apikey: settings.api_instance_token
          }
        }
      )
      console.log('getWhatsAppStatus:', response.data.instance.state)
      return setConnectionState(response.data.instance.state)
    } catch (error) {
      console.log('Erro', error)
    }
  }
  return (
    <Flex
      bg={'white'}
      borderRadius={'20px'}
      p="10px 20px"
      height={'fit-content'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Flex alignItems={'center'}>
        <FaWhatsapp color={connectionState === 'open' ? '#65e495' : '#fc8181'} size={'22px'} />
        <Text fontSize="18px" fontWeight="700" lineHeight="100%" ml="8px">
          WhatsApp
        </Text>
      </Flex>
      <Text
        fontWeight={'500'}
        fontSize={'18px'}
        color={connectionState === 'open' ? 'whatsapp.300' : 'red.300'}
      >
        {`${connectionState === 'open' ? 'Online' : 'Offline'}`}
      </Text>
    </Flex>
  )
}
