import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import { getUserAccount } from 'hooks/authService'
import {
  Alert,
  Avatar,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Flex,
  HStack,
  Input,
  Link,
  Textarea,
  SimpleGrid,
  Select,
  Switch,
  useToast,
  useColorModeValue,
  Text,
  IconButton,
  Tag,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody
} from '@chakra-ui/react'
import Card from 'components/card/Card'
import { Icon } from '@chakra-ui/react'
import { MdAdd } from 'react-icons/md'
import { FaEdit } from 'react-icons/fa'
import AnimalsForm from './animaisForm'
// import { getUserAccount } from 'hooks/authService'

export default function ClientAnimalsCards(props) {
  const {
    animal,
    animal: { name, species, breed, gender, weight, fur, birthdate, status },
    animalApproachs
  } = props
  const textColor = useColorModeValue('navy.700', 'white')

  const [editable, setEditable] = useState(false)

  function getAnimalGender(data) {
    let gender = 'Não identificado'
    if (data === 'female') gender = 'Fêmea'
    if (data === 'male') gender = 'Macho'

    return gender
  }

  function calcularIdade(birthdate) {
    const nascimento = new Date(birthdate)
    const hoje = new Date()

    let anos = hoje.getFullYear() - nascimento.getFullYear()
    let meses = hoje.getMonth() - nascimento.getMonth()

    if (meses < 0) {
      anos--
      meses += 12
    }

    return `${anos} anos, ${meses} meses`
  }

  function formatDate(dateString) {
    const date = new Date(dateString)
    const day = String(date.getDate()).padStart(2, '0')

    const monthNames = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
    const month = monthNames[date.getMonth()]

    return `${day}/${month}`
  }

  return (
    <Box>
      {animal && (
        <Card boxShadow="xl" p="6" borderRadius="20px" bg="white" mb="24px">
          <Flex justifyContent={'space-between'}>
            <Flex flexDirection={'column'} gap="20px">
              <Flex gap={'20px'}>
                <Avatar size="xl" name="" src={`clients/`} />
                <Flex flexDirection="column">
                  <Flex gap={'10px'}>
                    <Text fontWeight={'800'}>{name}</Text>
                    <Tag
                      colorScheme={status === 'Vivo' ? 'green' : 'blackAlpha'}
                      fontSize={'12px'}
                      borderRadius={'20px'}
                      width="fit-content"
                    >
                      {status}
                    </Tag>
                  </Flex>
                  <Text fontSize={'0.9rem'}>{`${species}  •  ${breed}`}</Text>
                  <Text fontSize={'0.9rem'}>{`${getAnimalGender(gender)}  •  ${fur}`}</Text>
                  <Text fontSize={'0.9rem'}>{calcularIdade(birthdate)}</Text>
                  <Text fontSize={'0.9rem'}>{weight}</Text>
                </Flex>
              </Flex>
              <Flex>oi </Flex>
              <Flex gap={'20px'}>
                <Flex flexDirection="column">
                  <Text fontWeight={800} mb="8px">
                    Últimas consultas
                  </Text>
                  <Flex gap={'5px'} wrap={'wrap'} maxWidth={'170px'}>
                    <Tag colorScheme={'linkedin'} fontSize={'12px'} borderRadius={'20px'} width="fit-content">
                      10/dez
                    </Tag>
                    <Tag colorScheme={'linkedin'} fontSize={'12px'} borderRadius={'20px'} width="fit-content">
                      24/ago
                    </Tag>
                    <Tag colorScheme={'linkedin'} fontSize={'12px'} borderRadius={'20px'} width="fit-content">
                      24/ago
                    </Tag>
                    <Tag colorScheme={'linkedin'} fontSize={'12px'} borderRadius={'20px'} width="fit-content">
                      24/ago
                    </Tag>
                    <Tag colorScheme={'linkedin'} fontSize={'12px'} borderRadius={'20px'} width="fit-content">
                      24/ago
                    </Tag>
                  </Flex>
                </Flex>
                <Flex flexDirection="column" gap="5px">
                  <Text fontWeight={800} mb="3px">
                    Abordagens
                  </Text>
                  <Flex gap="5px" maxWidth={'170px'} wrap={'wrap'}>
                    {animalApproachs &&
                      animalApproachs.map((approach) => (
                        <Tag
                          colorScheme={'linkedin'}
                          fontSize={'12px'}
                          borderRadius={'20px'}
                          width="fit-content"
                        >
                          {`${formatDate(approach.created_at)} • ${approach.script.campaign.subject}`}
                        </Tag>
                      ))}

                    <Tag colorScheme={'green'} fontSize={'12px'} borderRadius={'20px'} width="fit-content">
                      02/nov
                    </Tag>
                    <Tag colorScheme={'red'} fontSize={'12px'} borderRadius={'20px'} width="fit-content">
                      03/set
                    </Tag>
                    <Tag colorScheme={'green'} fontSize={'12px'} borderRadius={'20px'} width="fit-content">
                      03/set
                    </Tag>
                    <Tag colorScheme={'green'} fontSize={'12px'} borderRadius={'20px'} width="fit-content">
                      03/set
                    </Tag>
                    <Tag colorScheme={'red'} fontSize={'12px'} borderRadius={'20px'} width="fit-content">
                      03/set
                    </Tag>
                    <Tag colorScheme={'red'} fontSize={'12px'} borderRadius={'20px'} width="fit-content">
                      03/set
                    </Tag>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex>
              <Popover placement="right">
                <PopoverTrigger>
                  <IconButton
                    colorScheme="blue"
                    background="#49BFFF"
                    aria-label="Call Segun"
                    size="sm"
                    p="1px"
                    onClick={() => setEditable(!editable)}
                    icon={<Icon as={FaEdit} />}
                  />
                </PopoverTrigger>
                <PopoverContent width={'800px'}>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>Editar animal</PopoverHeader>
                  <PopoverBody>
                    <AnimalsForm
                      client_id={animal.owner_id}
                      account_id={animal.account_id}
                      animal_id={animal.id}
                    />
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Flex>
          </Flex>
        </Card>
      )}
    </Box>
  )
}
