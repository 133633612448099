export function formatPhoneNumber(number) {
  let formattedNumber = number.toString()

  // Remove all non-digit characters
  formattedNumber = formattedNumber.replace(/\D/g, '')

  // Check for country code and format accordingly
  if (formattedNumber.length === 12) {
    // Country code present (e.g., 551151283600)
    const countryCode = formattedNumber.substring(0, 2)
    const areaCode = formattedNumber.substring(2, 4)
    const firstPart = formattedNumber.substring(4, 8)
    const secondPart = formattedNumber.substring(8, 12)
    return `${countryCode} (${areaCode}) ${firstPart}-${secondPart}`
  } else if (formattedNumber.length === 10) {
    // Landline number without country code (e.g., 1151283600)
    const areaCode = formattedNumber.substring(0, 2)
    const firstPart = formattedNumber.substring(2, 6)
    const secondPart = formattedNumber.substring(6, 10)
    return `55 (${areaCode}) ${firstPart}-${secondPart}`
  } else if (formattedNumber.length === 13) {
    // Mobile number with country code (e.g., 5521999612288)
    const countryCode = formattedNumber.substring(0, 2)
    const areaCode = formattedNumber.substring(2, 4)
    const firstPart = formattedNumber.substring(4, 9)
    const secondPart = formattedNumber.substring(9, 13)
    return `${countryCode} (${areaCode}) ${firstPart}-${secondPart}`
  } else if (formattedNumber.length === 11) {
    // Mobile number without country code (e.g., 21992190830)
    const areaCode = formattedNumber.substring(0, 2)
    const firstPart = formattedNumber.substring(2, 7)
    const secondPart = formattedNumber.substring(7, 11)
    return `55 (${areaCode}) ${firstPart}-${secondPart}`
  } else {
    return 'Número inválido'
  }
}
