import { Flex, Text } from '@chakra-ui/react'
import { FaHeadset } from 'react-icons/fa'

export default function WhatsAppConectionStatus() {
  const connectionState = 'open'
  return (
    <Flex
      bg={'white'}
      borderRadius={'20px'}
      p="10px 20px"
      height={'fit-content'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Flex alignItems={'center'}>
        <FaHeadset color={connectionState === 'open' ? '#65e495' : '#fc8181'} size={'22px'} />
        <Text fontSize="18px" fontWeight="700" lineHeight="100%" ml="8px">
          Suporte VetSales
        </Text>
      </Flex>
      <Text
        fontWeight={'500'}
        fontSize={'18px'}
        color={connectionState === 'open' ? 'whatsapp.300' : 'red.300'}
      >
        {`${connectionState === 'open' ? 'Online' : 'Offline'}`}
      </Text>
    </Flex>
  )
}
