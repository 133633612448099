// Chakra imports
import { Box, Flex, Text, Icon, useColorModeValue, Checkbox, Tag } from '@chakra-ui/react'
// Custom components
import Card from 'components/card/Card.js'
import Menu from 'components/menu/MainMenu'
import IconBox from 'components/icons/IconBox'

// Assets
import {
  MdDone,
  MdDragIndicator,
  MdCallMade,
  MdCallReceived,
  MdSlowMotionVideo,
  MdEmail,
  MdChat,
  MdAnnouncement
} from 'react-icons/md'
import React, { useEffect, useState } from 'react'
import axios from 'api/axios'
import { Tooltip } from '@chakra-ui/react'
import ExpandableText from './ExpandableText'
import ActivityItem from './ActivityItem'
import LastApproachs from './LastApproachs'
import authHeader from 'hooks/authHeader'
import { formatDateTime } from 'hooks/formatDateTime'
import {
  FaCheck,
  FaCheckDouble,
  FaClock,
  FaComments,
  FaComment,
  FaCommentDots,
  FaHourglassHalf,
  FaPaw,
  FaUser
} from 'react-icons/fa'

export default function Conversion(props) {
  const { accountId, approachs, ...rest } = props

  const sortedApproachs =
    approachs && approachs.data
      ? [...approachs.data].sort((a, b) => {
          return new Date(b.updated_at) - new Date(a.updated_at)
        })
      : []

  console.log('approachsapproachs', approachs)

  const [feedData, setFeedData] = useState()

  // useEffect(() => {
  //   async function fetchData() {
  //     const { data } = await axios.get(`/approachs?account_id=${accountId}`, {
  //       headers: authHeader()
  //     })
  //     setFeedData(data.data)
  //     console.log('Últimas Abordagens', data.data)
  //     return data
  //   }
  //   fetchData()
  // }, [])

  const formatDate = (date) => {
    const [datePart, timePart] = date.split('T')
    const [hourPart, GTM] = timePart.split('+')

    return (
      <span>
        <span style={{ fontSize: 'smaller', fontWeight: '500' }}>{datePart}</span>
        <br />
        <span>{hourPart}</span>
      </span>
    )
  }

  const ActivityType = ({ value }) => {
    switch (value) {
      case 'CRM_EMAIL':
        return <Icon as={MdEmail} width="20px" height="20px" mr={3} color="inherit" />
      case 'CRM_TODO':
        return <Icon as={MdChat} width="20px" height="20px" mr={3} color="inherit" />
      case 'CRM_TASKS_TASK':
        return <Icon as={MdAnnouncement} width="20px" height="20px" mr={3} color="inherit" />
      case 'VOXIMPLANT_CALL':
        return <Icon as={MdAnnouncement} width="20px" height="20px" mr={3} color="inherit" />
      case 'CRM_WEBFORM':
        return <Icon as={MdAnnouncement} width="20px" height="20px" mr={3} color="inherit" />

      default:
        // return null;
        return value
    }
  }
  const DirectionIcon = ({ value }) => {
    switch (value) {
      case '0':
        return <Icon as={MdSlowMotionVideo} width="20px" height="20px" mr={3} color="inherit" />
      case '1':
        return <Icon as={MdCallReceived} width="20px" height="20px" mr={3} color="inherit" />
      case '2':
        return <Icon as={MdCallMade} width="20px" height="20px" mr={3} color="inherit" />
      default:
        return null
    }
  }

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const boxBg = useColorModeValue('secondaryGray.300', 'navy.700')
  const brandColor = useColorModeValue('brand.500', 'brand.400')

  function getIcon(data) {
    switch (data) {
      case 'invoiced':
        return <Icon color={'blackAlpha.400'} as={FaCheck} mr="16px" />
      case 'sent':
        return <Icon color={'blackAlpha.400'} as={FaCheckDouble} mr="16px" />
      case 'received':
        return <Icon color={'whatsapp.300'} as={FaCheckDouble} mr="16px" />
      case 'read':
        return <Icon color={'blue.300'} as={FaCheckDouble} mr="16px" />
      case 'responded':
        return <Icon color={'blue.300'} as={FaComments} mr="16px" />
      case 'scheduled':
        return <Icon color={'blackAlpha.400'} as={FaClock} mr="16px" />
      default:
        return null
    }
  }

  return (
    <Card p="20px" align="center" direction="column" w="100%" {...rest}>
      <Flex alignItems="center" w="100%" mb="30px">
        <Icon as={MdDone} width="20px" height="20px" mr={3} color="inherit" />

        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          Últimas abordagens
        </Text>
        {/* <Menu ms="auto" /> */}
      </Flex>
      <Flex
        flexDirection={'column'}
        gap={'10px'}
        overflowY="auto"
        maxHeight="250px"
        px="10px"
        sx={{
          '::-webkit-scrollbar': {
            width: '4px', // Largura da barra de rolagem
            borderRadius: '8px' // Borda arredondada para a barra
          },
          '::-webkit-scrollbar-track': {
            background: 'transparent' // Fundo transparente para a trilha
          },
          '::-webkit-scrollbar-thumb': {
            background: 'gray.300', // Cor da barra de rolagem
            borderRadius: '8px' // Borda arredondada para o polegar
          }
        }}
      >
        {sortedApproachs &&
          sortedApproachs.map((approach) => (
            <Flex
              flexDirection="row"
              p="5px 5px"
              borderRadius={'20px'}
              gap={'10px'}
              justifyContent={'space-between'}
              key={approach.id}
            >
              <Flex alignItems={'center'}>
                <Tag
                  colorScheme="blue"
                  borderRadius={'20px'}
                  mr="15px"
                  fontSize={'12px'}
                  w={'107px'}
                  justifyContent="center"
                >
                  {formatDateTime(approach.updated_at)}
                </Tag>
                <Tooltip
                  hasArrow
                  label={approach.message}
                  fontSize="14px"
                  bg="whatsapp.100"
                  color="black"
                  p="20px"
                  borderRadius="10px"
                  placement="left-end"
                >
                  {/* <span>
                    <Icon
                      color={'blackAlpha.400'}
                      as={FaCommentDots}
                      ml="0px"
                      mr="15px"
                      color="blackAlpha.400"
                    />
                  </span> */}
                  <Flex>
                    <Icon color={'blackAlpha.400'} as={FaUser} mr="5px" ml="10px" />
                    <Text mr="5px" fontWeight={'600'} w={'90px'} textAlign={'left'}>
                      {approach.owner.first_name}
                    </Text>
                  </Flex>
                </Tooltip>
                <Icon color={'blackAlpha.400'} as={FaPaw} ml="16px" mr="5px" />
                <Text mr="5px"> {`${approach.animal.name}, ${approach.animal.species}`}</Text>
              </Flex>
              <Flex>
                {approach.script?.campaign?.subject && (
                  <Tag mr="5px" colorScheme="teal" borderRadius={'20px'} fontSize={'12px'}>
                    {approach.script?.campaign?.subject}
                  </Tag>
                )}
                <Flex alignItems={'center'} ml="5px">
                  {getIcon(approach.status)}
                </Flex>
                {/* <Tag colorScheme="green" borderRadius={'20px'} mr="5px" justifyContent={'center'}>
                  
                </Tag> */}
              </Flex>
            </Flex>
          ))}
      </Flex>
      {/* <Box px="11px" overflowY="scroll" maxHeight="455px">
        {feedData &&
          feedData.map((item) => {
            return (
              <>
                <ActivityItem
                  lastUpdated={item.LAST_UPDATED}
                  dealOwner={item.RESPONSIBLE_ID}
                  entityId={item.OWNER_ID}
                  entityType={item.OWNER_TYPE_ID}
                  providerId={item.PROVIDER_ID}
                  direction={item.DIRECTION}
                  description={item.DESCRIPTION}
                />
                <Flex textAlign="left" mb={4}>
                  <SimpleGrid>
                    <Text color={textColor} fontSize="sm" fontWeight="700" alignItems="center" pr={2}>
                      <span style={{ textWrap: 'nowrap' }}>{formatDate(item.LAST_UPDATED)}</span>
                    </Text>
                    <UserOwner value={item.RESPONSIBLE_ID} mt="-15px" />
                  </SimpleGrid>
                  <SimpleGrid>
                    <SimpleGrid columns={3} display="flex" justifyContent="flex-start">
                      <ActivityType value={item.PROVIDER_ID} />
                      <DirectionIcon value={item.DIRECTION} />
                      <Text>{`${item.OWNER_ID} ${item.OWNER_TYPE_ID} `}</Text>
                    </SimpleGrid>
                    <ExpandableText text={item.DESCRIPTION} />
                  </SimpleGrid>
                </Flex>
              </>
            )
          })}
      </Box> */}
    </Card>
  )
}
