import React, { useState } from 'react';
import { FaStar } from 'react-icons/fa';

function Rating({ name, defaultValue = 0, onChange }) {
  const [value, setValue] = useState(defaultValue);

  const handleClick = (newValue) => {
    setValue(newValue);
    if (onChange) {
      onChange(name, newValue);
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      {[...Array(5)].map((_, index) => {
        const ratingValue = index + 1;
        return (
          <div
            key={index}
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            onClick={() => handleClick(ratingValue)}
          >
            <FaStar
              color={ratingValue <= value ? '#ffc107' : '#e4e5e9'}
              size={12}
            />
          </div>
        );
      })}
    </div>
  );
}

export default Rating;
