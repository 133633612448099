import React, { useState, useEffect } from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Flex,
  Switch,
  Icon,
  Input,
  Link,
  Textarea,
  SimpleGrid,
  TagLabel,
  TagCloseButton,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderTrack,
  SliderThumb,
  Select,
  Stack,
  useColorModeValue,
  Tag,
  Text
} from '@chakra-ui/react'
import { MdEditCalendar, MdCheck, MdClose } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import Card from 'components/card/Card'
import { getUserAccountId } from 'hooks/authService'

export default function CampaignForm() {
  const textColor = useColorModeValue('navy.700', 'white')

  const account_id = getUserAccountId()

  const { cadence_id: cadenceIdFromParams } = useParams() || ''
  const [cadence_id, setCadenceId] = useState(parseInt(cadenceIdFromParams, 10))

  useEffect(() => {
    async function getCadence() {
      try {
        const { data } = await axios.get(`/cadences/${cadenceIdFromParams}`, { headers: authHeader() })
        setFormData(data)
        setSelectedCampaigns(data.campaigns_ids)
      } catch (error) {
        console.log('Erro ao carregar os dados da cadência')
      }
    }

    if (cadenceIdFromParams) {
      getCadence()
    }
  }, [])

  const [formData, setFormData] = useState({
    name: '',
    media: 'whatsapp',
    account_id: account_id,
    order: 5,
    campaigns_ids: [],
    status: 'active'
  })

  const [campaignsList, setCampaignsList] = useState([])

  useEffect(() => {
    // console.log('campaign formData: ', formData)
  }, [account_id, formData])

  const [sendAlert, setSendAlert] = useState('')

  useEffect(() => {
    if (sendAlert === 'success') {
      setTimeout(() => {
        setSendAlert('')
      }, 3000)
    }
  }, [sendAlert])

  useEffect(() => {
    async function getCampaigns() {
      try {
        const response = await axios.get(`/campaigns`, {
          headers: authHeader()
        })
        setCampaignsList(response.data.data)
        // console.log('Campanhas carregadas: ', response.data.data)
      } catch (error) {
        console.log('Erro: ', error.response.data)
      }
    }

    getCampaigns()
  }, [])

  const [selectedCampaigns, setSelectedCampaigns] = useState([])

  const handleCheckboxChange = (value) => {
    setSelectedCampaigns((prevSelectedCampaigns) => {
      const updatedSelectedCampaigns = prevSelectedCampaigns.includes(value)
        ? prevSelectedCampaigns.filter((item) => item !== value)
        : [...prevSelectedCampaigns, value]

      // Atualiza o formData imediatamente após a atualização do selectedCampaigns
      setFormData((prevFormData) => ({
        ...prevFormData,
        campaigns_ids: updatedSelectedCampaigns
      }))

      return updatedSelectedCampaigns
    })
  }

  const handleTagClick = (campaignId) => {
    setSelectedCampaigns((prevSelectedCampaigns) => {
      const updatedSelectedCampaigns = prevSelectedCampaigns.includes(campaignId)
        ? prevSelectedCampaigns.filter((item) => item !== campaignId)
        : [...prevSelectedCampaigns, campaignId]

      setFormData((prevFormData) => ({
        ...prevFormData,
        campaigns_ids: updatedSelectedCampaigns
      }))

      return updatedSelectedCampaigns
    })
  }

  const bgTag = useColorModeValue('gray.100', 'gray.700')
  const colorTag = useColorModeValue('gray.800', 'white')

  // useEffect(() => {
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     campaigns_ids: selectedCampaigns
  //   }))
  // }, [selectedCampaigns])

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (cadence_id) {
      try {
        const response = await axios.patch(`/cadences/${cadence_id}`, formData, {
          headers: authHeader()
        })
        console.log('Dados atualizados com sucesso!', response.data)
        setSendAlert('success')
      } catch (error) {
        setSendAlert('error')
        console.error('Ocorreu um erro ao enviar os dados:', error.response.data)
      }
    } else {
      try {
        const response = await axios.post('/cadences', formData, {
          headers: authHeader()
        })
        console.log('Campanha cadastrada com sucesso!', response.data)
        setCadenceId(response.data.id)
        setSendAlert('success')
      } catch (error) {
        console.error('Ocorreu um erro ao cadastrar o script:', error.response.data)
        setSendAlert('error')
      }
    }
    console.log(formData)
  }

  return (
    <SimpleGrid columns={2} gap={5}>
      <Card>
        <form onSubmit={handleSubmit}>
          <FormControl>
            <Text display="flex" ms="4px" fontSize="xl" fontWeight="800" color={textColor} mb="8px">
              Nova
            </Text>
            <Grid templateColumns="repeat(3, 1fr)" gap={6}>
              <GridItem colSpan={2}>
                <Flex direction="column">
                  {/* <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Tema
              </FormLabel> */}
                  <Input
                    value={formData.name}
                    name="name"
                    onChange={handleChange}
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    placeholder="Nome"
                    mb="24px"
                    fontWeight="500"
                    size="md"
                  />
                </Flex>
              </GridItem>
              <GridItem colSpan={1}>
                <Flex flexDirection="row" justifyContent="center" alignItems="flex-start" mb="24px">
                  <FormLabel htmlFor="status" variant="auth" fontWeight="500" fontSize="sm" mb="8px">
                    Status Ativo?
                  </FormLabel>
                  <Switch
                    id="status"
                    checked={formData.status === 'active'}
                    onChange={(event) =>
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        status: event.target.checked ? 'active' : 'inactive'
                      }))
                    }
                    defaultChecked
                  />
                </Flex>
              </GridItem>
            </Grid>
            <SimpleGrid columns={2}>
              {/* <Box>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="md"
                  fontWeight="800"
                  color={textColor}
                  mt="24px"
                  mb="8px"
                >
                  Selecione as campanhas
                </FormLabel>
                <CheckboxGroup colorScheme="blue">
                  <Stack spacing={[5, 1]} direction={'column'} mb="24px">
                    {campaignsList &&
                      campaignsList.map((campaign) => (
                        <Checkbox
                          iconColor="green.500" // Cor do ícone quando marcado
                          borderColor="green.500" // Cor da borda
                          borderRadius="full" // Borda redonda (full = 50%)
                          size="lg" // Aumenta o tamanho para facilitar a visualização
                          transition="all 0.2s"
                          _checked={{
                            bg: 'green.500' // Cor de fundo quando marcado
                          }}
                          key={campaign.id}
                          value={selectedCampaigns[campaign.id]}
                          isChecked={selectedCampaigns.includes(campaign.id)} // Adicione esta linha
                          onChange={() => handleCheckboxChange(campaign.id)} // Adicione esta linha
                        >
                          {campaign.subject}
                        </Checkbox>
                      ))}
                  </Stack>
                </CheckboxGroup>
              </Box>
              <Box>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="md"
                  fontWeight="800"
                  color={textColor}
                  mt="24px"
                  mb="8px"
                >
                  Cadência
                </FormLabel>
                {selectedCampaigns && selectedCampaigns.map((item) => <Tag>{item}</Tag>)}
              </Box> */}

              <Box>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="md"
                  fontWeight="800"
                  color={textColor}
                  mt="24px"
                  mb="8px"
                >
                  Campanhas disponíveis
                </FormLabel>
                <Stack direction="column" mb="24px">
                  {campaignsList &&
                    campaignsList.map((campaign) => (
                      <Tag
                        key={campaign.id}
                        variant="solid"
                        bg={selectedCampaigns.includes(campaign.id) ? 'green.100' : 'transparent'}
                        color={colorTag}
                        borderRadius="full"
                        cursor="pointer"
                        onClick={() => handleTagClick(campaign.id)}
                        style={{ justifyContent: 'space-between' }}
                      >
                        <TagLabel>{campaign.subject}</TagLabel>
                        {selectedCampaigns.includes(campaign.id) && (
                          <Icon
                            as={MdCheck}
                            bg="green.500"
                            borderRadius="full"
                            p="2px"
                            color="white"
                            ml={1}
                          />
                        )}
                      </Tag>
                    ))}
                </Stack>
              </Box>
            </SimpleGrid>

            <Button
              type="submit"
              fontSize="sm"
              background="#49BFFF"
              color="white"
              fontWeight="800"
              w="100%"
              h="50"
              mb="24px"
              borderRadius="50px"
            >
              Salvar
            </Button>
            {sendAlert === 'success' && (
              <Alert status="success">
                <AlertIcon />
                <AlertTitle>Sucesso!</AlertTitle>
                <AlertDescription>Dados enviados com sucesso!</AlertDescription>
              </Alert>
            )}
          </FormControl>
        </form>
      </Card>
      {cadence_id && 'cadenceId'}
    </SimpleGrid>
  )
}
