import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import { Box, Flex, useColorModeValue, Text, Select, FormLabel, background } from '@chakra-ui/react'
import Card from 'components/card/Card'
import { Icon } from '@chakra-ui/react'
import { MdAdd } from 'react-icons/md'
import { getUserAccountId } from 'hooks/authService'

import { Importer, ImporterField } from 'react-csv-importer'
import 'react-csv-importer/dist/index.css'

const ImportComponent = () => {
  const accountId = getUserAccountId()

  const [importType, setImportType] = useState('')
  const textColor = useColorModeValue('secondaryGray.900', 'white')

  const importTypeList = ['Planilha manual', 'SimplesVet', 'Vetus']

  function getFirstNameAndLastName(nomeCompleto) {
    const partesNome = nomeCompleto.trim().split(' ')
    const primeiroNome = partesNome[0]

    let ultimoNome = ''
    if (partesNome.length > 1) {
      ultimoNome = partesNome.slice(1).join(' ')
    }

    return {
      first_name: primeiroNome,
      last_name: ultimoNome
    }
  }

  // Função para verificar se o cliente já existe
  async function checkExistingClient(clientData) {
    try {
      const response = await axios.get('/clients', {
        headers: authHeader(),
        params: {
          'filters[account_id][$eq]': accountId,
          'filters[first_name][$eq]': clientData.first_name,
          'filters[last_name][$eq]': clientData.last_name,
          'filters[email][$eq]': clientData.email,
          'filters[whatsapp][$eq]': clientData.whatsapp
        }
      })

      // Se o cliente existe, retorna o ID do primeiro cliente encontrado
      if (response.data.data.length > 0) {
        return response.data.data[0].id
      } else {
        // Se o cliente não existe, retorna false
        return false
      }
    } catch (error) {
      console.error('Erro ao verificar cliente existente:', error)
      return false // Em caso de erro, assume que o cliente não existe
    }
  }

  // Função para verificar se o animal já existe
  async function checkExistingAnimal(animalData, clientId) {
    try {
      const response = await axios.get('/animals', {
        headers: authHeader(),
        params: {
          'filters[account_id][$eq]': accountId,
          'filters[owner_id][$eq]': clientId,
          'filters[name][$eq]': animalData.name,
          'filters[species][$eq]': animalData.species
          // 'filters[animal_data][animal_code][$eq]': animalData.animal_data.animal_code
        }
      })

      return response.data.data.length > 0 // Retorna true se o animal já existe
    } catch (error) {
      console.error('Erro ao verificar animal existente:', error)
      return false // Em caso de erro, assume que o animal não existe
    }
  }

  function formatAnimalGender(gender) {
    const lowerCaseGender = gender.toLowerCase() // Converte para minúsculas para comparação

    if (lowerCaseGender === 'macho' || lowerCaseGender === 'm' || lowerCaseGender === 'masculino') {
      return 'male'
    } else if (lowerCaseGender === 'fêmea' || lowerCaseGender === 'f' || lowerCaseGender === 'feminino') {
      return 'female'
    } else {
      return 'unknow' // Valor padrão se não for macho ou fêmea
    }
  }

  function formatSterilization(input) {
    const normalizedInput = input
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')

    if (normalizedInput.includes('fertil')) {
      return 'Fértil'
    } else if (normalizedInput.includes('castrado')) {
      return 'Castrado'
    } else if (normalizedInput.includes('vasectomizado')) {
      return 'Vasectomizado'
    } else {
      return 'Desconhecido' // Default value if no match is found
    }
  }

  function formatAnimalSpecies(speciesInput) {
    const normalizedInput = speciesInput
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')

    const speciesMap = {
      canino: ['canina', 'cão', 'cachorro'],
      felino: ['felina', 'gato', 'gata'],
      ave: ['aves', 'pássaro'],
      bovino: ['bovinos', 'vaca', 'boi'],
      coelho: ['coelhos'],
      equino: ['equinos', 'cavalo', 'égua'],
      roedor: ['roedores', 'hamster', 'rato', 'camundongo'],
      suinos: ['suíno', 'porco']
    }

    for (const [standardSpecies, variations] of Object.entries(speciesMap)) {
      if (variations.includes(normalizedInput) || normalizedInput === standardSpecies) {
        return standardSpecies.charAt(0).toUpperCase() + standardSpecies.slice(1) // Capitalize the first letter
      }
    }

    return 'Desconhecido' // Default if no match
  }

  function formatDateToISO(dateString) {
    if (!dateString) return null // Retorna null se a data estiver vazia

    const parts = dateString.split('/')
    if (parts.length !== 3) return null // Retorna null se a data não estiver no formato correto

    const day = parts[0]
    const month = parts[1]
    const year = parts[2]

    return `${year}-${month}-${day}`
  }

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex flexDirection="column" background={'white'} borderRadius={'20px'} p={'20px'} gap={'20px'}>
        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          Importar Clientes
        </Text>
        <Flex flexDirection="column">
          <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
            Selecione a origem dos dados
          </FormLabel>
          <Select
            value={importType}
            name="importType"
            onChange={(event) => setImportType(event.target.value)}
            variant="auth"
            fontSize="sm"
            ms={{ base: '0px', md: '0px' }}
            placeholder="Selecione"
            fontWeight="500"
            size="md"
            w="400px"
            borderRadius="16px"
          >
            {importTypeList.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Select>
        </Flex>
        {/* <CSVReader
          cssClass="csvReaderInput"
          label="Selecione o arquivo CSV "
          onFileLoaded={handleFileLoad}
          onError={setError}
          parserOptions={{
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            transformHeader: (header) => header.trim()
          }}
          cssLabelClass={csvReaderInput}
        /> */}
        <Importer
          dataHandler={async (rows, { startIndex }) => {
            // required, may be called several times
            // receives a list of parsed objects based on defined fields and user column mapping;
            // (if this callback returns a promise, the widget will wait for it before parsing more data)
            for (const row of rows) {
              // separar primeiro nome e sobrenome
              // console.log('ROWssss', row)
              const { first_name, last_name } = getFirstNameAndLastName(row.client_name)
              const formattedGender = formatAnimalGender(row.animal_gender)
              const formattedSterilization = formatSterilization(row.animal_sterilization)
              const formattedSpecies = formatAnimalSpecies(row.animal_specie)

              const clientData = {
                account_id: accountId,
                first_name: first_name.charAt(0).toUpperCase() + first_name.slice(1),
                last_name: last_name.charAt(0).toUpperCase() + last_name.slice(1),
                email: row.client_email,
                whatsapp: row.client_whatsapp,
                owner_data: {
                  profile: [],
                  client_code: row.client_code,
                  gender: row.client_gender,
                  cpf: row.client_cpf,
                  address: row.client_address + row.client_number && `, ${row.client_number}`,
                  neighborhood: row.client_neighborhood,
                  city: row.client_city,
                  state: row.client_state,
                  zipCode: row.client_postal_code
                }
              }

              const animalData = {
                account_id: accountId, //
                owner_id: '', //
                name: row.animal_name.charAt(0).toUpperCase() + row.animal_name.slice(1), //
                species: formattedSpecies, //
                breed: row.animal_breed, //
                fur: row.animal_fur, //
                birthdate: formatDateToISO(row.animal_birthdate), //
                gender: formattedGender, //
                sterilization: formattedSterilization, //
                tags: row.animal_tags, // {}
                status: row.animal_status, //
                animal_data: {
                  animal_code: row.animal_code
                }
              }

              // console.log('client Data:', clientData)
              // console.log('animal Data: ', animalData)

              // Verifica se o cliente já existe
              const clientExists = await checkExistingClient(clientData)
              console.log('clientExists: ', clientExists)

              let clientId = null // Inicializa o ID do cliente como null

              if (!clientExists) {
                try {
                  // Cadastra o cliente se ele não existir
                  const clientResponse = await axios.post('/clients', clientData, {
                    headers: authHeader()
                  })
                  clientId = clientResponse.data.id // Obtém o ID do cliente cadastrado
                  console.log('Cliente cadastrado com sucesso:', clientResponse.data)
                } catch (clientError) {
                  console.error('Erro ao cadastrar cliente:', clientError)
                  // Lógica para lidar com erro ao cadastrar cliente (ex: exibir mensagem de erro)
                  continue // Pula para o próximo cliente na lista
                }
              } else {
                console.log('Cliente já existente:', clientData)
                clientId = clientExists
                // Lógica para lidar com cliente já existente (ex: atualizar dados do cliente?)
              }

              console.log('clientId antes de AnimalExists:', clientId) // Adicione esta linha para verificar o valor de clientId');

              // Verifica se o animal já existe (se o cliente foi cadastrado ou já existia)
              if (clientId) {
                const animalExists = await checkExistingAnimal(animalData, clientId)

                if (!animalExists) {
                  try {
                    // Cadastra o animal se ele não existir
                    animalData.owner_id = clientId // Define o ID do cliente
                    const animalResponse = await axios.post('/animals', animalData, {
                      headers: authHeader()
                    })
                    console.log('Animal cadastrado com sucesso:', animalResponse.data)
                  } catch (animalError) {
                    console.error('Erro ao cadastrar animal:', animalError)
                    // Lógica para lidar com erro ao cadastrar animal (ex: exibir mensagem de erro)
                  }
                } else {
                  console.log('Animal já existente:', animalData)
                  // Lógica para lidar com animal já existente (ex: atualizar dados do animal?)
                }
              }
            }
          }}
          defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
          restartable={false} // optional, lets user choose to upload another file when import is complete
          onStart={({ file, preview, fields, columnFields }) => {
            // optional, invoked when user has mapped columns and started import
            // prepMyAppForIncomingData()
          }}
          onComplete={({ file, preview, fields, columnFields }) => {
            // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
            // showMyAppToastNotification()
          }}
          onClose={({ file, preview, fields, columnFields }) => {
            // optional, if this is specified the user will see a "Finish" button after import is done,
            // which will call this when clicked
            // goToMyAppNextPage()
          }}
          // CSV options passed directly to PapaParse if specified:
          // delimiter={...}
          // newline={...}
          // quoteChar={...}
          // escapeChar={...}
          // comments={...}
          // skipEmptyLines={...}
          // delimitersToGuess={...}
          // chunkSize={...} // defaults to 10000
          // encoding={'windows-1254'} // defaults to utf-8, see FileReader API
        >
          <ImporterField name="client_name" label="Cliente Nome" />
          <ImporterField name="client_code" label="Cliente Código" />
          <ImporterField name="client_gender" label="Cliente Gênero" />
          <ImporterField name="client_email" label="Cliente Email" />
          <ImporterField name="client_whatsapp" label="Cliente WhatsApp" />
          <ImporterField name="client_postal_code" label="Cliente CEP" optional />
          <ImporterField name="client_state" label="Cliente UF" optional />
          <ImporterField name="client_city" label="Cliente Cidade" optional />
          <ImporterField name="client_address" label="Cliente Endereço" optional />
          <ImporterField name="client_number" label="Cliente Número" optional />
          <ImporterField name="client_neighborhood" label="Cliente Bairro" optional />
          <ImporterField name="client_cpf" label="Cliente CPF" optional />
          <ImporterField name="animal_code" label="Animal Código" optional />
          <ImporterField name="animal_name" label="Animal Nome" />
          <ImporterField name="animal_specie" label="Animal Espécie" optional />
          <ImporterField name="animal_breed" label="Animal Raça" optional />
          <ImporterField name="animal_fur" label="Animal Pelagem" optional />
          <ImporterField name="animal_gender" label="Animal Gênero" optional />
          <ImporterField name="animal_sterilization" label="Animal Esterelização" optional />
          <ImporterField name="animal_birthdate" label="Animal Nascimento" optional />
          <ImporterField name="animal_status" label="Animal Status" optional />
          <ImporterField name="animal_tags" label="Animal Marcações" optional />
        </Importer>
      </Flex>
    </Box>
  )
}

export default ImportComponent
