import React, { useState, useEffect } from 'react';
import { Text } from '@chakra-ui/react';
import axios from 'axios';

const CheckCNPJExists = ({ cnpj }) => {
  const [cnpjExists, setCnpjExists] = useState({ exist: 'no' });

  console.log('CNPJ: ', cnpj);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://otten.bitrix24.com.br/rest/1/7d0cb9ltfwp34kvp/crm.company.list.json?filter[UF_CRM_COMPANY_1705433463134]=${cnpj}`
        );
        setCnpjExists({ exist: response?.data?.total });
        console.log('CNPJ Consultado no CRM: ', response?.data?.total);
      } catch (error) {
        console.log(`Erro ao consultar o CNPJ: ${cnpj}`, error);
      }
    };
    fetchData();
  }, [cnpj, setCnpjExists]);

  return (
    <Text fontSize={12} ml={'8px'}>
      {cnpjExists?.exist > 0 && `Existe no CRM`}
    </Text>
  );
};

export default CheckCNPJExists;
