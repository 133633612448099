import { GoogleGenerativeAI } from '@google/generative-ai'
import React, { useState, useEffect, useRef } from 'react'
import { MdStar } from 'react-icons/md'
import { Spinner, Textarea } from '@chakra-ui/react'
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  Text,
  InputRightElement,
  useColorModeValue
} from '@chakra-ui/react'
// Custom components
import Card from 'components/card/Card.js'
import ReactMarkdown from 'react-markdown'
import CopyToClipboardButton from 'components/copyToClipboard'

export default function VetsalesBot(props) {
  const { ...rest } = props
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const chatContainerRef = useRef(null)

  const [userInput, setUserInput] = useState('')
  const [chatHistory, setChatHistory] = useState([])
  const [loadingStatus, setLoadingStatus] = useState(false)

  useEffect(() => {
    if (chatContainerRef.current) {
      // Scroll to the bottom with smooth behavior
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }, [chatHistory])

  const handleChange = (event) => {
    setUserInput(event.target.value)
  }

  const genAI = new GoogleGenerativeAI('AIzaSyCbGYGn_zCFLHNxsEVmmcbU9Q6tQVvGICE') // Insira sua chave aqui

  async function getResponse() {
    if (!userInput.trim()) return // Impede submissão se o input estiver vazio

    setLoadingStatus(true)

    const model = genAI.getGenerativeModel({ model: 'gemini-1.5-flash' })

    const userMessage = {
      role: 'user',
      parts: [{ text: userInput.trim() }]
    }

    try {
      // Adiciona a mensagem do usuário no histórico antes de enviar
      setChatHistory((prevHistory) => [...prevHistory, userMessage])

      const chat = model.startChat({
        history: chatHistory,
        message: userMessage,
        generationConfig: {
          maxOutputTokens: 100
        }
      })

      const result = await chat.sendMessage(userInput)
      const response = await result.response
      const data = response.text()

      const modelMessage = {
        role: 'model',
        parts: [{ text: data }]
      }

      // Adiciona a resposta do modelo ao histórico
      setChatHistory((prevHistory) => [...prevHistory, modelMessage])

      setUserInput('') // Limpa o campo de input
    } catch (error) {
      console.error('Error during chat:', error)
    } finally {
      setLoadingStatus(false)
    }
  }

  return (
    <Flex flexDirection={'column'} alignItems={'center'} pt="80px">
      <Flex
        w="100%"
        flexDirection={{ base: 'column', lg: 'row' }}
        maxHeight={'70vh'}
        overflowY="auto"
        px="15%"
        sx={{
          '::-webkit-scrollbar': {
            width: '4px', // Largura da barra de rolagem
            borderRadius: '8px' // Borda arredondada para a barra
          },
          '::-webkit-scrollbar-track': {
            background: 'transparent' // Fundo transparente para a trilha
          },
          '::-webkit-scrollbar-thumb': {
            background: 'gray.300', // Cor da barra de rolagem
            borderRadius: '8px' // Borda arredondada para o polegar
          }
        }}
        ref={chatContainerRef}
      >
        <Box minH="60px" minW="100%" mt="auto">
          <Box
            style={{
              padding: '20px',
              textAlign: 'justify',
              display: 'flex',
              flexDirection: 'column' // Mostra as mensagens em ordem reversa
            }}
          >
            {chatHistory.map((message, index) => (
              <Flex
                mb="20px"
                key={index}
                background={message.role === 'user' ? '#ffffff' : '#c6eafd'}
                width={'70%'}
                p="15px 20px"
                borderRadius={message.role === 'user' ? '20px 20px 5px 20px' : '20px 20px 20px 5px'}
                mr={message.role === 'user' ? '0' : '30%'}
                ml={message.role === 'user' ? '30%' : '0'}
              >
                <Text fontWeight={'600'} mr="16px">
                  {message.role === 'user' ? 'Você' : 'AI'}
                </Text>
                <ReactMarkdown className={`message ${message.role}`}>
                  {message.parts[0].text.trim()}
                </ReactMarkdown>
              </Flex>
            ))}
          </Box>
        </Box>
      </Flex>
      <Flex width={'full'} style={{ position: 'fixed', bottom: '60px', width: '60%' }}>
        <InputGroup>
          {/* <Input
            autoFocus
            background={'white'}
            value={userInput}
            onChange={handleChange}
            isRequired={false}
            variant="auth"
            fontSize="sm"
            placeholder="Digite o que quiser..."
            fontWeight="500"
            size="md"
            borderRadius="100px"
            // mb="20px"
            width={'full'}
            height={'50px'}
            boxShadow={'2xl'}
          /> */}
          <Textarea
            autoFocus
            background={'white'}
            value={userInput}
            onChange={handleChange}
            placeholder="Digite o que quiser..."
            // resize="none" // Desativa o redimensionamento manual
            // overflow="auto" // Adiciona scroll interno
            maxHeight="6em" // Define a altura máxima para 6 linhas
            minH="2.5em"
            width={'full'}
            borderRadius="100px"
            boxShadow={'2xl'}
            height={'50px'}
            p={'14px 20px'}
            data-gramm="false" // Desabilita plugins de correção ortográfica do chrome
            data-gramm_editor="false" // Desabilita plugins de correção ortográfica do chrome
            spellcheck="false" // Desabilita plugins de correção ortográfica do chrome
            sx={{
              '::-webkit-scrollbar': {
                width: '4px', // Largura da barra de rolagem
                borderRadius: '8px' // Borda arredondada para a barra
              },
              '::-webkit-scrollbar-track': {
                background: 'transparent', // Fundo transparente para a trilha
                Paddingight: '20px'
              },
              '::-webkit-scrollbar-thumb': {
                background: 'gray.300', // Cor da barra de rolagem
                borderRadius: '8px' // Borda arredondada para o polegar
              }
            }}
            _focus={{
              outline: 0, // Remove a borda azul
              border: 0,
              boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.6)' // Mantém a sombra, se desejar
            }}
          />
          <InputRightElement alignItems={'center'}>
            <IconButton
              colorScheme="gray"
              aria-label="Call Segun"
              size="sm"
              onClick={getResponse}
              m={'10px 10px 0 0'}
              icon={
                loadingStatus ? (
                  <Spinner color="blue.500" />
                ) : (
                  <Icon as={MdStar} width="20px" height="20px" color="inherit" />
                )
              }
            />
          </InputRightElement>
        </InputGroup>
      </Flex>
    </Flex>
  )
}
