// Chakra imports
import { Box, Flex, Icon, Text, Select, useColorModeValue, SimpleGrid } from '@chakra-ui/react'
// Custom components
import AnimalsAgeDonutCard from './DonutCardAnimalsAge'
import StatusDonutChartCard from './DonutCardStatus'
import DonutChartCardSpecies from './DonutCardSpecies'
import React, { useEffect } from 'react'
import { MdDone } from 'react-icons/md'

export default function ClientsDash(props) {
  const { height, weight, labels, clients, approachs, animals, ...rest } = props

  // console.log('animals ClientDash', animals)

  const [species, speciesTotal] = animals && countSpecies(animals.data)
  const [ageLabels, ageCounts] = animals && countAnimalsByAge(animals.data)

  useEffect(() => {
    // console.log('total', speciesTotal)
    // console.log('species', species)
  }, [speciesTotal, species])

  function countSpecies(animalsData) {
    if (!Array.isArray(animalsData)) {
      console.error('animalsData não é um array:', animalsData)
      return [[], []] // Retorna arrays vazios para evitar erros
    }

    const species = []
    const total = []

    animalsData.reduce((speciesCount, animal) => {
      const specie = animal.species
      speciesCount[specie] = (speciesCount[specie] || 0) + 1

      if (!species.includes(specie)) {
        species.push(specie)
      }

      return speciesCount
    }, {})

    species.forEach((specie) => {
      total.push(animalsData.filter((animal) => animal.species === specie).length)
    })

    return [species, total]
  }

  function countAnimalsByAge(animalsData) {
    if (!Array.isArray(animalsData)) {
      console.error('animalsData não é um array:', animalsData)
      return [[], []] // Retorna arrays vazios para evitar erros
    }

    const today = new Date()
    const ageCategories = [
      'Filhotes até 1 ano',
      'Filhotes entre 1 e 2 anos',
      'Adultos entre 2 e 6 anos',
      'Idosos acima de 6 anos'
    ]
    const ageTotals = [0, 0, 0, 0] // Initialize totals for each category

    animalsData.forEach((animal) => {
      const birthdate = new Date(animal.birthdate)
      const ageInYears = today.getFullYear() - birthdate.getFullYear()

      if (ageInYears <= 1) {
        ageTotals[0]++
      } else if (ageInYears <= 2) {
        ageTotals[1]++
      } else if (ageInYears <= 6) {
        ageTotals[2]++
      } else {
        ageTotals[3]++
      }
    })

    return [ageCategories, ageTotals]
  }

  const pieChartSpeciesOptions = {
    labels: species,
    colors: ['#49a3d0', '#3375a6', '#22477e', '#122756'],
    chart: {
      width: '60px'
    },
    states: {
      hover: {
        filter: {
          type: 'none'
        }
      }
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    hover: { mode: null },
    plotOptions: {
      pie: {
        donut: {
          size: '75%'
        }
      },
      donut: {
        expandOnClick: true,
        donut: {
          labels: {
            show: true
          }
        }
      }
    },
    fill: {
      colors: ['#49a3d0', '#3375a6', '#22477e', '#122756']
    },
    tooltip: {
      enabled: true,
      theme: 'dark'
    }
  }

  const pieChartAgeOptions = {
    labels: ageLabels,
    colors: ['#49a3d0', '#3375a6', '#22477e', '#122756'],
    chart: {
      width: '60px'
    },
    states: {
      hover: {
        filter: {
          type: 'none'
        }
      }
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    hover: { mode: null },
    plotOptions: {
      pie: {
        donut: {
          size: '75%'
        }
      },
      donut: {
        expandOnClick: true,
        donut: {
          labels: {
            show: true
          }
        }
      }
    },
    fill: {
      colors: ['#49a3d0', '#3375a6', '#22477e', '#122756']
    },
    tooltip: {
      enabled: true,
      theme: 'dark'
    }
  }

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const cardColor = useColorModeValue('white', 'navy.700')
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset')
  return (
    <Flex borderRadius="20px" px="20px" bg="white" pt="20px" flexDirection="column" height={'fit-content'}>
      <Flex alignItems="center" w="100%">
        <Icon as={MdDone} width="20px" height="20px" mr={3} color="inherit" />

        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          Perfil veterinário
        </Text>
      </Flex>
      <SimpleGrid minChildWidth="130px" columns={5} justifyItems={'center'} alignItems={'center'}>
        {animals.data && (
          <AnimalsAgeDonutCard
            weight={'90%'}
            height={'80%'}
            labels={false}
            pieChartData={ageCounts}
            pieChartOptions={pieChartAgeOptions}
          />
        )}
        {/* <StatusDonutChartCard weight={'90%'} height={'80%'} labels={false} /> */}
        {animals.data && (
          <DonutChartCardSpecies
            weight={'90%'}
            height={'80%'}
            labels={false}
            pieChartData={speciesTotal}
            pieChartOptions={pieChartSpeciesOptions}
          />
        )}
        <Flex
          flexDirection="column"
          alignContent="center"
          boxShadow={cardShadow}
          p="10px 15px"
          borderRadius="20px"
        >
          <Text color="#A3AED0" fontSize="sm" fontWeight="700" mb="-7px">
            Clientes
          </Text>
          <Text color="#1b2559" fontSize="32px" fontWeight="700">
            {clients.total}
          </Text>
        </Flex>
        <Flex
          flexDirection="column"
          alignContent="center"
          boxShadow={cardShadow}
          p="10px 15px"
          borderRadius="20px"
        >
          <Text color="#A3AED0" fontSize="sm" fontWeight="700" mb="-7px">
            Animais
          </Text>
          <Text color="#1b2559" fontSize="32px" fontWeight="700">
            {animals.total}
          </Text>
        </Flex>
      </SimpleGrid>
    </Flex>
  )
}
