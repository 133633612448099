// Chakra imports
import { Box, Flex, Text, Select, useColorModeValue, SimpleGrid, background } from '@chakra-ui/react'
// Custom components
import Card from 'components/card/Card.js'
import DonutChart from 'components/charts/DonutChart'
import { HSeparator } from 'components/separator/Separator'
import { VSeparator } from 'components/separator/Separator'
import React from 'react'

// const pieChartData = [48, 34, 12, 9]
// const pieChartOptions = {
//   labels: ['Adultos', 'Idosos', 'Filhotes 2', 'Filhotes 1'],
//   colors: ['#122756 ', '#22477e', '#3375a6', '#49a3d0'],
//   chart: {
//     width: '60px'
//   },
//   states: {
//     hover: {
//       filter: {
//         type: 'none'
//       }
//     }
//   },
//   legend: {
//     show: false
//   },
//   dataLabels: {
//     enabled: false
//   },
//   hover: { mode: null },
//   plotOptions: {
//     pie: {
//       donut: {
//         size: '75%'
//       }
//     },
//     donut: {
//       expandOnClick: true,
//       donut: {
//         labels: {
//           show: true
//         }
//       }
//     }
//   },
//   fill: {
//     colors: ['#49a3d0', '#3375a6', '#22477e', '#122756']
//   },
//   tooltip: {
//     enabled: true,
//     theme: 'dark'
//   }
// }

export default function DonutChartCard(props) {
  const { height, weight, labels, pieChartData, pieChartOptions, ...rest } = props

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const cardColor = useColorModeValue('white', 'navy.700')
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset')
  return (
    <Box align="center" direction="column" w="100%" {...rest}>
      {labels && (
        <Flex justifyContent="space-between" alignItems="center" w="100%">
          <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
            Idade dos Animais
          </Text>
        </Flex>
      )}
      <Flex flexDirection="column-reverse">
        <DonutChart h={height} w={weight} chartData={pieChartData} chartOptions={pieChartOptions} />

        {!labels && (
          <Flex style={{ position: 'relative', top: '70px' }} mx="auto">
            Idade
          </Flex>
        )}
        {labels && (
          <Flex flexDirection="column">
            {pieChartOptions.labels.length > 0 &&
              pieChartOptions.labels.map((item, index) => (
                <>
                  <Card py="0" mb="6px" bg={pieChartOptions.colors[index]}>
                    <Flex direction="row" align="center" alignItems="center" py="2px">
                      <Flex h="30px" w="30px" mr="6px">
                        <Text fontSize="lg" color="white" fontWeight="700">
                          {pieChartData[index]}
                        </Text>
                      </Flex>
                      <Text fontSize="xs" color="white" fontWeight="700">
                        {item}
                      </Text>
                    </Flex>
                  </Card>
                </>
              ))}
          </Flex>
        )}
      </Flex>
    </Box>
  )
}
