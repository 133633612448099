/*eslint-disable*/
import React from 'react'
import { Flex, Link, List, ListItem, Text, Button, useColorMode, useColorModeValue } from '@chakra-ui/react'

export default function Footer() {
  const textColor = useColorModeValue('gray.400', 'white')
  const { toggleColorMode } = useColorMode()
  return (
    <Flex
      zIndex="3"
      flexDirection={{
        base: 'column',
        xl: 'row'
      }}
      alignItems={{
        base: 'center',
        xl: 'start'
      }}
      justifyContent="space-between"
      px={{ base: '30px', md: '50px' }}
      pb="30px"
    >
      <Text
        color={textColor}
        textAlign={{
          base: 'center',
          xl: 'start'
        }}
        mb={{ base: '20px', xl: '0px' }}
      >
        {' '}
        &copy; {1900 + new Date().getYear()}
        <Text as="span" fontWeight="500" ms="4px">
          VetSales uma solução Otten
        </Text>
      </Text>
      <List display="flex">
        <ListItem
          me={{
            base: '20px',
            md: '44px'
          }}
        >
          <Link fontWeight="500" color={textColor} href="https://otten.com.br">
            Créditos
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: '20px',
            md: '44px'
          }}
        >
          <Link fontWeight="500" color={textColor} href="https://vetsales.otten.com.br/termos-e-condicoes/">
            Termos de uso
          </Link>
        </ListItem>
        <ListItem>
          <Link
            fontWeight="500"
            color={textColor}
            href='https://otten.com.br/contato?utm_source="ottensalesleads"'
          >
            Contato
          </Link>
        </ListItem>
      </List>
    </Flex>
  )
}
