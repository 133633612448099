import { Flex, Text, Switch } from '@chakra-ui/react'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import { getUserAccountId } from 'hooks/authService'
import { useEffect, useState } from 'react'
import { FaWhatsapp } from 'react-icons/fa'
import { MdPets } from 'react-icons/md'

export default function WhatsAppConectionStatus() {
  const [queueStatus, setQueueStatus] = useState('inactive')
  const [activeStatus, setActiveStatus] = useState(false)

  const accountId = getUserAccountId()

  useEffect(() => {
    getAccountStatus()
  }, [accountId, activeStatus])

  async function getAccountStatus() {
    if (!accountId) return
    try {
      const response = await axios.get(`/accounts/${accountId}`, { headers: authHeader() })

      if (response.data.status === 'active') {
        setQueueStatus(response.data.status)
        setActiveStatus(true)
      }
      // console.log('AccountStatus', response.data.status)
    } catch (error) {}
  }

  async function changeAccountStatus() {
    if (!accountId) return
    let status
    if (activeStatus) {
      status = 'inactive'
    } else {
      status = 'active'
    }

    try {
      const response = await axios.patch(
        `/accounts/${accountId}`,
        { status: status },
        { headers: authHeader() }
      )
      console.log('response: ', response)
      setQueueStatus(status)
      setActiveStatus(!activeStatus)
    } catch (error) {
      console.log('Erro', error)
    }
  }

  return (
    <Flex flexDirection={'column'} bg={'white'} borderRadius={'20px'} p="10px 20px" height={'fit-content'}>
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <Flex alignItems={'center'}>
          <MdPets color={activeStatus ? '#65e495' : '#fc8181'} size={'22px'} />
          <Text fontSize="18px" fontWeight="700" lineHeight="100%" ml="8px">
            VetSales
          </Text>
        </Flex>
        <Text fontWeight={'500'} fontSize={'18px'} color={activeStatus ? 'whatsapp.300' : 'red.300'}>
          {`${activeStatus ? 'Ativo' : 'Pausado'}`}
        </Text>
        <Switch
          colorScheme="whatsapp"
          isChecked={activeStatus ? true : false}
          onChange={() => changeAccountStatus()}
        ></Switch>
      </Flex>
      <Text>{activeStatus ? 'Sistema enviando mensagens.' : 'Envio de mensagens pausado.'}</Text>
    </Flex>
  )
}
