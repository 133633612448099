import React, { useState, useEffect } from 'react'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import { getUserAccountId, getUserAuthentication } from 'hooks/authService'
import Card from 'components/card/Card'
import { MdAddBusiness, MdImportContacts, MdImportExport, MdPerson, MdPersonAdd } from 'react-icons/md'
import { Avatar, Box, Flex, Link, Icon, Text, useColorModeValue, SimpleGrid, Wrap } from '@chakra-ui/react'
import ApproachsTable from './components/approachsTable'
import AnimalsAgeDonutCard from '../default/components/DonutCardAnimalsAge'
import StatusDonutChartCard from '../default/components/DonutCardStatus'
import DonutChartCardSpecies from '../default/components/DonutCardSpecies'

export default function Approachs() {
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const iconColor = useColorModeValue('secondaryGray.500', 'white')
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100')

  const [dados, setData] = useState([])
  const [emptyData, setEmptyData] = useState(null)

  const account_id = getUserAccountId()

  console.log('account_id:', account_id)

  useEffect(() => {
    getApproachs()
  }, [])

  async function getApproachs() {
    if (!account_id) return

    try {
      const response = await axios.get(`/approachs?account_id=${account_id}`, { headers: authHeader() })
      setData(response.data.data)
    } catch (error) {
      setEmptyData('Não há nenhuma abordagem registrada.')
    }
  }

  return (
    <Box pt={{ base: '30px', md: '80px', xl: '80px' }}>
      <SimpleGrid rows={2}>
        {/* <Wrap minChildWidth="300px" maxChildWidth="150px" columns={6} mb="20px" alignItems={'center'}>
          <StatusDonutChartCard width={'170px'} />
          <AnimalsAgeDonutCard width={'170px'} />
          <DonutChartCardSpecies width={'170px'} />
        </Wrap> */}

        <ApproachsTable
          columnsData={[
            {
              Header: 'Tutor',
              accessor: ''
            },
            {
              Header: 'Campanha',
              accessor: 'campaign  '
            },
            {
              Header: 'Tema',
              accessor: 'animals  '
            },
            {
              Header: 'Status',
              accessor: 'status  '
            },
            {
              Header: 'Data',
              accessor: 'date  '
            }
          ]}
          tableData={dados}
        />
      </SimpleGrid>
    </Box>
  )
}
