import React from 'react'
import { Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react'
import BarChart from 'components/charts/BarChart'
import Card from 'components/card/Card.js'
// import { barChartDataDailyTraffic, barChartOptionsDailyTraffic } from 'variables/charts'
import { RiArrowUpSFill } from 'react-icons/ri'

export default function LastApproachs(props) {
  const { approachs, ...rest } = props

  // console.log('approachs', approachs)

  const ultimas7Abordagens = ultimasAbordagens(approachs, 7)
  // console.log('ultimasAbordagens', ultimas7Abordagens)

  const barChartDataDailyTraffic = [
    {
      name: 'Abordagens preventivas',
      data: ultimas7Abordagens
    }
  ]

  const lastMonths = ultimosMeses(7)

  const barChartOptionsDailyTraffic = {
    chart: {
      toolbar: {
        show: false
      }
    },
    tooltip: {
      style: {
        fontSize: '12px',
        fontFamily: undefined
      },
      onDatasetHover: {
        style: {
          fontSize: '12px',
          fontFamily: undefined
        }
      },
      theme: 'dark'
    },
    xaxis: {
      categories: lastMonths,
      show: false,
      labels: {
        show: true,
        style: {
          colors: '#A3AED0',
          fontSize: '14px',
          fontWeight: '500'
        }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      show: false,
      color: 'black',
      labels: {
        show: true,
        style: {
          colors: '#CBD5E0',
          fontSize: '14px'
        }
      }
    },
    grid: {
      show: false,
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true
        }
      },
      xaxis: {
        lines: {
          show: false
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        type: 'vertical',
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          [
            {
              offset: 0,
              color: '#0D4384',
              opacity: 1
            },
            {
              offset: 100,
              color: '#48BFFF',
              opacity: 0.28
            }
          ]
        ]
      }
    },
    dataLabels: {
      enabled: false
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: '40px'
      }
    }
  }

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white')

  function ultimosMeses(x) {
    const meses = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
    const dataAtual = new Date()
    const mesAtual = dataAtual.getMonth()
    const resultado = []

    for (let i = 0; i < x; i++) {
      const mesIndex = (mesAtual - i + 12) % 12
      resultado.push(meses[mesIndex])
    }

    return resultado.reverse() // Inverte a lista para que os meses estejam na ordem correta
  }

  function ultimasAbordagens(dataWrapper, x) {
    const data = dataWrapper.data
    // console.log('dataWrapper: ', dataWrapper)
    if (!Array.isArray(data)) return

    const meses = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
    const dataAtual = new Date()
    const mesAtual = dataAtual.getMonth()
    const anoAtual = dataAtual.getFullYear()

    // Cria um objeto para armazenar a contagem de abordagens por mês/ano
    const contagemMeses = {}

    // Filtra e conta as abordagens dos últimos x meses
    data.forEach((item) => {
      const dataAbordagem = new Date(item.created_at)
      const diferencaMeses =
        (anoAtual - dataAbordagem.getFullYear()) * 12 + (mesAtual - dataAbordagem.getMonth())

      // Apenas considera abordagens dos últimos x meses
      if (diferencaMeses < x) {
        const mesIndex = dataAbordagem.getMonth()
        const ano = dataAbordagem.getFullYear()
        const chave = `${meses[mesIndex]}/${ano}`

        if (!contagemMeses[chave]) {
          contagemMeses[chave] = 0
        }
        contagemMeses[chave]++
      }
    })

    // Organiza o resultado em uma lista com a contagem dos últimos x meses
    const resultado = []
    for (let i = 0; i < x; i++) {
      const mesIndex = (mesAtual - i + 12) % 12
      const ano = mesAtual - i < 0 ? anoAtual - 1 : anoAtual
      const chave = `${meses[mesIndex]}/${ano}`
      resultado.push(contagemMeses[chave] || 0)
    }

    return resultado.reverse() // Inverte para manter a ordem cronológica
  }

  // console.log('lastMonths:', lastMonths)
  return (
    <Card align="center" direction="column" w="100%" {...rest}>
      <Flex justify="space-between" align="start" px="10px" pt="5px">
        <Flex flexDirection="column" align="start" me="20px">
          <Flex w="100%">
            <Text me="auto" color="secondaryGray.600" fontSize="sm" fontWeight="500">
              Contatos realizadas
            </Text>
          </Flex>
          <Flex align="end">
            <Text color={textColor} fontSize="34px" fontWeight="700" lineHeight="100%">
              {approachs.total}
            </Text>
            <Text ms="6px" color="secondaryGray.600" fontSize="sm" fontWeight="500">
              abordagens
            </Text>
          </Flex>
        </Flex>
        {/* <Flex align="center">
          <Icon as={RiArrowUpSFill} color="green.500" />
          <Text color="green.500" fontSize="sm" fontWeight="700">
            +2.45%
          </Text>
        </Flex> */}
      </Flex>
      <Box h="240px" mt="auto">
        {ultimas7Abordagens && (
          <BarChart chartData={barChartDataDailyTraffic} chartOptions={barChartOptionsDailyTraffic} />
        )}
      </Box>
    </Card>
  )
}
