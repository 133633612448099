import React, { useEffect, useMemo, useState } from 'react'
import {
  Avatar,
  Center,
  Flex,
  Link,
  Icon,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button
} from '@chakra-ui/react'
import { MdEditCalendar, MdCheck, MdClose, MdPlusOne, MdAdd } from 'react-icons/md'
import { app } from 'api/feathers'
import axios from 'api/axios'
import { getUserAuthenticationRole } from 'hooks/authService'

// Custom components
import Card from 'components/card/Card'
// import Menu from 'components/menu/MainMenu'
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import authHeader from 'hooks/authHeader'
import { formatCNPJ } from 'views/admin/default/components/formatCNPJ'
import { FaPlus } from 'react-icons/fa'
const userRole = getUserAuthenticationRole()

const columnsData = [
  { Header: 'Empresa', accessor: '' },
  { Header: 'Colaboradores', accessor: '' },
  { Header: 'Contato', accessor: '' },
  { Header: 'Tutores', accessor: '' },
  { Header: 'Animais', accessor: '' },
  { Header: 'Status', accessor: '' }
]

export default function CampaignsTable() {
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    getAccounts()
  }, [])

  async function getAccounts() {
    try {
      const response = await app.service('accounts').find({
        headers: authHeader(),
        paginate: 20
      })
      setTableData(response.data)
      console.log(response.data)
    } catch (error) {
      console.error('Erro ao buscar campanhas:', error)
    }
  }

  const columns = useMemo(() => columnsData, [])
  const data = useMemo(() => tableData, [tableData])

  const tableInstance = useTable(
    {
      columns,
      data
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, initialState } = tableInstance
  initialState.pageSize = 150

  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const iconColor = useColorModeValue('secondaryGray.500', 'white')
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100')
  return (
    <Card direction="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          Contas
        </Text>
        <Flex gap={5} alignItems="center">
          <Link href="/admin/new-account">
            <Button
              leftIcon={<FaPlus fontWeight={'500'} size={'12px'} />}
              colorScheme="gray"
              color="gray.400"
              variant="solid"
              fontWeight={'500'}
              borderRadius={'50px'}
              size="md"
            >
              Nova
            </Button>
          </Link>
        </Flex>
        {/* <Menu /> */}
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color="gray.400"
                  >
                    {column.render('Header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row)
            // console.log('row', row)
            return (
              <Tr {...row.getRowProps()} key={index}>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Link href={`/admin/account/${row.original.id}`}>
                    <Text color={textColor} fontSize="sm" fontWeight="600">
                      {row.original.name}
                    </Text>
                    <Text>{formatCNPJ(row.original?.business_data?.cnpj)}</Text>
                    {}
                  </Link>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Flex alignItems="center">
                    <Text color={textColor} fontSize="sm" fontWeight="700">
                      {row.original.business_data?.employees}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Flex align="left" flexDirection="column">
                    <Text me="10px" color={textColor} fontSize="sm" fontWeight="600">
                      {row.original.contact_data?.emails[0]?.address}
                    </Text>
                    <Text me="10px" color={textColor} fontSize="sm" fontWeight="400">
                      {row.original.contact_data?.phones[0]?.number}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Flex align="center">
                    <Text me="10px" color={textColor} fontSize="sm" fontWeight="400">
                      6.272
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Flex align="center">
                    <Text me="10px" color={textColor} fontSize="sm" fontWeight="400">
                      7.142
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Text color={textColor} fontSize="sm" fontWeight="400">
                    {row.original.status === 'active' && (
                      <Icon as={MdCheck} color="green" w="20px" h="20px" />
                    )}
                    {row.original.status === 'inactive' && (
                      <Icon as={MdClose} color="red" w="20px" h="20px" />
                    )}
                    {row.original.status === 'new' && <Icon as={MdCheck} color="blue" w="20px" h="20px" />}
                    {row.original.status}
                  </Text>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Card>
  )
}
