// id: Type.Number(),
//     account_id: Type.Number(),
//     name: Type.String(),
//     message: Type.String(),
//     step: Type.String(), // aproach, response 1, response 2, ?
//     category: Type.String(), // theme or subject
//     status: Type.String(), // active, suspended
//     createdAt: Type.Optional(Type.String({ format: 'date-time' })),
//     updatedAt: Type.Optional(Type.String({ format: 'date-time' }))

import React, { useState, useEffect, useRef } from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  FormHelperText,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Flex,
  HStack,
  Input,
  Link,
  Textarea,
  SimpleGrid,
  Switch,
  Select,
  useToast,
  useColorModeValue,
  Text
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import Card from 'components/card/Card'
import { getUserAccountId } from 'hooks/authService'
import AddTermToInput from 'components/addTermToInput'

export default function ApproachQueueForm() {
  const textColor = useColorModeValue('navy.700', 'white')

  const account_id = getUserAccountId()

  const { script_id: scriptIdFromParams } = useParams() || ''
  const [script_id, setScript_id] = useState(scriptIdFromParams)

  // table.integer('account_id')
  // table.integer('owner_id'
  // table.integer('animal_id')
  // table.integer('script_id')
  // table.integer('cadence_id')
  // table.enum('media', ['email', 'whatsapp']).notNullable()
  // table.text('message').notNullable()
  // table.enum('status', ['sent', 'received', 'read', 'responded', 'scheduled', 'invoiced']).notNullable()
  // table.timestamps(true, true)

  const textareaRef = useRef(null)
  const [updatedMessage, setUpdatedMessage] = useState('')
  const [accountName, setAccountName] = useState('')
  const [selectedClientName, setSelectedClientName] = useState('')
  const [selectedAnimalName, setSelectedAnimalName] = useState('')
  const [selectedAnimalGender, setSelectedAnimalGender] = useState('')
  const [selectedAnimalAge, setSelectedAnimalAge] = useState('')
  const [accountScriptList, setAccountScriptlist] = useState([])
  const [clientList, setClientList] = useState([])
  const [animalsClientList, setAnimalsClientList] = useState([])
  const [ownerId, setOwnerId] = useState(null)
  const [animalId, setAnimalId] = useState(null)
  const [whatsappNumbers, setWhatsappNumbers] = useState(null)
  const [formData, setFormData] = useState({
    account_id: account_id,
    owner_id: null,
    animal_id: null,
    cadence_id: 1, // Alterar cadence_id para campaign_id na base de dados de approachs
    script_id: null,
    message: '',
    media: 'whatsapp', // 'whatsapp'/'email'
    status: 'sent' //'sent', 'received', 'read', 'responded', 'scheduled', 'invoiced'
  })
  const { message } = formData

  useEffect(() => {
    // console.log('Approach formData:', formData)
    // console.log('formData message:', message)
    // console.log('whatsappNumbers: ', whatsappNumbers)
    // console.log('ownerId: ', ownerId)
    // console.log('animalId: ', animalId)
    // console.log('selectedClientName: ', selectedClientName)
    // console.log('selectedAnimalName: ', selectedAnimalName)
    // console.log('selectedAnimalGender: ', selectedAnimalGender)
    // console.log('selectedAnimalAge: ', selectedAnimalAge)
    // console.log('script_id: ', script_id)

    atualizarMensagem(
      message,
      selectedClientName,
      selectedAnimalName,
      selectedAnimalAge,
      selectedAnimalGender
    )
  }, [
    message,
    account_id,
    script_id,
    formData,
    scriptIdFromParams,
    whatsappNumbers,
    ownerId,
    animalId,
    selectedClientName,
    selectedAnimalName,
    selectedAnimalGender,
    selectedAnimalAge,
    accountName
  ])

  function atualizarMensagem(
    message,
    selectedClientName,
    selectedAnimalName,
    selectedAnimalAge,
    selectedAnimalGender
  ) {
    const termosParaSubstituir = {
      '{{NomeEmpresa}}': accountName,
      '{{TutorNome}}': selectedClientName,
      '{{AnimalNome}}': selectedAnimalName,
      '{{AnimalIdade}}': selectedAnimalAge,
      '{{o/a}}': animalThe(selectedAnimalGender),
      '{{ele/ela}}': animalHeShe(selectedAnimalGender)
    }

    const regex = new RegExp(Object.keys(termosParaSubstituir).join('|'), 'g')

    const mensagemAtualizada = message.replace(regex, (termo) => termosParaSubstituir[termo])

    return setUpdatedMessage(mensagemAtualizada)
  }

  function animalHeShe(gender) {
    return gender === 'female' ? 'ela' : 'ele'
  }

  function animalThe(gender) {
    return gender === 'female' ? 'a' : 'o'
  }

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      message: updatedMessage
    }))
  }, [updatedMessage])

  useEffect(() => {
    async function getAccountScripts() {
      try {
        const { data } = await axios.get(`/scripts?account_id=${account_id}`, { headers: authHeader() })
        setAccountScriptlist(data.data)

        // console.log('setAccountScriptlist', data.data)
      } catch (error) {
        console.log('Erro ao carregar scripts: ', error)
      }
    }

    async function getClients() {
      try {
        const { data } = await axios.get(`/clients?account_id=${account_id}`, { headers: authHeader() })

        // console.log('Clientes:', data.data)
        setClientList(data.data)
      } catch (error) {
        console.log('Erro', error.response.data)
      }
    }

    async function getAccountName() {
      try {
        const { data } = await axios.get(`/accounts/${account_id}`, { headers: authHeader() })

        // console.log('Conta:', data.name)
        setAccountName(data.name)
      } catch (err) {
        console.log('Erro: ', err.response.data)
      }
    }

    if (account_id) {
      getAccountScripts()
      getClients()
      getAccountName()
    }
  }, [])

  useEffect(() => {
    async function getAnimalsFromClientId(ownerId) {
      try {
        const { data } = await axios.get(`/animals?account_id=${account_id}&owner_id=${ownerId}`, {
          headers: authHeader()
        })

        // console.log('Animals :', data.data)
        setAnimalsClientList(data.data)
      } catch (error) {
        console.log('Erro', error.response.data)
      }
    }

    if (ownerId) {
      getAnimalsFromClientId(ownerId)
    }
  }, [ownerId, account_id])

  const [sendAlert, setSendAlert] = useState('')

  function getWhatsAppNumberFromClient(ownerId) {
    const found = clientList.find((client) => client.id == ownerId)
    return found ? setWhatsappNumbers(found.whatsapp) : null
  }

  function getSelectedClientName(ownerId) {
    const found = clientList.find((client) => client.id == ownerId)
    return found ? setSelectedClientName(found.first_name) : null
  }

  function getSelectedAnimalName(animalId) {
    const found = animalsClientList.find((animal) => animal.id == animalId)
    return found ? setSelectedAnimalName(found.name) : null
  }

  function getSelectedAnimalGender(animalId) {
    const found = animalsClientList.find((animal) => animal.id == animalId)
    return found ? setSelectedAnimalGender(found.gender) : null
  }

  function getSelectedAnimalAge(animalId) {
    const found = animalsClientList.find((animal) => animal.id == animalId)
    return found ? setSelectedAnimalAge(calcularIdadeParaMensagem(found.birthdate)) : null
  }

  const handleChange = (event) => {
    const { name, value } = event.target

    if (name === 'script_id') {
      const scriptId = +value
      const scriptselectedis = accountScriptList.find((script) => script.id === scriptId)
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: parseInt(scriptId, 10),
        message: scriptselectedis.message
      }))
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value
      }))
    }
  }

  const handleClientChange = (event) => {
    const { value } = event.target

    setFormData((prevFormData) => ({
      ...prevFormData,
      owner_id: parseInt(value, 10),
      script_id: '',
      message: ''
    }))

    console.log('formdata Depois do handleClientChange: ', formData)

    getWhatsAppNumberFromClient(value)
    setOwnerId(value)
    setSelectedClientName(() => getSelectedClientName(value))

    console.log('selec', selectedClientName)
  }

  const handleAnimalChange = (event) => {
    const { value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      animal_id: parseInt(value, 10),
      script_id: 999,
      message: ''
    }))
    setAnimalId(value)

    getSelectedAnimalName(value)
    getSelectedAnimalGender(value)
    getSelectedAnimalAge(value)
  }

  const handleChangeWhatsappNumbersList = (event) => {
    const { value } = event.target
    const numbersArray = value.split(',').map((number) => number.trim())

    setWhatsappNumbers({ numbers: numbersArray })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    // await processWhatsappNumbers(whatsappNumbers) // responsável por gerar um Delay e enviar cadencia de mensagens com intervalo aleatório.

    sendPostRequest(whatsappNumbers)

    console.log('handleSubmit acionado.')

    try {
      const response = await axios.post('/approachs', formData, {
        headers: authHeader()
      })
      console.log('Abordagem registrada com sucesso!', response.data)
      // setClientId(response.data.id)
      setSendAlert('success')
    } catch (error) {
      console.error('Ocorreu um erro ao cadastrar o script:', error.response.data)
      setSendAlert('error')
    }

    console.log(formData)
  }

  const getRandomDelay = () => {
    return Math.floor(Math.random() * (50 - 10 + 1) + 10) * 1000
  }

  const sendPostRequest = async (number) => {
    const wapiUrl = 'https://wapi.vetsales.com.br/message/sendText/551151283600'

    console.log('number do sendPostRequest: ', number)

    const whastappMessageToken = {
      number,
      options: {
        delay: 0,
        presence: 'composing',
        linkPreview: false
      },
      textMessage: {
        text: formData.message
      }
    }
    console.log('whastappMessageToken: ', whastappMessageToken)

    try {
      const response = await fetch(wapiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // apikey: 'wyjrdjr2zqj4fdw5fi53nu'
          apikey: 'fsdl4atsb7iw05bw4dhv'
        },
        body: JSON.stringify(whastappMessageToken)
      })
      console.log('response do fetch: ', response)
      if (!response.ok) {
        throw new Error(`Erro na requisição: ${response.statusText}`)
      }

      const data = await response.json()
      console.log(`Successfully sent to ${number}:`, data)
    } catch (error) {
      console.error(`Error sending to ${number}:`, error)
    }
  }

  const processWhatsappNumbers = async ({ numbers }) => {
    console.log('processWhatsappNumbers Função: ', numbers)

    for (let i = 0; i < numbers.length; i++) {
      console.log('numbers[i]: ', numbers[i])

      await sendPostRequest(numbers[i])

      if (i < numbers.length - 1) {
        const randomDelay = getRandomDelay()

        // Assegura que o delay é de pelo menos 14 segundos
        const delay = Math.max(randomDelay, 14000)

        console.log(`Waiting for ${delay / 1000} seconds before sending to the next number...`)

        await new Promise((resolve) => setTimeout(resolve, delay))
      }
    }
  }

  function calcularIdade(birthdate) {
    const nascimento = new Date(birthdate)
    const hoje = new Date()

    let anos = hoje.getFullYear() - nascimento.getFullYear()
    let meses = hoje.getMonth() - nascimento.getMonth()

    if (meses < 0) {
      anos--
      meses += 12
    }

    return `${anos} anos e ${meses} meses`
  }

  function calcularIdadeParaMensagem(birthdate) {
    const nascimento = new Date(birthdate)
    const hoje = new Date()

    let anos = hoje.getFullYear() - nascimento.getFullYear()
    let meses = hoje.getMonth() - nascimento.getMonth()

    if (meses < 0) {
      anos--
      meses += 12
    }

    return `${anos} anos`
  }

  return (
    <>
      <Card>
        <form onSubmit={handleSubmit}>
          <FormControl>
            <Text display="flex" ms="4px" fontSize="xl" fontWeight="800" color={textColor} mb="8px">
              Nova Abordagem
            </Text>
            <SimpleGrid columns={{ base: '1', md: '2' }} gap="0px 20px">
              <Flex direction="column">
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Cliente
                </FormLabel>
                <Select
                  value={formData.owner_id}
                  name="owner_id"
                  onChange={handleClientChange}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="Selecione"
                  mb="24px"
                  fontWeight="500"
                  size="md"
                >
                  {clientList.map((client) => (
                    <option value={parseInt(client.id, 10)} key={client.id}>
                      {`${client.first_name} ${client.last_name}`}
                    </option>
                  ))}
                </Select>
              </Flex>
              <FormControl id="breed" isDisabled={!formData.owner_id}>
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Animal
                </FormLabel>
                <Select
                  value={animalsClientList.id}
                  name="animal_id"
                  onChange={handleAnimalChange}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="Selecione"
                  mb="24px"
                  fontWeight="500"
                  size="md"
                >
                  {animalsClientList.map((animal) => (
                    <option value={parseInt(animal.id, 10)} key={animal.id}>
                      {`${animal.name}, ${animal.species} ${animal.breed}, ${animal.gender}, ${calcularIdade(
                        animal.birthdate
                      )}`}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {/* <FormControl>
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Assunto
                </FormLabel>
                <Input
                  value={formData.name}
                  name="name"
                  onChange={handleChange}
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="Nome"
                  mb="24px"
                  fontWeight="500"
                  size="md"
                />
              </FormControl> */}
              <FormControl id="script" isDisabled={!formData.animal_id || formData.script_id === ''}>
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Script
                </FormLabel>
                <Select
                  value={formData.script_id}
                  name="script_id"
                  onChange={handleChange}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  mb="24px"
                  fontWeight="500"
                  size="md"
                >
                  <option value={''} key={' '}>
                    Selecione
                  </option>
                  {accountScriptList.map((value) => {
                    if (value.step === 'Primeira abordagem')
                      return (
                        <option value={value.id} key={value.id}>
                          {value.subject}
                        </option>
                      )
                  })}
                </Select>
              </FormControl>
            </SimpleGrid>
            <FormControl display="flex" flexDirection={'column'}>
              <FormLabel display="flex" fontSize="sm" color={textColor} mb="8px">
                WhatsApp do cliente
              </FormLabel>
              <Input
                name="numbers"
                value={whatsappNumbers}
                onChange={handleChangeWhatsappNumbersList}
                isRequired={true}
                variant="filled"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                placeholder="exemplo: 55 11 51283600"
                fontWeight="500"
                size="md"
                mb="24px"
              />
              {/* <FormHelperText mb="24px">{`Exemplo: 551151283600`}</FormHelperText> */}
            </FormControl>
            <FormControl display="flex" flexDirection={'column'}>
              <FormLabel display="flex" fontSize="sm" color={textColor} mb="8px">
                Menssagem
              </FormLabel>
              <Textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                isRequired={true}
                variant="filled"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                placeholder="Olá {{TutorNome}}, tudo bem?"
                fontWeight="500"
                size="md"
                ref={textareaRef}
              />
              <FormHelperText mb="24px">
                <AddTermToInput textToCopy="{{TutorNome}}" textareaRef={textareaRef} mr="5px" />
                <AddTermToInput textToCopy="{{AnimalNome}}" textareaRef={textareaRef} />
                <AddTermToInput textToCopy="{{AnimalIdade}}" textareaRef={textareaRef} />
                <AddTermToInput textToCopy="{{o/a}}" textareaRef={textareaRef} />
                <AddTermToInput textToCopy="{{ele/ela}}" textareaRef={textareaRef} />
                <AddTermToInput textToCopy={`${accountName}`} textareaRef={textareaRef} />
                <Text fontSize={'sm'} mt="-10px" pl="10px">
                  Clique nas opções acima para adicionar os termos na sua mensagem.
                </Text>
              </FormHelperText>
            </FormControl>

            <Button
              type="submit"
              fontSize="sm"
              background="#49BFFF"
              color="white"
              fontWeight="800"
              w="100%"
              h="50"
              mb="24px"
              borderRadius="50px"
            >
              Enviar
            </Button>
            {sendAlert === 'success' && (
              <Alert status="success">
                <AlertIcon />
                <AlertTitle>Sucesso!</AlertTitle>
                <AlertDescription>Dados enviados com sucesso!</AlertDescription>
              </Alert>
            )}
          </FormControl>
        </form>
      </Card>
    </>
  )
}
