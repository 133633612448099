// import React, { useState, useEffect } from 'react'
// import { Text, useStyles } from '@chakra-ui/react'
// import feathers from '@feathersjs/feathers'
// import socketio from '@feathersjs/socketio-client'
// import io from 'socket.io-client'
// import Peer from 'peerjs'

// export default function Telemedicine() {
//   const [roomId, setRoomId] = useState(null)

//   const socket = io('http://localhost:3034')
//   const app = feathers()

//   const video = { width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px' }

//   const ROOM_ID = '<%= roomId %>'

//   useEffect(() => {
//     app.configure(socketio(socket))
//   }, [socket])

//   const videoGrid = document.getElementById('video-grid')
//   const myPeer = new Peer(undefined, {
//     host: '/',
//     port: '3450'
//   })
//   const myVideo = document.createElement('video')
//   myVideo.muted = true
//   const peers = {}
//   navigator.mediaDevices
//     .getUserMedia({
//       video: true,
//       audio: true
//     })
//     .then((stream) => {
//       addVideoStream(myVideo, stream)

//       myPeer.on('call', (call) => {
//         call.answer(stream)
//         const video = document.createElement('video')
//         call.on('stream', (userVideoStream) => {
//           addVideoStream(video, userVideoStream)
//         })
//       })

//       socket.on('user-connected', (userId) => {
//         connectToNewUser(userId, stream)
//       })
//     })

//   socket.on('user-disconnected', (userId) => {
//     if (peers[userId]) peers[userId].close()
//   })

//   myPeer.on('open', (id) => {
//     socket.emit('join-room', ROOM_ID, id)
//   })

//   function connectToNewUser(userId, stream) {
//     const call = myPeer.call(userId, stream)
//     const video = document.createElement('video')
//     call.on('stream', (userVideoStream) => {
//       addVideoStream(video, userVideoStream)
//     })
//     call.on('close', () => {
//       video.remove()
//     })

//     peers[userId] = call
//   }

//   function addVideoStream(video, stream) {
//     video.srcObject = stream
//     video.addEventListener('loadedmetadata', () => {
//       video.play()
//     })
//     videoGrid.append(video)
//   }

//   return <div id="video-grid"></div>
// }

import React, { useState, useEffect, useRef } from 'react'
import { Box, Button } from '@chakra-ui/react'
import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import io from 'socket.io-client'
import Peer from 'peerjs'

export default function Telemedicine() {
  const [roomId, setRoomId] = useState('<%= roomId %>')
  const videoGridRef = useRef(null)
  const socket = io('http://localhost:3034')
  const app = feathers()
  const myPeer = new Peer(undefined, {
    host: '/',
    port: '3450'
  })
  const peers = {}

  const [showVideo, setShowvideo] = useState(true)

  useEffect(() => {
    app.configure(socketio(socket))

    const myVideo = document.createElement('video')
    myVideo.muted = true
    myVideo.controls = true
    myVideo.style.borderRadius = '20px'

    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: true
      })
      .then((stream) => {
        addVideoStream(myVideo, stream)

        myPeer.on('call', (call) => {
          call.answer(stream)
          const video = document.createElement('video')
          call.on('stream', (userVideoStream) => {
            addVideoStream(video, userVideoStream)
          })
        })

        socket.on('user-connected', (userId) => {
          connectToNewUser(userId, stream)
        })
      })

    socket.on('user-disconnected', (userId) => {
      if (peers[userId]) peers[userId].close()
    })

    myPeer.on('open', (id) => {
      socket.emit('join-room', roomId, id)
    })

    return () => {
      socket.disconnect()
      myPeer.disconnect()
    }
  }, [socket, roomId])

  const connectToNewUser = (userId, stream) => {
    const call = myPeer.call(userId, stream)
    const video = document.createElement('video')
    call.on('stream', (userVideoStream) => {
      addVideoStream(video, userVideoStream)
    })
    call.on('close', () => {
      video.remove()
    })

    peers[userId] = call
  }

  const addVideoStream = (video, stream) => {
    video.srcObject = stream
    video.addEventListener('loadedmetadata', () => {
      video.play()
    })
    if (videoGridRef.current) {
      videoGridRef.current.append(video)
    }
  }

  return <Box ref={videoGridRef} id="video-grid" width="100%" height="100%" />
}
