import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import { getUserAuthentication, getUserAccountId } from 'hooks/authService'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Flex,
  HStack,
  Input,
  Link,
  Textarea,
  SimpleGrid,
  Select,
  Stack,
  useToast,
  useColorModeValue,
  Text
} from '@chakra-ui/react'
import Card from '../../../components/card/Card'
import { Icon } from '@chakra-ui/react'
import { MdAdd } from 'react-icons/md'
import AccountForm from './components/accountForm'
import { useLocation } from 'react-router-dom'

export default function AccountForms(props) {
  // const { accountData } = props
  const textColor = useColorModeValue('navy.700', 'white')

  const showAccounts = window.location.href.includes('/admin/account/')
  const newAccount = window.location.href.includes('/admin/new-account')

  const location = useLocation()
  const locationAccountId = parseInt(location.pathname.replace('/admin/account/', ''), 10)
  // console.log('showAccounts', showAccounts, locationAccountId)

  const userAccountId = getUserAccountId()
  // console.log('userAccountId', userAccountId)
  // console.log('newAccount', newAccount)

  const [accountData, setAccountData] = useState({
    name: '',
    business_data: {
      cnpj: '',
      employees: ''
    },
    address: '',
    contact_data: {
      emails: [
        {
          type: '',
          address: ''
        }
      ],
      phones: [
        {
          ddd: '',
          type: '',
          number: ''
        }
      ],
      website: '',
      instagram: ''
    },
    company_logo_url: '',
    settings: {
      approach_min_interval: 20,
      max_daily_triggers: 200,
      api_instance_name: '',
      api_instance_token: ''
    }
  })

  useEffect(() => {
    getAccountData()
  }, [])

  const getAccountData = async () => {
    if (newAccount) return
    if (showAccounts && locationAccountId) {
      try {
        const { data } = await axios(`/accounts/${locationAccountId}`, { headers: authHeader() })
        console.log('data: ', data)
        setAccountData(data)
      } catch (error) {
        console.log('Erro ao carregar dados da conta: ', error)
      }
    } else {
      try {
        const { data } = await axios(`/accounts/${userAccountId}`, { headers: authHeader() })
        setAccountData(data)
      } catch (error) {
        console.log('Erro ao carregar dados da conta: ', error)
      }
    }
  }

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <AccountForm accountData={accountData} isNewAccount={newAccount} />
    </Box>
  )
}
