// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue
} from '@chakra-ui/react'
// Assets
import Usa from 'assets/img/dashboards/usa.png'
// Custom components
import MiniCalendar from 'components/calendar/MiniCalendar'
import MiniStatistics from 'components/card/MiniStatistics'
import IconBox from 'components/icons/IconBox'
import React from 'react'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { MdAddTask, MdAttachMoney, MdBarChart, MdFileCopy } from 'react-icons/md'
import Tasks from 'views/public/default/components/Tasks'
import ChatOttenAi from './components/ChatOttenAI'

export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue('brand.500', 'white')
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100')

  return (
    // <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
    <Box>
      <Grid templateColumns="repeat(6, 1fr)" gap={'20px'}>
        {/* <GridItem colSpan={1}></GridItem> */}
        {/* <GridItem colSpan={1}></GridItem> */}
        <GridItem colSpan={6}>
          <ChatOttenAi />
        </GridItem>
      </Grid>
    </Box>
  )
}
