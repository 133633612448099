import React, { useState, useEffect } from 'react'
// Chakra imports
import { Box, Text, SimpleGrid, Flex, useColorModeValue, Button } from '@chakra-ui/react'
// Custom components
import Card from 'components/card/Card.js'
import { v4 } from 'uuid'

import axios from 'api/axios'
import authHeader from 'hooks/authHeader'

export default function Information(props) {
  const [sendAlert, setSendAlert] = useState('')
  const { watchedData, volunteer, ...rest } = props
  const { id, name, age, status, city, flag, special_conditions } = watchedData
  const { id: volunteerId } = volunteer

  console.log('volunteer', volunteer)

  const now = Date.now()

  const [formData, setFormData] = useState({
    watched_id: id,
    scheduled_at: new Date(now).toISOString(),
    status: 'waiting',
    meet_link: v4(), //uuidV4 aqui. depois, no server, pegar roomId pelo params
    flag,
    volunteer_id: volunteerId
  })

  console.log('formdata', formData)

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')
  const textColorSecondary = 'gray.400'
  const bg = useColorModeValue('white', 'navy.700')

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const response = await axios.post('/consultations', formData, {
        headers: authHeader()
      })
      console.log('Dados atualizados com sucesso!', response.data)
      setSendAlert('success')
    } catch (error) {
      setSendAlert('error')
      console.error('Ocorreu um erro ao enviar os dados:', error.response.data)
    }

    console.log(formData)
  }

  return (
    <Card bg={bg} {...rest} mb={5}>
      <Box>
        <SimpleGrid columns={5} gap={2}>
          <Flex flexDirection="column">
            <Text fontWeight="500" color={textColorSecondary} fontSize="sm">
              <span style={{ color: flag, marginRight: '5px' }}>•</span>
              {name}
            </Text>
            <Text color={textColorPrimary} fontWeight="500" fontSize="sm">
              {age}
            </Text>
          </Flex>
          <Flex flexDirection="column">
            <Text fontWeight="500" color={textColorSecondary} fontSize="sm">
              Disponibilidade
            </Text>
            <Text color={textColorPrimary} fontWeight="500" fontSize="sm">
              {status}
            </Text>
          </Flex>
          <Flex flexDirection="column">
            <Text fontWeight="500" color={textColorSecondary} fontSize="sm">
              Cidade
            </Text>
            <Text color={textColorPrimary} fontWeight="500" fontSize="sm">
              {city}
            </Text>
          </Flex>
          <Flex flexDirection="column">
            <Text fontWeight="500" color={textColorSecondary} fontSize="sm">
              Condições
            </Text>
            <Text color={textColorPrimary} fontWeight="500" fontSize="sm">
              {special_conditions}
            </Text>
          </Flex>
          <Button
            style={{ background: 'rgb(157 225 115)', borderRadius: '50px', fontWeight: '500' }}
            w="120px"
            fontSize="sm"
            onClick={handleSubmit}
          >
            Atender agora
          </Button>
        </SimpleGrid>
      </Box>
    </Card>
  )
}
