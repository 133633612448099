import React, { useState, useEffect } from 'react'
import {
  InputGroup,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  Stack
} from '@chakra-ui/react'

function IntervalInput({ onChange, value: initialValue = 0 }) {
  const [number, setNumber] = useState(1)
  const [unit, setUnit] = useState('months')

  useEffect(() => {
    const initialUnit = millisecondsToUnit(initialValue)
    setNumber(initialUnit.value)
    setUnit(initialUnit.unit)
  }, [initialValue])

  const handleNumberChange = (valueString) => {
    const value = parseInt(valueString, 10) || 0 // Garante que seja um inteiro
    setNumber(value)
    onChange(valueToMilliseconds(value, unit))
  }

  const handleUnitChange = (event) => {
    const newUnit = event.target.value
    setUnit(newUnit)
    onChange(valueToMilliseconds(number, newUnit))
  }

  const valueToMilliseconds = (value, unit) => {
    const multipliers = {
      seconds: 1000,
      minutes: 60 * 1000,
      hours: 60 * 60 * 1000,
      days: 24 * 60 * 60 * 1000,
      weeks: 7 * 24 * 60 * 60 * 1000,
      months: 30.44 * 24 * 60 * 60 * 1000, // Média aproximada
      years: 365.25 * 24 * 60 * 60 * 1000 // Média aproximada
    }
    return value * multipliers[unit]
  }

  const millisecondsToUnit = (milliseconds) => {
    const units = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds']

    for (const unit of units) {
      const value = parseInt(milliseconds / valueToMilliseconds(1, unit))
      if (value >= 1) {
        return { value, unit }
      }
    }

    return { value: 6, unit: 'months' }
  }

  return (
    <Stack>
      <InputGroup>
        {/* <InputLeftAddon> */}
        <Select
          value={unit}
          onChange={handleUnitChange}
          variant="auth"
          fontSize="sm"
          placeholder="Selecione o período"
          fontWeight="500"
          size="md"
          borderRadius=" 16px 0 0 16px"
        >
          <option value="seconds">Segundos</option>
          <option value="minutes">Minutos</option>
          <option value="hours">Horas</option>
          <option value="days">Dias</option>
          <option value="weeks">Semanas</option>
          <option value="months">Meses</option>
          <option value="years">Anos</option>
        </Select>
        {/* </InputLeftAddon> */}
        <NumberInput value={number} onChange={handleNumberChange} min={0} precision={0}>
          <NumberInputField borderRadius="0 16px 16px 0" />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </InputGroup>
    </Stack>
  )
}

export default IntervalInput
