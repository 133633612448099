// import React, { useState, useEffect, forwardRef } from 'react'
// import { Button, useToast } from '@chakra-ui/react'

// const AddTermToInput = forwardRef(({ textToCopy, textareaRef }) => {
//   const toast = useToast()

//   const handleClick = async () => {
//     try {
//       await navigator.clipboard.writeText(textToCopy)

//       // Usa a API do React para atualizar o textarea
//       textareaRef.current.focus()
//       document.execCommand('insertText', false, textToCopy)

//       toast({
//         title: 'Texto copiado e colado com sucesso!',
//         status: 'success',
//         duration: 3000,
//         isClosable: true
//       })
//     } catch (err) {
//       toast({
//         title: 'Falha ao copiar/colar o texto.',
//         description: err.message,
//         status: 'error',
//         duration: 5000,
//         isClosable: true
//       })
//     }
//   }

//   return (
//     <Button
//       onClick={handleClick}
//       mb={4}
//       fontSize={'xs'}
//       borderRadius={'50px'}
//       p="2px 15px"
//       bg="#F4F7FE"
//       mr="5px"
//     >
//       {textToCopy}
//     </Button>
//   )
// })

// export default AddTermToInput

import React, { forwardRef } from 'react'
import { Button, useToast } from '@chakra-ui/react'

const AddTermToInput = forwardRef(({ textToCopy, textareaRef, setTextAreaValue }, ref) => {
  const toast = useToast()

  const handleClick = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy)

      // Get the current textarea value and selection
      const textarea = textareaRef.current
      const currentText = textarea.value
      const start = textarea.selectionStart
      const end = textarea.selectionEnd

      // Calculate the new value with the inserted text
      const newValue = currentText.substring(0, start) + textToCopy + currentText.substring(end)

      // Update the textarea value
      setTextAreaValue(newValue)

      // Update the cursor position
      textarea.selectionStart = textarea.selectionEnd = start + textToCopy.length
      textarea.focus()

      toast({
        title: 'Texto copiado e colado com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true
      })
    } catch (err) {
      toast({
        title: 'Falha ao copiar/colar o texto.',
        description: err.message,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  return (
    <Button
      onClick={handleClick}
      mb={4}
      fontSize={'xs'}
      borderRadius={'50px'}
      p="2px 15px"
      bg="#F4F7FE"
      mr="5px"
    >
      {textToCopy}
    </Button>
  )
})

export default AddTermToInput
