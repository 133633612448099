import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue
} from '@chakra-ui/react'
// Custom components
// import { HSeparator } from 'components/separator/Separator'
// import SignInForm from './components/SignInForm'
import DefaultAuth from 'layouts/auth/Default'
// Assets
import illustration from 'assets/img/auth/portoalegre_reconstrucao.jpg'
import { FcGoogle } from 'react-icons/fc'
import { MdOutlineRemoveRedEye } from 'react-icons/md'
import { RiEyeCloseLine } from 'react-icons/ri'
import { loginLocal } from '../../../hooks/authService'
import { registerWhatched } from '../../../hooks/authService'
import NewUser from '../newUser'

import axios from 'axios'

function SignUp() {
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white')
  const textColorSecondary = 'gray.400'
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600')
  const textColorBrand = useColorModeValue('brand.500', 'white')
  const brandStars = useColorModeValue('brand.500', 'brand.400')
  const googleBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.200')
  const googleText = useColorModeValue('navy.700', 'white')
  const googleHover = useColorModeValue({ bg: 'gray.200' }, { bg: 'whiteAlpha.300' })
  const googleActive = useColorModeValue({ bg: 'secondaryGray.300' }, { bg: 'whiteAlpha.200' })

  const [loginData, setLoginData] = useState(0)

  const [geoData, setGeoData] = useState({})

  useEffect(() => {
    fetch('https://api.ipgeolocation.io/ipgeo?apiKey=3a086497e1ce47a5b4e6aa3f29222e1a')
      .then((response) => response.json())
      .then((data) => {
        const visitorInformation = {
          ip: data.ip,
          isp: data.isp,
          state_code: data.state_code,
          city: data.city,
          zipcode: data.zipcode,
          latitude: data.latitude,
          longitude: data.longitude,
          calling_code: data.calling_code,
          time_zone: data.time_zone.offset,
          current_time: data.time_zone.current_time
        }
        console.log('Dados de geolocalização:', visitorInformation)
        setGeoData(visitorInformation)
      })
      .catch((error) => {
        console.error('Error fetching IP:', error)
      })
  }, [])

  return (
    <Flex position="relative" h="max-content">
      <Flex
        h={{
          sm: 'initial',
          md: 'unset',
          lg: '100vh'
          // xl: '97vh'
        }}
        w="100%"
        maxW={{ md: '66%', lg: '1313px' }}
        mx="auto"
        pt={{ sm: '50px', md: '0px' }}
        px={{ lg: '30px', xl: '0px' }}
        ps={{ xl: '70px' }}
        justifyContent="start"
        direction="column"
      >
        <Box mx="auto" mt="10%">
          <NewUser />
        </Box>
      </Flex>
    </Flex>
  )
}

export default SignUp
