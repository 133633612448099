import axios from '../api/axios'
import authHeader from './authHeader'
import { useState } from 'react'

export async function loginLocal(email, password, signInData) {
  try {
    const response = await axios.post('/authentication', {
      email: email,
      password: password,
      strategy: 'local'
    })

    const { accessToken, user } = response && response.data

    if (accessToken) {
      localStorage.setItem('user', JSON.stringify(response.data))
      console.log(`Olá ${user.first_namee}!`, response.data)

      if (signInData && signInData.cpf) {
        const patch = await axios.patch(`/users/${user.id}`, signInData, {
          headers: authHeader()
        })

        console.log('patch 1: ', patch.data)
      }

      setTimeout(() => {
        window.location.href = '/admin/painel'
      }, 600)
    }
  } catch (error) {
    console.error('verifique os dados de login e senha.', error)
  }
}

export async function newMainUser(name, email, password, user_data) {
  const { phone1 } = user_data
  const whatsappMessage = `${name}, seja bem vindo! Esse é o nosso canal de atendimento principal. Salve este número na sua agenda.`

  const role = 'main'

  const whastappMessageToken = {
    number: phone1,
    options: {
      delay: 0,
      presence: 'composing',
      linkPreview: false
    },
    textMessage: {
      text: whatsappMessage
    }
  }

  const addUserResponse = await axios.post('/users', {
    first_name: name,
    email,
    user_data,
    password,
    role
  })

  console.log('addUserResponse: ', addUserResponse)

  return addUserResponse
}

export function registerUser(nome, email, password, role) {
  return axios.post('/users', {
    nome,
    email,
    password,
    role
  })
}

export function logout() {
  localStorage.removeItem('user')
  window.location.href = '/'
}

export function getUserAuthentication() {
  // let authenticationLocalStorageData
  const userData = JSON.parse(localStorage.getItem('user'))
  const expirationDate = parseInt(userData?.authentication?.payload?.exp, 10)
  const timeNow = parseInt(Math.floor(new Date().getTime() / 1000), 10)
  const audience = userData?.authentication?.payload?.aud

  // console.log(`now: ${timeNow}, expirationDate: ${expirationDate}, audience: ${audience}`)
  // console.log(`userData:`, userData)
  // console.log('Token Valid: ', expirationDate >= timeNow)
  // console.log('Audience valid: ', audience === 'https://vetsales.com.br')

  if (expirationDate > timeNow && audience === 'https://vetsales.com.br') {
    return userData
  }
  return false
}

export function getUserAccountId() {
  const userToken = JSON.parse(localStorage.getItem('user'))
  const accountId = userToken?.user?.user_data?.main_account_id
  return accountId
}

export function getUserAuthenticationRole() {
  const userToken = JSON.parse(localStorage.getItem('user'))
  const role = userToken?.user?.role
  return 'main'
}
