export function capitalizeText(frase) {
  let palavras = (frase && frase.toLowerCase().split(' ')) || {};

  for (let i = 0; i < palavras.length; i++) {
    if (palavras[i] !== '') {
      palavras[i] = palavras[i].charAt(0).toUpperCase() + palavras[i].slice(1);
    }
  }

  return palavras.join(' ');
}
