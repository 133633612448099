import React, { useState, useEffect } from 'react'
import {
  Alert,
  AlertIcon,
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Button,
  Stack,
  Text,
  Textarea,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightElement,
  Select,
  SimpleGrid
} from '@chakra-ui/react'
import {
  FaWhatsapp,
  FaEnvelope,
  FaInstagram,
  FaHome,
  FaLinkedin,
  FaFacebook,
  FaIndustry,
  FaUserTie,
  FaClock
} from 'react-icons/fa'
import { MdContentCopy } from 'react-icons/md'
import { PhoneIcon } from '@chakra-ui/icons'
import axios from 'axios'

const VetSalesLinkGenerator = (props) => {
  const { responsavel, whatsappEmpresa, nomeFantasia } = props

  const [nome, setNome] = useState(responsavel || '')
  const [clinica, setClinica] = useState(nomeFantasia || '')
  const [dealId, setDealId] = useState('')
  const [whatsapp, setWhatsapp] = useState(whatsappEmpresa || '')

  useEffect(() => {
    console.log('props:', props)
  }, [responsavel, whatsappEmpresa, nomeFantasia, props])

  const generateLink = () => {
    const encodedNome = encodeURIComponent(nome)
    const encodedClinica = encodeURIComponent(clinica)
    const encodedDealId = encodeURIComponent(dealId)
    return `https://vetsales.otten.com.br/vetsales-lp1/?p1=${encodedNome}${
      encodedClinica ? `&p2=${encodedClinica}` : ''
    }${encodedDealId ? `&p3=${encodedDealId}` : ''}`
  }

  const link = generateLink()

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link)
    alert('Link copiado para o clipboard!')
  }

  const handleSendMessage = async () => {
    const whatsappMessageToken = {
      number: whatsapp,
      options: {
        delay: 0,
        presence: 'composing',
        linkPreview: false
      },
      textMessage: {
        text: `Olá, conforme  ${nome}, segue o link ${link}`
      }
    }

    try {
      const response = await fetch('https://wapi.vetsales.com.br/message/sendText/1151283600', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          apikey: 'wtwHLYfFxI9n1zDadvafLpcjVmXBm'
        },
        body: JSON.stringify(whatsappMessageToken)
      })
      if (response.status === 200) {
        alert('Mensagem enviada com sucesso!')
      } else {
        alert('Falha ao enviar a mensagem.')
      }
    } catch (error) {
      console.error('Erro ao enviar a mensagem:', error)
      alert('Erro ao enviar a mensagem.')
    }
  }

  return (
    <Box>
      <form>
        <FormControl id="contato1" mb="8px">
          <Text
            style={{
              fontSize: '14px',
              marginBottom: '15px',
              marginLeft: '15px'
            }}
          >
            Link de Apresentação
          </Text>
          <InputGroup>
            <InputLeftAddon
              width="50px"
              style={{
                backgroundColor: '#FAFCFE',
                color: '#A0AEC0',
                borderRadius: '50px 0 0 50px',
                paddingInlineEnd: '0'
              }}
            >
              <Icon as={FaUserTie} mr="16px" />
            </InputLeftAddon>

            <Input
              type="text"
              name="contato1PrimeiroNome"
              placeholder="Nome do decisor"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              style={{ borderRadius: '0 50px 50px 0', width: '310px' }}
            />
          </InputGroup>
        </FormControl>
        <FormControl id="whatsapp" mb="8px">
          <Stack spacing={2}>
            <InputGroup>
              <InputLeftAddon
                width="50px"
                style={{
                  backgroundColor: '#FAFCFE',
                  color: '#A0AEC0',
                  borderTopLeftRadius: '50px',
                  borderBottomLeftRadius: '50px'
                }}
              >
                <Icon as={FaWhatsapp} mr="5px" />
              </InputLeftAddon>

              <Input
                type="tel"
                name="whatsapp"
                placeholder="Whatsapp ou Celular"
                value={whatsapp}
                onChange={(e) => setWhatsapp(e.target.value)}
                maskChar=" "
                mask="(99) 999999999"
                style={{
                  width: '100%',
                  paddingInlineStart: 'var(--chakra-space-4)',
                  paddingInlineEnd: 'var(--chakra-space-10)',
                  height: 'var(--chakra-sizes-10)',
                  border: '1px solid',
                  borderColor: 'inherit',
                  background: 'inherit',
                  borderRadius: '0px 50px 50px 0px',
                  borderLeftRadius: '0px',
                  borderRightRadius: '50px'
                }}
              />
              <InputRightElement width="2.7rem">
                <IconButton
                  colorScheme={whatsapp ? 'green' : 'gray'}
                  aria-label="Call Segun"
                  size="sm"
                  p="1px"
                  onClick={() => {
                    const numerowhatsapp = `${whatsapp}`
                    window.location.href = `https://api.whatsapp.com/send?phone=${numerowhatsapp}`
                  }}
                  icon={<Icon as={FaWhatsapp} />}
                />
              </InputRightElement>
            </InputGroup>
          </Stack>
        </FormControl>
        <FormControl id="nome_fantasia" mb="8px">
          <InputGroup>
            <InputLeftAddon
              width="50px"
              style={{
                backgroundColor: '#FAFCFE',
                color: '#A0AEC0',
                borderRadius: '50px 0 0 50px'
              }}
            >
              <Icon as={FaIndustry} mr="5px" />
            </InputLeftAddon>
            <Input
              type="text"
              name="nome_fantasia"
              placeholder="Nome Fantasia"
              value={clinica}
              onChange={(e) => setClinica(e.target.value)}
              style={{ borderRadius: '0 50px 50px 0' }}
            />
          </InputGroup>
        </FormControl>
        <FormControl id="dealId" mb="8px">
          <InputGroup>
            <InputLeftAddon
              width="50px"
              style={{
                backgroundColor: '#FAFCFE',
                color: '#A0AEC0',
                borderRadius: '50px 0 0 50px'
              }}
            >
              <Icon as={FaIndustry} mr="5px" />
            </InputLeftAddon>
            <Input
              type="text"
              name="dealId"
              placeholder="Id do Negócio"
              value={dealId}
              onChange={(e) => setDealId(e.target.value)}
              style={{ borderRadius: '0 50px 50px 0' }}
            />
          </InputGroup>
        </FormControl>

        <div>
          <Button type="button" onClick={handleCopyLink}>
            <MdContentCopy />
          </Button>

          {whatsapp && (
            <Button type="button" onClick={handleSendMessage}>
              Enviar WhatsApp
            </Button>
          )}
        </div>
      </form>
      <div>
        <Text fontSize="12px" pl="10px">
          {`URL: `}
          <a href={link} target="_blank" rel="nooTextener noreferrer">
            {link}
          </a>
        </Text>
      </div>
    </Box>
  )
}

export default VetSalesLinkGenerator
