import React, { useState, useEffect, useRef } from 'react'
import Card from 'components/card/Card'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  Flex,
  Icon,
  useColorModeValue,
  useDisclosure,
  Text
} from '@chakra-ui/react'
import { MdEdit } from 'react-icons/md'
import ScriptForm from './scriptForm'

export default function ScriptCards({ script }) {
  const { onOpen, onClose, isOpen } = useDisclosure()
  const firstFieldRef = React.useRef(null)
  const iconColor = useColorModeValue('secondaryGray.500', 'white')

  return (
    <Card key={script.id}>
      <Flex flexDirection="column">
        <Text fontWeight={600} mb="12px" display="flex" justifyContent="space-between">
          {`${script.subject}`}
          {script && (
            <Flex>
              <Popover
                isOpen={isOpen}
                initialFocusRef={firstFieldRef}
                onOpen={onOpen}
                onClose={onClose}
                placement="top-start"
                closeOnBlur={false}
              >
                <PopoverTrigger>
                  <Icon
                    as={MdEdit}
                    color={iconColor}
                    w="24px"
                    h="24px"
                    style={{ background: '#fafafa', padding: '5px', borderRadius: '10px' }}
                    cursor="pointer"
                  />
                </PopoverTrigger>
                <PopoverContent p={'10px'} width="600px" borderRadius="20px 10px 20px 20px">
                  <PopoverArrow />
                  <ScriptForm data={script} />
                  <PopoverCloseButton />
                </PopoverContent>
              </Popover>
            </Flex>
          )}
        </Text>
        <Text fontSize="sm">{script.message}</Text>
      </Flex>
    </Card>
  )
}
