// Chakra imports
import {
  Avatar,
  Box,
  Icon,
  Link,
  Select,
  SimpleGrid,
  Text,
  background,
  useColorModeValue,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Input,
  InputGroup,
  InputRightElement,
  Textarea
} from '@chakra-ui/react'

// Custom components
import MiniCalendar from 'components/calendar/MiniCalendar'
import IconBox from 'components/icons/IconBox'
import React, { useState, useEffect } from 'react'
import { MdAddTask, MdAttachMoney, MdBarChart, MdFileCopy, MdPeople, MdVerifiedUser } from 'react-icons/md'
import { logout, getUserAuthentication } from 'hooks/authService'
import authHeader from 'hooks/authHeader'
import axios from 'axios'
import Telemedicine from './components/Telemedicine'
import MeetingRoom from './components/MeetingRoom'

export default function UserReports() {
  const { user } = getUserAuthentication()
  const textColor = useColorModeValue('navy.700', 'white')

  const [lost_home, setLostHome] = useState(null)
  const [lost_loved_ones, setLostLovedOnes] = useState(null)
  const [lost_job, setLostJob] = useState(null)
  const [psicologicAssistance, setPsicologicAssistance] = useState(null)

  const [whatchedProfile, setWhatchedProfile] = useState({
    first_name: user && user.name,
    is_user_id: user && user.id,
    user_owner: user && user.id
    // contact_data: [
    //   {
    //     whatchedFlag: {
    //       color: 'yellow',
    //       lost_his_home: lost_home,
    //       lost_loved_ones: lost_loved_ones,
    //       lost_job: lost_job,
    //       want_psicologic_assistance: psicologicAssistance
    //       // anything: null
    //     }
    //     // contact_sos_name: null,
    //     // contact_sos_phone: null,
    //     // has_special_heath_need: null,
    //     // in_company_with: null,
    //     // is_pragnant: null,
    //     // urgent_needs: null,
    //     // helpfull_habilities: null,
    //     // need_work_assistance: null
    //   }
    // ]
  })

  const handleChange = (event) => {
    const { name, value } = event.target

    setWhatchedProfile((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  const addContactData = async (event) => {
    event.preventDefault()

    // const { lost_his_home, lost_loved_ones, lost_job, need_work_assistance } = whatchedProfile
    try {
      const whatchedFlag = {
        color: 'yellow',
        lost_his_home: lost_home || null,
        lost_loved_ones: lost_loved_ones || null,
        lost_job: lost_job || null,
        want_psicologic_assistance: psicologicAssistance || null
      }

      const response = await axios.post(
        '/contacts',
        {
          first_name: user && user.name,
          is_user_id: user && user.id,
          user_owner: user && user.id,
          contact_data: [JSON.stringify(whatchedFlag)]
        },
        {
          headers: authHeader()
        }
      )

      if (!response.ok) {
        throw new Error(`Erro na requisição: ${response.statusText}`)
      }

      const data = await response.json()
      console.log('Informações do contato adicionado.', data)
    } catch (error) {
      console.error('Erro ao enviar o texto para a API:', error)
      throw error
    }
  }

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <Flex>
          <MeetingRoom />
        </Flex>
        <Telemedicine />

        {/* <EmergencyContact /> */}

        {/* Voluntarios  */}

        <Flex
          p={5}
          direction="column"
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '15px' }}
        >
          <Text fontSize="64px" fontWeight={800}>
            {user.name},
          </Text>
          <Text fontWeight={800} fontSize="md">
            Para sermos mais eficientes, precisamos entender melhor o seu perfil. Responda as perguntas
            abaixo:
          </Text>

          <FormControl mt={5} style={{ background: '#fff', borderRadius: '20px', padding: '30px' }}>
            <form onSubmit={addContactData}>
              <FormLabel display="flex" ms="4px" fontSize="md" fontWeight="800" color={textColor} mb="8px">
                Você foi materialmente afetado pela tragédia?
              </FormLabel>
              <RadioGroup onChange={setLostLovedOnes} mb={6} value={lost_loved_ones}>
                <Stack direction="row">
                  <Radio value="Sim">Sim, diretamente</Radio>
                  <Radio value="Talvez">Sim, indiretamente</Radio>
                  <Radio value="Não">Não</Radio>
                </Stack>
              </RadioGroup>

              <FormLabel display="flex" ms="4px" fontSize="md" fontWeight="800" color={textColor} mb="8px">
                Qual a sua profissão?
              </FormLabel>
              <Select
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                value={1}
                name="anything"
                placeholder="Escreva o que quiser"
                mb="24px"
                fontWeight="500"
                size="lg"
                onChange={handleChange}
                style={{
                  background: '#ffffff',
                  border: '0px',
                  borderRadius: '20px',
                  border: '1px solid #E2E8F0'
                }}
              />
              <FormLabel display="flex" ms="4px" fontSize="md" fontWeight="800" color={textColor} mb="8px">
                CEP de onde mora
              </FormLabel>
              <Input name="uf" value="RJ" />
              <FormLabel display="flex" ms="4px" fontSize="md" fontWeight="800" color={textColor} mb="8px">
                Como pode ajudar?
              </FormLabel>
              <Stack spacing={5} direction="row">
                <Checkbox colorScheme="green" defaultChecked>
                  Fazendo uma doação
                </Checkbox>
                <Checkbox colorScheme="green" defaultChecked>
                  Trabalho voluntário online
                </Checkbox>
                <Checkbox colorScheme="green" defaultChecked>
                  Disponibilizando um espaço como Ponto de Apoio
                </Checkbox>
              </Stack>
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                type="submit"
                style={{ background: '#ffc100', border: '0px', borderRadius: '100px' }}
              >
                Avançar
              </Button>
            </form>
          </FormControl>
        </Flex>

        {/* <SimpleGrid columns={1} gap="20px">
          <Link href="/auth/SignIn">
            <Flex direction="column" style={{ background: '#ffda68', borderRadius: '15px' }} p={5}>
              <Text fontSize="32px" fontWeight={800}>
                Preciso de ajuda.
              </Text>
              <Text>Preciso de ajuda.</Text>
            </Flex>
          </Link>
          <Flex
            direction="column"
            style={{ background: '#8eca78', borderRadius: '15px', color: '#FFFFFF' }}
            p={5}
          >
            <Text fontSize="32px" fontWeight={800} color="#FFFFFF">
              Quero ajudar.
            </Text>
            <Text>Mais de 600mil brasileiros precisam recomeçar as suas vidas.</Text>
          </Flex>
        </SimpleGrid> */}

        {/* <MelhoresProjetos columnsData={columnsDataComplex} tableData={tableDataComplex} />
        <PioresProjetos columnsData={columnsDataComplex} tableData={tableDataComplex} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
          {/* <Tasks /> */}
        {/* <MiniCalendar h='100%' minW='100%' selectRange={false} />
        </SimpleGrid> */}
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 4 }} gap="20px">
        <Flex direction="column" style={{ background: '#FFFFFF', borderRadius: '15px' }} p={5}>
          <Text fontSize={18} fontWeight={800}>
            Apoio psicológico
          </Text>
        </Flex>
        <Flex direction="column" style={{ background: '#FFFFFF', borderRadius: '15px' }} p={5}>
          <Text fontSize={18} fontWeight={800}>
            Recursos
          </Text>
        </Flex>
        <Flex direction="column" style={{ background: '#FFFFFF', borderRadius: '15px' }} p={5}>
          <Text fontSize={18} fontWeight={800}>
            Adoção de família
          </Text>
        </Flex>
        <Flex direction="column" style={{ background: '#FFFFFF', borderRadius: '15px' }} p={5}>
          <Text fontSize={18} fontWeight={800}>
            Recolocação
          </Text>
        </Flex>
      </SimpleGrid>
    </Box>
  )
}
