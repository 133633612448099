/* eslint-disable */
import {
  Avatar,
  Center,
  Flex,
  Link,
  Icon,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Tag,
  Button
} from '@chakra-ui/react'
import { MdAddBusiness, MdImportContacts, MdImportExport, MdPerson, MdPersonAdd } from 'react-icons/md'

// Custom components
import Card from 'components/card/Card'
// import Menu from 'components/menu/MainMenu'
import React, { useMemo, useState } from 'react'
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import { SearchBar } from 'components/navbar/searchBar/SearchBar'
import { FaEnvelope, FaPlus, FaWhatsapp } from 'react-icons/fa'
import { formatPhoneNumber } from 'hooks/formatPhoneNumber'
import { getUserAuthenticationRole } from 'hooks/authService'
const userRole = getUserAuthenticationRole()

export default function Clients(props) {
  const { columnsData, tableData } = props

  const [animalAge, setAnimalAge] = useState('')
  const [status, setStatus] = useState('')
  const [breed, setBreed] = useState('')
  const [specie, setSpecie] = useState('')

  const handleChangeAnimalAge = (event) => {
    setAnimalAge(event.value)
  }
  const handleChangeStatus = (event) => {
    setStatus(event.value)
  }
  const handleChangeSpecie = (event) => {
    setSpecie(event.value)
  }
  const handleChangeBreed = (event) => {
    setBreed(event.value)
  }

  const columns = useMemo(() => columnsData, [columnsData])
  const data = useMemo(() => tableData, [tableData])

  const tableInstance = useTable(
    {
      columns,
      data
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    state: { pageIndex, pageSize },
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage
  } = tableInstance
  initialState.pageSize = 10

  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const iconColor = useColorModeValue('secondaryGray.500', 'white')
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100')
  return (
    <Card direction="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          Clientes
        </Text>
        <SearchBar />
        {/* <Flex alignItems="center">
          <Text mr="10px">Filtros</Text>
          <Select
            value={status}
            onChange={handleChangeStatus}
            variant="auth"
            fontSize="sm"
            placeholder="Status"
            fontWeight="500"
            size="md"
            borderRadius="16px"
            mr="10px"
          >
            <option>Ativo</option>
            <option>Estagnado</option>
            <option>Inativo</option>
          </Select>
          <Select
            value={animalAge}
            onChange={handleChangeAnimalAge}
            variant="auth"
            fontSize="sm"
            placeholder="Idade"
            fontWeight="500"
            size="md"
            borderRadius="16px"
            mr="10px"
          >
            <option>Adulto</option>
            <option>Idoso</option>
            <option>Filhote 1</option>
            <option>Filhote 2</option>
          </Select>
          <Select
            value={specie}
            onChange={handleChangeSpecie}
            variant="auth"
            fontSize="sm"
            placeholder="Espécie"
            fontWeight="500"
            size="md"
            borderRadius="16px"
            mr="10px"
          >
            <option>Canino</option>
            <option>Felino</option>
            <option>Equino</option>
          </Select>
          <Select
            value={breed}
            onChange={handleChangeBreed}
            variant="auth"
            fontSize="sm"
            placeholder="Raça"
            fontWeight="500"
            size="md"
            borderRadius="16px"
            mr="10px"
          >
            <option>Rotweiller</option>
            <option>Poodle</option>
            <option>Dog Alemão</option>
            <option>Pastor Alemão</option>
          </Select>
        </Flex> */}
        <Flex gap={5} alignItems="center">
          <Link href="/admin/new-client">
            <Button
              leftIcon={<MdPersonAdd fontWeight={'500'} size={'18px'} />}
              colorScheme="gray"
              color="gray.400"
              variant="solid"
              fontWeight={'500'}
              borderRadius={'50px'}
              size="md"
            >
              Novo
            </Button>
          </Link>
          <Link href="/admin/import-clients">
            <Button
              leftIcon={<MdImportExport fontWeight={'500'} size={'18px'} />}
              colorScheme="gray"
              color="gray.400"
              variant="solid"
              fontWeight={'500'}
              borderRadius={'50px'}
              size="md"
            >
              Importar
            </Button>
          </Link>
        </Flex>
        {/* <Menu /> */}
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color="gray.400"
                  >
                    {column.render('Header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row)
            // console.log('row', row)
            return (
              <Tr {...row.getRowProps()} key={index}>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Link href={`/admin/client/${row.original.id}`}>
                    <Flex alignItems="center">
                      <Avatar size="md" name="" src={`clients/${row.original.logo}`} mr="10px" />
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {`${row.original.first_name} ${row.original.last_name}`}
                      </Text>
                    </Flex>
                  </Link>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Flex align="center" wrap={'wrap'} gap={'10px'}>
                    {row.original.whatsapp && (
                      <Button
                        leftIcon={<FaWhatsapp size={'18px'} />}
                        colorScheme="whatsapp"
                        fontSize="12px"
                        fontWeight="400"
                        borderRadius={'20px'}
                        p={'5px 10px'}
                        height={'fit-content'}
                      >
                        {formatPhoneNumber(row.original.whatsapp)}
                      </Button>
                    )}
                    {row.original.email && (
                      <Button
                        leftIcon={<FaEnvelope size={'18px'} />}
                        colorScheme="blackAlpha"
                        fontSize="12px"
                        fontWeight="400"
                        borderRadius={'20px'}
                        p={'5px 10px'}
                        height={'fit-content'}
                      >
                        {row.original.email}
                      </Button>
                    )}
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Flex gap={'10px'} wrap={'wrap'}>
                    {row.original?.animals &&
                      row.original.animals.map((animal) => (
                        <Tag
                          colorScheme="blackAlpha"
                          key={animal.name}
                          borderRadius={'20px'}
                          fontSize={'12px'}
                        >
                          {animal.name}
                        </Tag>
                      ))}
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Tag colorScheme="blackAlpha" borderRadius={'20px'} fontSize={'12px'}>
                    {row.original.last_consultations} 12/ago
                  </Tag>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Tag colorScheme="green" borderRadius={'20px'} fontSize={'12px'}>
                    {row.original.status}Ativo
                  </Tag>
                </Td>
                {userRole === 'admin' && (
                  <>
                    <Td
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      borderColor="transparent"
                    >
                      <Text color={textColor} fontSize="sm" fontWeight="300">
                        <Link href={`/admin/user/${row.original.id}`}>
                          <span
                            style={{
                              padding: '2px 10px',
                              borderRadius: '50px',
                              background: '#eaeaea',
                              fontSize: '12px',
                              color: '#8f9bba'
                            }}
                          >
                            {row.original.role}
                          </span>
                        </Link>
                      </Text>
                    </Td>
                    <Td
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      borderColor="transparent"
                    >
                      <Text color={textColor} fontSize="sm" fontWeight="300">
                        <Link href={`/admin/user/${row.original.id}`}>
                          <span
                            style={{
                              padding: '2px 10px',
                              borderRadius: '50px',
                              background: '#eaeaea',
                              fontSize: '12px',
                              color: '#8f9bba'
                            }}
                          >
                            Editar
                          </span>
                        </Link>
                      </Text>
                    </Td>
                  </>
                )}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      <Flex style={{ justifyContent: 'space-evenly', alignItems: 'center' }}>
        <Button onClick={() => previousPage()} disabled={!canPreviousPage} size="sm">
          Anterior
        </Button>
        <Text fontSize="sm">
          Página{' '}
          <strong>
            {pageIndex + 1} de {Math.ceil(data.length / pageSize)}
          </strong>
        </Text>
        <Button onClick={() => nextPage()} disabled={!canNextPage} size="sm">
          Próxima
        </Button>
      </Flex>
    </Card>
  )
}
