import React, { useMemo } from 'react'
import {
  Avatar,
  Center,
  Flex,
  Link,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from '@chakra-ui/react'
import { MdEditCalendar, MdDoneAll, MdDone, MdChat } from 'react-icons/md'

import { getUserAuthenticationRole } from 'hooks/authService'

// Custom components
import Card from 'components/card/Card'
// import Menu from 'components/menu/MainMenu'
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
const userRole = getUserAuthenticationRole()

const tableData = [
  {
    Tutor: 'Clenilson S.',
    Animal: 'Pooboo - Rotweiller, 3a',
    Abordagem: 'Verificação de peso e crescimento',
    Data: '07-06-2024',
    Status: 'Lida'
  },
  {
    Tutor: 'Giovana M.',
    Animal: 'Martina - Dog Alemão, 2a',
    Abordagem: 'Verificação de peso e crescimento',
    Data: '07-06-2024',
    Status: 'Lida'
  }
]

const columnsData = [
  { Header: 'Tutor', accessor: 'Tutor' },
  { Header: 'Animal', accessor: 'Animal' },
  { Header: 'Abordagem', accessor: 'Abordagem' },
  { Header: 'Data', accessor: 'Data' }
  // { Header: 'Status', accessor: 'Status' }
]

export default function LastActivities() {
  const columns = useMemo(() => columnsData, [columnsData])
  const data = useMemo(() => tableData, [tableData])

  const tableInstance = useTable(
    {
      columns,
      data
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, initialState } = tableInstance
  initialState.pageSize = 150

  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const iconColor = useColorModeValue('secondaryGray.500', 'white')
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100')
  return (
    <Card direction="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          Últimas Abordagens
        </Text>
        <Flex gap={5} alignItems="center">
          <Link href="/admin/new-client">
            <Flex flexDirection="rows" alignItems="center" gap={2}>
              <Icon as={MdEditCalendar} color={iconColor} w="26px" h="26px" />
              <Text px="5px" fontSize="sm">
                Adicionar Rotina
              </Text>
            </Flex>
          </Link>
        </Flex>
        {/* <Menu /> */}
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  px="20px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color="gray.400"
                  >
                    {column.render('Header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row)
            // console.log('row', row)
            return (
              <Tr {...row.getRowProps()} key={index}>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                  p="12px 12px 12px 24px"
                >
                  <Link href={`/admin/client/${row.original.id}`}>
                    <Flex alignItems="center">
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {`${row.original.Tutor}`}
                      </Text>
                    </Flex>
                  </Link>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                  p="0 12px 0 12px"
                >
                  <Flex align="center">
                    <Text me="10px" color={textColor} fontSize="sm" fontWeight="400">
                      {row.original.Animal}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '100px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                  p="0 12px 0 12px"
                >
                  <Flex>
                    <Icon as={MdDoneAll} color={'green'} w="18px" h="18px" mr="5px" />
                    <Text color={textColor} fontSize="sm" fontWeight="400" alignItems="center">
                      {row.original.Abordagem}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '100px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                  p="0 12px 0 12px"
                >
                  <Text color={textColor} fontSize="sm" fontWeight="400">
                    {row.original.Data}
                  </Text>
                </Td>
                {/* <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '100px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                  p="12px 12px 12px 12px"
                >
                  <Text color={textColor} fontSize="sm" fontWeight="400">
                    {row.original.Status}
                  </Text>
                </Td> */}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Card>
  )
}
