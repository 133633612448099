import React, { useState, useEffect } from 'react'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Textarea,
  SimpleGrid,
  Select,
  Stack,
  useColorModeValue,
  Text,
  Grid,
  GridItem,
  InputRightElement
} from '@chakra-ui/react'
import InputMask from 'react-input-mask'
import Card from 'components/card/Card'
import { formatCNPJ } from 'views/admin/default/components/formatCNPJ'

// Atende à 3 circunstâncias:
// Se possui accountData, retorna os dados da respectiva conta
// Se não possui accountData, cria uma nova conta
// Se possui /account/ no url e é super-admin, mostra a respectiva conta

export default function AccountForm({ accountData, isNewAccount }) {
  const textColor = useColorModeValue('navy.700', 'white')
  console.log('accountData: ', accountData)

  const [formData, setFormData] = useState({
    name: accountData.name,
    business_data: {
      cnpj: accountData.business_data.cnpj,
      employees: accountData.business_data.employees
    },
    address: accountData.address,
    contact_data: {
      phones: [accountData.contact_data.phones],
      emails: [accountData.contact_data.emails],
      website: accountData.contact_data?.website || '',
      instagram: accountData.contact_data?.instagram || ''
    },
    profile: accountData.profile,
    status: accountData.status || 'new',
    main_user: {},
    company_logo_url: accountData?.company_logo_url || '',
    settings: {
      approach_min_interval: accountData.settings.approach_min_interval,
      max_daily_triggers: accountData.settings.max_daily_triggers,
      api_instance_name: accountData.settings.api_instance_name,
      api_instance_token: accountData.settings.api_instance_token
    }
  })

  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    status: 'new',
    role: 'main',
    user_data: {
      main_account_id: null,
      phone1: '',
      geo_data: {
        ip: '',
        isp: '',
        city: '',
        zipcode: '',
        latitude: '',
        longitude: '',
        time_zone: -3,
        state_code: '',
        calling_code: +55,
        current_time: ''
      }
    }
  })

  const [newInstanceData, setNewInstanceData] = useState({
    // instance
    instanceName: formData.settings.api_instance_name,
    // "token": "{{apikey}}", // (Optional)
    // number: formData.settings.api_instance_name, // (Optional)
    qrcode: true, // (Optional)
    integration: 'WHATSAPP-BAILEYS' // WHATSAPP-BAILEYS | WHATSAPP-BUSINESS | EVOLUTION (Default WHATSAPP-BAILEYS)
    // settings (Optional)
    // "rejectCall": false,
    // "msgCall": "",
    // "groupsIgnore": false,
    // "alwaysOnline": false,
    // "readMessages": false,
    // "readStatus": false,
    // "syncFullHistory": false,
    // // proxy (Optional)
    // "proxyHost": "",
    // "proxyPort": "",
    // "proxyProtocol": "",
    // "proxyUsername": "",
    // "proxyPassword": "",
    // webhook (Optional)
    // "webhook": {
    //     "url": "",
    //     "byEvents": false,
    //     "base64": true,
    //     "headers": {
    //         "autorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6Ik...",
    //         "Content-Type": "application/json"
    //     },
    //     "events": [
    //         "APPLICATION_STARTUP",
    //         "QRCODE_UPDATED",
    //         "MESSAGES_SET",
    //         "MESSAGES_UPSERT",
    //         "MESSAGES_UPDATE",
    //         "MESSAGES_DELETE",
    //         "SEND_MESSAGE",
    //         "CONTACTS_SET",
    //         "CONTACTS_UPSERT",
    //         "CONTACTS_UPDATE",
    //         "PRESENCE_UPDATE",
    //         "CHATS_SET",
    //         "CHATS_UPSERT",
    //         "CHATS_UPDATE",
    //         "CHATS_DELETE",
    //         "GROUPS_UPSERT",
    //         "GROUP_UPDATE",
    //         "GROUP_PARTICIPANTS_UPDATE",
    //         "CONNECTION_UPDATE",
    //         "LABELS_EDIT",
    //         "LABELS_ASSOCIATION",
    //         "CALL",
    //         "TYPEBOT_START",
    //         "TYPEBOT_CHANGE_STATUS"
    //     ]
    // },
    // // rabbitmq (Optional)
    // "rabbitmq": {
    //     "enabled": true,
    //     "events": [
    //         "APPLICATION_STARTUP",
    //         "QRCODE_UPDATED",
    //         "MESSAGES_SET",
    //         "MESSAGES_UPSERT",
    //         "MESSAGES_UPDATE",
    //         "MESSAGES_DELETE",
    //         "SEND_MESSAGE",
    //         "CONTACTS_SET",
    //         "CONTACTS_UPSERT",
    //         "CONTACTS_UPDATE",
    //         "PRESENCE_UPDATE",
    //         "CHATS_SET",
    //         "CHATS_UPSERT",
    //         "CHATS_UPDATE",
    //         "CHATS_DELETE",
    //         "GROUPS_UPSERT",
    //         "GROUP_UPDATE",
    //         "GROUP_PARTICIPANTS_UPDATE",
    //         "CONNECTION_UPDATE",
    //         "LABELS_EDIT",
    //         "LABELS_ASSOCIATION",
    //         "CALL",
    //         "TYPEBOT_START",
    //         "TYPEBOT_CHANGE_STATUS"
    //     ]
    // },
    // // sqs (Optional)
    // "sqs": {
    //     "enabled": true,
    //     "events": [
    //         "APPLICATION_STARTUP",
    //         "QRCODE_UPDATED",
    //         "MESSAGES_SET",
    //         "MESSAGES_UPSERT",
    //         "MESSAGES_UPDATE",
    //         "MESSAGES_DELETE",
    //         "SEND_MESSAGE",
    //         "CONTACTS_SET",
    //         "CONTACTS_UPSERT",
    //         "CONTACTS_UPDATE",
    //         "PRESENCE_UPDATE",
    //         "CHATS_SET",
    //         "CHATS_UPSERT",
    //         "CHATS_UPDATE",
    //         "CHATS_DELETE",
    //         "GROUPS_UPSERT",
    //         "GROUP_UPDATE",
    //         "GROUP_PARTICIPANTS_UPDATE",
    //         "CONNECTION_UPDATE",
    //         "LABELS_EDIT",
    //         "LABELS_ASSOCIATION",
    //         "CALL",
    //         "TYPEBOT_START",
    //         "TYPEBOT_CHANGE_STATUS"
    //     ]
    // },
    // // chatwoot (Optional)
    // "chatwootAccountId": "1",
    // "chatwootToken": "TOKEN",
    // "chatwootUrl": "https://chatoot.com",
    // "chatwootSignMsg": true,
    // "chatwootReopenConversation": true,
    // "chatwootConversationPending": false,
    // "chatwootImportContacts": true,
    // "chatwootNameInbox": "evolution",
    // "chatwootMergeBrazilContacts": true,
    // "chatwootImportMessages": true,
    // "chatwootDaysLimitImportMessages": 3,
    // "chatwootOrganization": "Evolution Bot",
    // "chatwootLogo": "https://evolution-api.com/files/evolution-api-favicon.png",
  })

  const [connectionState, setConnectionState] = useState()
  const [connectionCodes, setConnectionCodes] = useState({})

  useEffect(() => {
    getWhatsAppStatus()
  }, [formData])

  useEffect(() => {
    console.log(' connectionCodes', connectionCodes)
  }, [connectionCodes])

  async function getWhatsAppStatus() {
    if (!formData.settings.api_instance_name && !formData.settings.api_instance_token)
      return setConnectionState('Conectar')

    try {
      const response = await axios.get(
        `https://wapi.vetsales.com.br/instance/connectionState/${formData.settings.api_instance_name}`,
        {
          headers: {
            'Content-Type': 'application/json',
            apikey: formData.settings.api_instance_token
          }
        }
      )
      console.log('getWhatsAppStatus:', response.data.instance.state)
      return setConnectionState(response.data.instance.state)
    } catch (error) {
      console.log('Erro', error)
    }
  }

  function ImageFromBase64(img) {
    // Verifica se a string base64 é válida e cria a URL do src da imagem
    if (img) return <img src={img} alt="Imagem vinda do servidor" />
  }

  const setWhatsAPIConnection = async () => {
    console.log('formData.settings.api_instance_token', formData.settings.api_instance_token)
    if (connectionState !== 'open' && formData.settings.api_instance_token) {
      try {
        const response = await axios.get(
          `https://wapi.vetsales.com.br/instance/connect/${formData.settings.api_instance_name}?number=${formData.settings.api_instance_name}`,
          {
            headers: {
              'Content-Type': 'application/json',
              apikey: formData.settings.api_instance_token
            }
          }
        )
        console.log('QRCODE Response', response.data.base64)
        return setConnectionCodes(response.data)
      } catch (error) {
        return console.log('error ', error)
      }
    }

    if (connectionState === 'Conectar' && formData.settings.api_instance_token === '') {
      try {
        // const response = await axios.post(`https://wapi.conta.com.vc/instance/create`, newInstanceData, {
        const response = await axios.post(`https://wapi.vetsales.com.br/instance/create`, newInstanceData, {
          headers: {
            'Content-Type': 'application/json',
            // apikey: '429683C43434534f3E10F7D57E11'
            apikey: 'wtwHLYfFxI9n1zDadvafLpcjVmXBm'
          }
        })
        if (response.status === 201) {
          setFormData((prevState) => ({
            ...prevState,
            settings: {
              ...prevState.settings,
              api_instance_token: response.data.hash
            }
          }))
          setConnectionCodes(response.data.qrcode)
        }
        return console.log('QRCODE Response', response)
      } catch (error) {
        return console.log('error ', error)
      }
    }
  }

  // fetch('https://{server-url}/instance/create', options)
  //   .then((response) => response.json())
  //   .then((response) => console.log(response))
  //   .catch((err) => console.error(err))

  useEffect(() => {
    const correctedAccountData = {
      ...accountData,
      profile: Array.isArray(accountData && accountData.profile) ? accountData.profile : ['']
    }

    setFormData(correctedAccountData)
    setNewInstanceData((prevState) => ({
      ...prevState,
      instanceName: accountData?.settings?.api_instance_name
    }))
  }, [accountData])

  useEffect(() => {
    // console.log('formData:', formData)
    // console.log('newInstanceData:', newInstanceData)
    // console.log('connectionState:', connectionState)
  }, [formData, newInstanceData, connectionState])

  const [sendAlert, setSendAlert] = useState('')

  const addPhone = () => {
    setFormData((prevState) => ({
      ...prevState,
      contact_data: {
        ...prevState.contact_data,
        phones: [
          ...prevState.contact_data.phones,
          {
            ddd: '',
            number: '',
            type: ''
          }
        ]
      }
    }))
  }

  const addEmail = () => {
    setFormData((prevState) => ({
      ...prevState,
      contact_data: {
        ...prevState.contact_data,
        emails: [
          ...prevState.contact_data.emails,
          {
            address: '',
            type: ''
          }
        ]
      }
    }))
  }

  const handleCheckboxProfileChange = (event) => {
    const value = event.target.value
    setFormData((prevContactProfile) => {
      const newClientProfile = JSON.parse(JSON.stringify(prevContactProfile))

      if (event.target.checked) {
        newClientProfile.profile.push(value)
      } else {
        newClientProfile.profile = newClientProfile.profile.filter((item) => item !== value)
      }

      return newClientProfile
    })
  }

  const handleSettingsChange = (event) => {
    const { name, value } = event.target

    setFormData((prevState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        [name]: value
      }
    }))
    if (name === 'api_instance_name') {
      setNewInstanceData((prevState) => ({
        ...prevState,
        instanceName: value,
        number: value
      }))
    }
  }

  const handleUserChange = (event) => {
    const { name, value } = event.target

    setUserData((prevState) => ({
      ...prevState,
      // user_data: {
      //   ...prevState.user_data,
      [name]: value
      // }
    }))
  }

  const handleChange = (event) => {
    const { name, value } = event.target

    setFormData((prevState) => {
      const updatedFormData = { ...prevState }
      const path = name.split('.')
      let nestedObj = updatedFormData

      path.forEach((key, index) => {
        if (key.includes('[')) {
          const [arrayKey, arrayIndex] = key.split(/\[|\]/).filter(Boolean)
          if (!nestedObj[arrayKey]) nestedObj[arrayKey] = []
          if (index === path.length - 1) {
            nestedObj[arrayKey][arrayIndex] = value
          } else {
            nestedObj = nestedObj[arrayKey][arrayIndex] = {
              ...nestedObj[arrayKey][arrayIndex]
            }
          }
        } else {
          if (index === path.length - 1) {
            nestedObj[key] = value
          } else {
            nestedObj = nestedObj[key] = { ...nestedObj[key] }
          }
        }
      })

      return updatedFormData
    })
  }

  const handleContactDataChange = (event) => {
    const { name, value } = event.target

    setFormData((prevState) => ({
      ...prevState,
      contact_data: {
        ...prevState.contact_data,
        [name]: value
      }
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    // Clone and stringify necessary fields
    const payload = { ...formData }
    payload.business_data = JSON.stringify(formData.business_data)
    payload.address = JSON.stringify(formData.address)
    payload.contact_data = JSON.stringify(formData.contact_data)
    payload.profile = JSON.stringify(formData.profile)
    payload.main_user = JSON.stringify(formData.main_user)
    payload.status = 'active'

    if (accountData?.id) {
      try {
        const response = await axios.patch(`/accounts/${accountData.id}`, payload, {
          headers: authHeader()
        })
        console.log('Dados atualizados com sucesso!', response.data)
        setSendAlert('success')
        setTimeout(() => {
          setSendAlert('')
        }, 1500)
        isNewAccount &&
          setTimeout(() => {
            window.location.href = '/admin/painel'
          }, 600)
      } catch (error) {
        setSendAlert('error')
        console.error('Ocorreu um erro ao atualizar os dados:', error)
      }
    } else {
      try {
        const response = await axios.post('/accounts', payload, {
          headers: authHeader()
        })
        console.log('Conta cadastrada com sucesso!', response.data)
        setSendAlert('success')
        setTimeout(() => {
          window.location.href = '/admin/painel'
        }, 600)
      } catch (error) {
        console.error('Ocorreu um erro ao cadastrar a conta:', error)
        setSendAlert('error')
      }
    }
    console.log('Dados enviados: ', payload)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid templateColumns="repeat(3, 1fr)" gap={'20px'} w={'full'}>
        <GridItem colSpan={3}>
          <Flex
            justifyContent={'space-between'}
            alignItems={'center'}
            borderRadius="50px"
            bg={'white'}
            p="10px 10px 10px 20px"
          >
            <Text display="flex" ms="4px" fontSize="xl" fontWeight="800" color={textColor}>
              Configurações da Conta
            </Text>
            <Button
              type="submit"
              fontSize="sm"
              background="#49BFFF"
              color="white"
              fontWeight="800"
              w="fit-content"
              h="40px"
              borderRadius="50px"
            >
              {/* {client_id ? 'Atualizar dados' : 'Confirmar'} */}
              Salvar
            </Button>
          </Flex>
        </GridItem>
        {sendAlert === 'success' && (
          <GridItem colSpan={3}>
            <Alert status="success">
              <AlertIcon />
              <AlertTitle>Sucesso!</AlertTitle>
              <AlertDescription>Dados enviados com sucesso!</AlertDescription>
            </Alert>
          </GridItem>
        )}
        {isNewAccount && (
          <GridItem colSpan={3}>
            <Card>
              <Text display="flex" ms="4px" mb="20px" fontSize="xl" fontWeight="800" color={textColor}>
                Administrador da conta
              </Text>
              <Flex gap={'20px'}>
                <Flex direction="column">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Nome e sobrenome
                  </FormLabel>
                  <InputGroup>
                    <Input
                      value={userData && userData.first_name}
                      name="first_name"
                      onChange={handleUserChange}
                      isRequired={true}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="Nome"
                      fontWeight="500"
                      size="md"
                      borderRadius="16px 0px 0px 16px"
                    />
                    <Input
                      value={userData && userData.last_name}
                      name="last_name"
                      onChange={handleUserChange}
                      isRequired={true}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="Sobrenome"
                      fontWeight="500"
                      size="md"
                      borderRadius="0px 16px 16px 0px "
                    />
                  </InputGroup>
                </Flex>
                <Flex direction="column">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Email
                  </FormLabel>
                  <Input
                    value={userData && userData.email}
                    name="email"
                    onChange={handleUserChange}
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    placeholder="email"
                    fontWeight="500"
                    size="md"
                  />
                </Flex>
                <Flex direction="column">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Senha
                  </FormLabel>
                  <Input
                    value={userData && userData.password}
                    name="password"
                    onChange={handleUserChange}
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    placeholder="Senha"
                    fontWeight="500"
                    size="md"
                  />
                </Flex>
                <Flex direction="column">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Perfil de usuário
                  </FormLabel>
                  <Select
                    value={userData.role}
                    name={`role`}
                    onChange={handleUserChange}
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    placeholder="Selecione"
                    fontWeight="500"
                    size="md"
                    w="200px"
                    borderRadius="16px"
                  >
                    <option name="main" value="main">
                      Administrador
                    </option>
                    <option name="worker" value="worker">
                      Colaborador
                    </option>
                  </Select>
                </Flex>
                {/* <Button>Salvar</Button> */}
              </Flex>
            </Card>
          </GridItem>
        )}
        <GridItem colSpan={1}>
          <Card>
            <FormControl>
              <Text display="flex" ms="4px" mb="20px" fontSize="xl" fontWeight="800" color={textColor}>
                Dados da Clínica
              </Text>

              <Flex direction="column">
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Nome da Clínica
                </FormLabel>
                <Input
                  value={formData && formData.name}
                  name="name"
                  onChange={handleChange}
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="Nome da unidade"
                  mb="24px"
                  fontWeight="500"
                  size="md"
                />
              </Flex>
              <Flex direction="column">
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  CNPJ
                </FormLabel>
                <Input
                  value={
                    formData &&
                    formData.business_data &&
                    formData.business_data.cnpj &&
                    formatCNPJ(formData.business_data.cnpj)
                  }
                  name="business_data.cnpj"
                  onChange={handleChange}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="CNPJ da clínica"
                  mb="24px"
                  fontWeight="500"
                  size="md"
                />
              </Flex>
              <Flex direction="column">
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Website
                </FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" minW="70px" fontSize="sm">
                    https://
                  </InputLeftElement>
                  <Input
                    value={formData && formData.contact_data && formData.contact_data.website}
                    name="website"
                    onChange={handleContactDataChange}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    placeholder="www.seusite.com.br"
                    pl="57px"
                    mb="24px"
                    fontWeight="500"
                    size="md"
                  />
                </InputGroup>
              </Flex>
              <Flex direction="column">
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Instagram
                </FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" w="50px">
                    @
                  </InputLeftElement>
                  <Input
                    value={formData && formData.contact_data && formData.contact_data.instagram}
                    name="instagram"
                    onChange={handleContactDataChange}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    placeholder="Informe a conta"
                    mb="24px"
                    fontWeight="500"
                    size="md"
                  />
                </InputGroup>
              </Flex>

              <Flex direction="column">
                {formData &&
                  formData.contact_data &&
                  formData.contact_data.phones &&
                  formData.contact_data.phones.map((phone, index) => (
                    <>
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        mb="8px"
                      >
                        Telefone(s)
                      </FormLabel>
                      <Flex direction="column" key={index}>
                        <InputGroup>
                          {/* <InputLeftElement pointerEvents="none" w="50px">
                        oi
                      </InputLeftElement> */}
                          <Select
                            value={phone.type}
                            name={`contact_data.phones[${index}].type`}
                            onChange={handleChange}
                            isRequired={phone.number ? true : false}
                            variant="auth"
                            fontSize="sm"
                            ms={{ base: '0px', md: '0px' }}
                            placeholder="Selecione"
                            mb="24px"
                            fontWeight="500"
                            size="md"
                            w="200px"
                            borderRadius="16px 0px 0px 16px"
                          >
                            <option key="WhatsApp" value="WhatsApp">
                              WhatsApp
                            </option>
                            <option key="Número Fixo" value="Número Fixo">
                              Fixo
                            </option>
                          </Select>
                          <Input
                            as={InputMask}
                            mask="+** (**) *********"
                            maskChar={null}
                            value={phone.number}
                            name={`contact_data.phones[${index}].number`}
                            onChange={handleChange}
                            type="tel"
                            variant="auth"
                            fontSize="sm"
                            ms={{ base: '0px', md: '0px' }}
                            placeholder="Informe o telefone"
                            mb="24px"
                            fontWeight="500"
                            size="md"
                            style={{ borderRadius: '0 16px 16px 0' }}
                          />
                        </InputGroup>
                      </Flex>
                    </>
                  ))}
                <Button
                  onClick={addPhone}
                  style={{ fontSize: '12px', background: '#e8f0fe' }}
                  mt="-10px"
                  mb="24px"
                  padding="5px 30px"
                  height="30px"
                  borderRadius={50}
                >
                  Adicionar Telefone
                </Button>
              </Flex>
              <Flex direction="column">
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Email
                </FormLabel>
                {formData &&
                  formData.contact_data &&
                  formData.contact_data.emails &&
                  formData.contact_data.emails.map((email, index) => (
                    <Input
                      value={email.address}
                      name={`contact_data.emails[${index}].address`}
                      onChange={handleChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="email"
                      type="email"
                      mb="24px"
                      fontWeight="500"
                      size="md"
                    />
                  ))}
                <Button
                  onClick={addEmail}
                  style={{ fontSize: '12px', background: '#e8f0fe' }}
                  mt="-10px"
                  mb="24px"
                  padding="5px 30px"
                  height="30px"
                  borderRadius={50}
                >
                  Adicionar Email
                </Button>
              </Flex>
            </FormControl>
          </Card>
        </GridItem>
        <GridItem colSpan={1}>
          <Card>
            <Flex>
              <Text display="flex" ms="4px" mb="20px" fontSize="xl" fontWeight="800" color={textColor}>
                Perfil da clinica
              </Text>
            </Flex>
            <Flex direction="column">
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Número de Funcionários
              </FormLabel>
              <Select
                value={formData && formData.business_data && formData.business_data.employees}
                name="business_data.employees"
                onChange={handleChange}
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                placeholder="Selecione"
                mb="24px"
                fontWeight="500"
                size="md"
              >
                <option key="1-5" velue="1-5">
                  1 a 5 funcionários
                </option>
                <option key="6-10" velue="6-10">
                  6 a 10 funcionários
                </option>
                <option key="11-20" velue="11-20">
                  11 a 20 funcionários
                </option>
                <option key="21-30" velue="21-30">
                  21 a 30 funcionários
                </option>
                <option key="31-50" velue="31-50">
                  31 a 50 funcionários
                </option>
                <option key="+50" velue="+50">
                  mais de 50 funcionários
                </option>
              </Select>
            </Flex>
            <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
              Serviços prestados
            </FormLabel>
            <Stack
              spacing={1}
              style={{ display: 'grid', gap: '10px' }}
              gridTemplateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
              mb="8px"
              px="5px"
            >
              {[
                '24 horas',
                'Ambulatório',
                'Banho e tosa',
                'Centro Cirúrgico',
                'Clínica de Fisioterapia',
                'Clínica de Reabilitação',
                'Consultório',
                'Crematório de Animais',
                'Especialidades',
                'Farmácia Veterinária',
                'Hospital Veterinário',
                'Hotel para Animais',
                'Laboratório',
                'Petshop'
              ].map((value) => (
                <Checkbox
                  key={value}
                  value={value}
                  isChecked={formData.profile && formData.profile.includes(value)}
                  onChange={handleCheckboxProfileChange}
                >
                  {value}
                </Checkbox>
              ))}
            </Stack>
          </Card>
        </GridItem>
        <GridItem colSpan={1}>
          <Card>
            <Flex flexDirection={'column'}>
              <Text display="flex" ms="4px" mb="20px" fontSize="xl" fontWeight="800" color={textColor}>
                Configurações
              </Text>
              <Flex direction="column">
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Intervalo entre abordagens por cliente
                </FormLabel>
                <Input
                  value={formData && formData.settings && formData.settings.approach_min_interval}
                  name="approach_min_interval"
                  onChange={handleSettingsChange}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder=""
                  mb="24px"
                  fontWeight="500"
                  size="md"
                />
              </Flex>
              <Flex direction="column">
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Limite máximo de abordagens por dia
                </FormLabel>
                <Input
                  value={formData && formData.settings && formData.settings.max_daily_triggers}
                  name="max_daily_triggers"
                  onChange={handleSettingsChange}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="CNPJ da clínica"
                  mb="24px"
                  fontWeight="500"
                  size="md"
                />
              </Flex>
              <Flex direction="column">
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Número do Whatsapp
                </FormLabel>
                <InputGroup>
                  <Input
                    value={formData && formData.settings && formData.settings.api_instance_name}
                    name="api_instance_name"
                    onChange={handleSettingsChange}
                    onBlur={(e) =>
                      setNewInstanceData((prevState) => ({ ...prevState, instanceName: e.target.value }))
                    }
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    placeholder="Exemplo: 551151283600"
                    mb="24px"
                    fontWeight="500"
                    size="md"
                  />
                  <InputRightElement width="7rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      colorScheme={connectionState === 'open' ? 'green' : 'blue'}
                      onClick={setWhatsAPIConnection}
                    >
                      {connectionState === 'open' ? 'Conectado' : 'Conectar'}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Flex>

              <Flex direction="column">
                <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                  Token API
                </FormLabel>
                <Input
                  value={formData && formData.settings && formData.settings.api_instance_token}
                  name="api_instance_token"
                  onChange={handleSettingsChange}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  placeholder="..."
                  mb="24px"
                  fontWeight="500"
                  size="md"
                />
              </Flex>
              {connectionCodes.base64 && <img src={connectionCodes.base64} />}

              {connectionCodes.pairingCode && (
                <Flex flexDirection={'column'} alignItems={'center'} mb="24px">
                  <Text>Código de pareamento</Text>
                  <Text fontSize={'22px'} fontWeight={'800'} letterSpacing={'1px'}>
                    {connectionCodes.pairingCode}
                  </Text>
                </Flex>
              )}
            </Flex>
          </Card>
        </GridItem>
      </Grid>
    </form>
  )
}
