import React from 'react'
import Card from 'components/card/Card'
import { MdAddBusiness, MdImportContacts, MdImportExport, MdPerson, MdPersonAdd } from 'react-icons/md'
import { Avatar, Box, Flex, Link, Icon, Text, useColorModeValue, SimpleGrid } from '@chakra-ui/react'
import AccountsTable from './components/accountsTable'

const AccountsManagement = () => {
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <AccountsTable />
    </Box>
  )
}

export default AccountsManagement
