import React from 'react'

// Chakra imports
import { Flex, Image, Link } from '@chakra-ui/react'

// Custom components
import { HSeparator } from 'components/separator/Separator'
import vetesales from 'assets/img/logo_VetSales.png'

export function SidebarBrand() {
  return (
    <Flex align="center" direction="column">
      <Link href="/">
        <Image w="260px" mt="0" mb="40px" px="40px" src={vetesales} />
      </Link>
      <HSeparator mb="20px" />
    </Flex>
  )
}

export default SidebarBrand
