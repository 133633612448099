import React, { useState, useEffect, createRef } from 'react'
// import { useParams } from 'react-router-dom';
import apiDados from '../../../api/api-dados'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Select,
  Stack,
  Switch,
  useColorModeValue,
  useToast,
  Text
} from '@chakra-ui/react'
import { PhoneIcon, SearchIcon } from '@chakra-ui/icons'
import Card from '../../../components/card/Card'
// import { NotAllowedIcon } from '@chakra-ui/icons';
// import formatDate from 'hooks/formatDate';
import TableConsulta from './components/tableConsulta'
import ModalConsultaCnpj from './components/modalProspeccao'

export default function Settings() {
  const textColor = useColorModeValue('navy.700', 'white')

  let capital_social_lte = null
  let capital_social_gte = null
  let data_abertura_lte = null
  let data_abertura_gte = null

  const [cnpj, setCnpj] = useState([])
  const [trigger, setTrigger] = useState(false)

  const [formData, setFormData] = useState({
    query: {
      atividade_principal: ['7500100'],
      bairro: [],
      cep: [],
      ddd: [],
      municipio: [],
      natureza_juridica: ['2062'],
      situacao_cadastral: 'ATIVA',
      termo: [],
      uf: []
    },
    page: 0,
    extras: {
      com_contato_telefonico: true,
      com_email: true,
      excluir_mei: true,
      incluir_atividade_secundaria: false,
      somente_celular: false,
      somente_filial: false,
      somente_fixo: false,
      somente_matriz: false,
      somente_mei: false
    },
    range_query: {
      capital_social: { lte: capital_social_lte, gte: capital_social_gte },
      data_abertura: { lte: data_abertura_lte, gte: data_abertura_gte }
    }
  })

  const [resultado, setResultado] = useState([])
  const [loading, setLoading] = useState(false)
  const [sendAlert, setSendAlert] = useState('')

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
    console.log(`Name: ${name}, value: ${value}`)
  }

  const handleQueryChange = (event) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      query: {
        ...prevFormData.query,
        [name]: [value]
      }
    }))
    console.log(`Query Name: ${name}, value: ${value}`)
    console.log(`formData.query: ${JSON.stringify(formData?.query)}`)
  }

  const handleCnpjConsultaChange = (event) => {
    const { value } = event.target
    setCnpj(value)
    console.log(`Query Name: ${event.name}, value: ${value}`)
  }

  const handleExtraChange = (event) => {
    const { name, checked } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      extras: {
        ...prevFormData.extras,
        [name]: checked
      }
    }))
    console.log(`Extra Name: ${name}, value: ${checked}`)
    console.log(`event.taget: ${JSON.stringify(checked)}`)
  }

  const handleLoadMore = async () => {
    setLoading(true)
    const { page: currentPage } = formData
    console.log('Página original:', currentPage)
    setFormData((prevState) => ({ ...prevState, page: currentPage + 1 }))
    console.log('Página nova:', formData?.page)

    try {
      const {
        data: {
          data: { cnpj }
        }
      } = await apiDados.post('/cnpj/search', formData)

      const cnpjsUnicos = new Set(resultado.map((item) => item.cnpj))
      const novosCnpjs = cnpj.filter((item) => !cnpjsUnicos.has(item.cnpj))
      setResultado((prevData) => [...prevData, ...novosCnpjs])

      console.log('Sucesso!', novosCnpjs)
      console.log('Novo formData:', formData)
    } catch (error) {
      setSendAlert('error')
      console.error('Erro ao carregar mais dados:', error.response)
    }
    setLoading(false)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const {
        data: {
          data,
          success,
          page,
          data: { cnpj }
        }
      } = await apiDados.post('/cnpj/search', formData)
      console.log('Sucesso!', cnpj)
      setResultado(cnpj)
      setSendAlert('success')
      console.log('Sucesso! Page:', page)
      console.log('Sucesso! Sucess: ', success)
      console.log('Sucesso! Data:', data)
      console.log('Sucesso! formData:', formData)
    } catch (error) {
      setSendAlert('error')
      console.error('Ocorreu um erro ao enviar os dados:', error.response)
    }
    // console.log(`Resultado: ${JSON.stringify(resultado)}`);
    // console.log(`formData: ${JSON.stringify(formData)}`);
  }

  const { termo, atividade_principal, natureza_juridica, situacao_cadastral, uf, municipio, cep } =
    formData?.query

  // console.log(`resultado ${JSON.stringify(resultado)}`);
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid mb="20px" columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
        <Card>
          <form onSubmit={handleSubmit}>
            <FormControl>
              <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={{ base: '20px', xl: '20px' }}>
                <Stack>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    mb="-2px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                  >
                    Atividade Principal CNAE
                  </FormLabel>
                  <Select
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    type="autor"
                    placeholder="Selecione"
                    mb="24px"
                    fontWeight="500"
                    size="md"
                    name="atividade_principal"
                    value={atividade_principal}
                    onChange={handleQueryChange}
                  >
                    <option value="0111301">0111301 - Cultivo de arroz</option>
                    <option value="0111302">0111302 - Cultivo de milho</option>
                    <option value="0111303">0111303 - Cultivo de trigo</option>
                    <option value="0111399">
                      0111399 - Cultivo de outros cereais nao especificados anteriormente
                    </option>
                    <option value="0112101">0112101 - Cultivo de algodao herbaceo</option>
                    <option value="0112102">0112102 - Cultivo de juta</option>
                    <option value="0112199">
                      0112199 - Cultivo de outras fibras de lavoura temporaria nao especificadas anteriormente
                    </option>
                    <option value="0113000">0113000 - Cultivo de cana-de-acucar</option>
                    <option value="0114800">0114800 - Cultivo de fumo</option>
                    <option value="0115600">0115600 - Cultivo de soja</option>
                    <option value="0116401">0116401 - Cultivo de amendoim</option>
                    <option value="0116402">0116402 - Cultivo de girassol</option>
                    <option value="0116403">0116403 - Cultivo de mamona</option>
                    <option value="0116499">
                      0116499 - Cultivo de outras oleaginosas de lavoura temporaria nao especificadas
                      anteriormente
                    </option>
                    <option value="0119901">0119901 - Cultivo de abacaxi</option>
                    <option value="0119902">0119902 - Cultivo de alho</option>
                    <option value="0119903">0119903 - Cultivo de batata-inglesa</option>
                    <option value="0119904">0119904 - Cultivo de cebola</option>
                    <option value="0119905">0119905 - Cultivo de feijao</option>
                    <option value="0119906">0119906 - Cultivo de mandioca</option>
                    <option value="0119907">0119907 - Cultivo de melao</option>
                    <option value="0119908">0119908 - Cultivo de melancia</option>
                    <option value="0119909">0119909 - Cultivo de tomate rasteiro</option>
                    <option value="0119999">
                      0119999 - Cultivo de outras plantas de lavoura temporaria nao especificadas
                      anteriormente
                    </option>
                    <option value="0121101">0121101 - horticultura, exceto morango</option>
                    <option value="0121102">0121102 - Cultivo de morango</option>
                    <option value="0122900">0122900 - Cultivo de flores e plantas ornamentais</option>
                    <option value="0131800">0131800 - Cultivo de laranja</option>
                    <option value="0132600">0132600 - Cultivo de uva</option>
                    <option value="0133401">0133401 - Cultivo de acai</option>
                    <option value="0133402">0133402 - Cultivo de banana</option>
                    <option value="0133403">0133403 - Cultivo de caju</option>
                    <option value="0133404">0133404 - Cultivo de citricos, exceto laranja</option>
                    <option value="0133405">0133405 - Cultivo de coco-da-baia</option>
                    <option value="0133406">0133406 - Cultivo de guarana</option>
                    <option value="0133407">0133407 - Cultivo de maca</option>
                    <option value="0133408">0133408 - Cultivo de mamao</option>
                    <option value="0133409">0133409 - Cultivo de maracuja</option>
                    <option value="0133410">0133410 - Cultivo de manga</option>
                    <option value="0133411">0133411 - Cultivo de pessego</option>
                    <option value="0133499">
                      0133499 - Cultivo de frutas de lavoura permanente nao especificadas anteriormente
                    </option>
                    <option value="0134200">0134200 - Cultivo de cafe</option>
                    <option value="0135100">0135100 - Cultivo de cacau</option>
                    <option value="0139301">0139301 - Cultivo de cha-da-india</option>
                    <option value="0139302">0139302 - Cultivo de erva-mate</option>
                    <option value="0139303">0139303 - Cultivo de pimenta-do-reino</option>
                    <option value="0139304">
                      0139304 - Cultivo de plantas para condimento, exceto pimenta-do-reino
                    </option>
                    <option value="0139305">0139305 - Cultivo de dende</option>
                    <option value="0139306">0139306 - Cultivo de seringueira</option>
                    <option value="0139399">
                      0139399 - Cultivo de outras plantas de lavoura permanente nao especificadas
                      anteriormente
                    </option>
                    <option value="0141501">
                      0141501 - Producao de sementes certificadas, exceto de forrageiras para pasto
                    </option>
                    <option value="0141502">
                      0141502 - Producao de sementes certificadas de forrageiras para formacao de pasto
                    </option>
                    <option value="0142300">
                      0142300 - Producao de mudas e outras formas de propagacao vegetal, certificadas
                    </option>
                    <option value="0151201">0151201 - criacao de bovinos para corte</option>
                    <option value="0151202">0151202 - criacao de bovinos para leite</option>
                    <option value="0151203">0151203 - criacao de bovinos, exceto para corte e leite</option>
                    <option value="0152101">0152101 - criacao de bufalinos</option>
                    <option value="0152102">0152102 - criacao de equinos</option>
                    <option value="0152103">0152103 - criacao de asininos e muares</option>
                    <option value="0153901">0153901 - criacao de caprinos</option>
                    <option value="0153902">
                      0153902 - criacao de ovinos, inclusive para Producao de la
                    </option>
                    <option value="0154700">0154700 - criacao de suinos</option>
                    <option value="0155501">0155501 - criacao de frangos para corte</option>
                    <option value="0155502">0155502 - Producao de pintos de um dia</option>
                    <option value="0155503">0155503 - criacao de outros galinaceos, exceto para corte</option>
                    <option value="0155504">0155504 - criacao de aves, exceto galinaceos</option>
                    <option value="0155505">0155505 - Producao de ovos</option>
                    <option value="0159801">0159801 - apicultura</option>
                    <option value="0159802">0159802 - criacao de animais de estimacao</option>
                    <option value="0159803">0159803 - criacao de escargo</option>
                    <option value="0159804">0159804 - criacao de bicho-da-seda</option>
                    <option value="0159899">
                      0159899 - criacao de outros animais nao especificados anteriormente
                    </option>
                    <option value="0161001">
                      0161001 - servico de pulverizacao e controle de pragas agricolas
                    </option>
                    <option value="0161002">0161002 - servico de poda de arvores para lavouras</option>
                    <option value="0161003">
                      0161003 - servico de Preparacao de terreno, Cultivo e colheita
                    </option>
                    <option value="0161099">
                      0161099 - Atividades de apoio a agricultura nao especificadas anteriormente
                    </option>
                    <option value="0162801">0162801 - servico de inseminacao artificial em animais</option>
                    <option value="0162802">0162802 - servico de tosquiamento de ovinos</option>
                    <option value="0162803">0162803 - servico de manejo de animais</option>
                    <option value="0162899">
                      0162899 - Atividades de apoio a pecuaria nao especificadas anteriormente
                    </option>
                    <option value="0163600">0163600 - Atividades de pos-colheita</option>
                    <option value="0170900">0170900 - caca e Servicos relacionados</option>
                    <option value="0210101">0210101 - Cultivo de eucalipto</option>
                    <option value="0210102">0210102 - Cultivo de acacia-negra</option>
                    <option value="0210103">0210103 - Cultivo de pinus</option>
                    <option value="0210104">0210104 - Cultivo de teca</option>
                    <option value="0210105">
                      0210105 - Cultivo de especies madeireiras, exceto eucalipto, acacia-negra, pinus e teca
                    </option>
                    <option value="0210106">0210106 - Cultivo de mudas em viveiros florestais</option>
                    <option value="0210107">0210107 - Extracao de madeira em florestas plantadas</option>
                    <option value="0210108">
                      0210108 - Producao de carvao vegetal - florestas plantadas
                    </option>
                    <option value="0210109">
                      0210109 - Producao de casca de acacia-negra - florestas plantadas
                    </option>
                    <option value="0210199">
                      0210199 - Producao de produtos nao-madeireiros nao especificados anteriormente em
                      florestas plantadas
                    </option>
                    <option value="0220901">0220901 - Extracao de madeira em florestas nativas</option>
                    <option value="0220902">0220902 - Producao de carvao vegetal - florestas nativas</option>
                    <option value="0220903">0220903 - coleta de castanha-do-para em florestas nativas</option>
                    <option value="0220904">0220904 - coleta de latex em florestas nativas</option>
                    <option value="0220905">0220905 - coleta de palmito em florestas nativas</option>
                    <option value="0220906">0220906 - conservacao de florestas nativas</option>
                    <option value="0220999">
                      0220999 - coleta de produtos nao-madeireiros nao especificados anteriormente em
                      florestas nativas
                    </option>
                    <option value="0230600">0230600 - Atividades de apoio a Producao florestal</option>
                    <option value="0311601">0311601 - pesca de peixes em agua salgada</option>
                    <option value="0311602">0311602 - pesca de crustaceos e moluscos em agua salgada</option>
                    <option value="0311603">0311603 - coleta de outros produtos marinhos</option>
                    <option value="0311604">0311604 - Atividades de apoio a pesca em agua salgada</option>
                    <option value="0312401">0312401 - pesca de peixes em agua doce</option>
                    <option value="0312402">0312402 - pesca de crustaceos e moluscos em agua doce</option>
                    <option value="0312403">
                      0312403 - coleta de outros produtos aquaticos de agua doce
                    </option>
                    <option value="0312404">0312404 - Atividades de apoio a pesca em agua doce</option>
                    <option value="0321301">0321301 - criacao de peixes em agua salgada e salobra</option>
                    <option value="0321302">0321302 - criacao de camaroes em agua salgada e salobra</option>
                    <option value="0321303">
                      0321303 - criacao de ostras e mexilhoes em agua salgada e salobra
                    </option>
                    <option value="0321304">
                      0321304 - criacao de peixes ornamentais em agua salgada e salobra
                    </option>
                    <option value="0321305">
                      0321305 - Atividades de apoio a aquicultura em agua salgada e salobra
                    </option>
                    <option value="0321399">
                      0321399 - Cultivos e semiCultivos da aquicultura em agua salgada e salobra nao
                      especificados anteriormente
                    </option>
                    <option value="0322101">0322101 - criacao de peixes em agua doce</option>
                    <option value="0322102">0322102 - criacao de camaroes em agua doce</option>
                    <option value="0322103">0322103 - criacao de ostras e mexilhoes em agua doce</option>
                    <option value="0322104">0322104 - criacao de peixes ornamentais em agua doce</option>
                    <option value="0322105">0322105 - ranicultura</option>
                    <option value="0322106">0322106 - criacao de jacare</option>
                    <option value="0322107">0322107 - Atividades de apoio a aquicultura em agua doce</option>
                    <option value="0322199">
                      0322199 - Cultivos e semiCultivos da aquicultura em agua doce nao especificados
                      anteriormente
                    </option>
                    <option value="0500301">0500301 - Extracao de carvao mineral</option>
                    <option value="0500302">0500302 - Beneficiamento de carvao mineral</option>
                    <option value="0600001">0600001 - Extracao de petroleo e gas natural</option>
                    <option value="0600002">0600002 - Extracao e Beneficiamento de xisto</option>
                    <option value="0600003">0600003 - Extracao e Beneficiamento de areias betuminosas</option>
                    <option value="0710301">0710301 - Extracao de minerio de ferro</option>
                    <option value="0710302">
                      0710302 - pelotizacao, sinterizacao e outros Beneficiamentos de minerio de ferro
                    </option>
                    <option value="0721901">0721901 - Extracao de minerio de aluminio</option>
                    <option value="0721902">0721902 - Beneficiamento de minerio de aluminio</option>
                    <option value="0722701">0722701 - Extracao de minerio de estanho</option>
                    <option value="0722702">0722702 - Beneficiamento de minerio de estanho</option>
                    <option value="0723501">0723501 - Extracao de minerio de manganes</option>
                    <option value="0723502">0723502 - Beneficiamento de minerio de manganes</option>
                    <option value="0724301">0724301 - Extracao de minerio de metais preciosos</option>
                    <option value="0724302">0724302 - Beneficiamento de minerio de metais preciosos</option>
                    <option value="0725100">0725100 - Extracao de minerais radioativos</option>
                    <option value="0729401">0729401 - Extracao de minerios de niobio e titanio</option>
                    <option value="0729402">0729402 - Extracao de minerio de tungstenio</option>
                    <option value="0729403">0729403 - Extracao de minerio de niquel</option>
                    <option value="0729404">
                      0729404 - Extracao de minerios de cobre, chumbo, zinco e outros minerais metalicos
                      nao-ferrosos nao especificados anteriormente
                    </option>
                    <option value="0729405">
                      0729405 - Beneficiamento de minerios de cobre, chumbo, zinco e outros minerais metalicos
                      nao-ferrosos nao especificados anteriormente
                    </option>
                    <option value="0810001">0810001 - Extracao de ardosia e Beneficiamento associado</option>
                    <option value="0810002">0810002 - Extracao de granito e Beneficiamento associado</option>
                    <option value="0810003">0810003 - Extracao de marmore e Beneficiamento associado</option>
                    <option value="0810004">
                      0810004 - Extracao de calcario e dolomita e Beneficiamento associado
                    </option>
                    <option value="0810005">0810005 - Extracao de gesso e caulim</option>
                    <option value="0810006">
                      0810006 - Extracao de areia, cascalho ou pedregulho e Beneficiamento associado
                    </option>
                    <option value="0810007">0810007 - Extracao de argila e Beneficiamento associado</option>
                    <option value="0810008">0810008 - Extracao de saibro e Beneficiamento associado</option>
                    <option value="0810009">0810009 - Extracao de basalto e Beneficiamento associado</option>
                    <option value="0810010">
                      0810010 - Beneficiamento de gesso e caulim associado a Extracao
                    </option>
                    <option value="0810099">
                      0810099 - Extracao e britamento de pedras e outros materiais para Construcao e
                      Beneficiamento associado
                    </option>
                    <option value="0891600">
                      0891600 - Extracao de minerais para Fabricacao de adubos, fertilizantes e outros
                      produtos quimicos
                    </option>
                    <option value="0892401">0892401 - Extracao de sal marinho</option>
                    <option value="0892402">0892402 - Extracao de sal-gema</option>
                    <option value="0892403">0892403 - Refino e outros tratamentos do sal</option>
                    <option value="0893200">
                      0893200 - Extracao de gemas (pedras preciosas e semipreciosas)
                    </option>
                    <option value="0899101">0899101 - Extracao de grafita</option>
                    <option value="0899102">0899102 - Extracao de quartzo</option>
                    <option value="0899103">0899103 - Extracao de amianto</option>
                    <option value="0899199">
                      0899199 - Extracao de outros minerais nao-metalicos nao especificados anteriormente
                    </option>
                    <option value="0910600">
                      0910600 - Atividades de apoio a Extracao de petroleo e gas natural
                    </option>
                    <option value="0990401">
                      0990401 - Atividades de apoio a Extracao de minerio de ferro
                    </option>
                    <option value="0990402">
                      0990402 - Atividades de apoio a Extracao de minerais metalicos nao-ferrosos
                    </option>
                    <option value="0990403">
                      0990403 - Atividades de apoio a Extracao de minerais nao-metalicos
                    </option>
                    <option value="1011201">1011201 - Frigorifico - abate de bovinos</option>
                    <option value="1011202">1011202 - Frigorifico - abate de equinos</option>
                    <option value="1011203">1011203 - Frigorifico - abate de ovinos e caprinos</option>
                    <option value="1011204">1011204 - Frigorifico - abate de bufalinos</option>
                    <option value="1011205">
                      1011205 - Matadouro - abate de reses sob contrato - exceto abate de suinos
                    </option>
                    <option value="1012101">1012101 - abate de aves</option>
                    <option value="1012102">1012102 - abate de pequenos animais</option>
                    <option value="1012103">1012103 - Frigorifico - abate de suinos</option>
                    <option value="1012104">1012104 - Matadouro - abate de suinos sob contrato</option>
                    <option value="1013901">1013901 - Fabricacao de produtos de carne</option>
                    <option value="1013902">1013902 - Preparacao de subprodutos do abate</option>
                    <option value="1020101">1020101 - preservacao de peixes, crustaceos e moluscos</option>
                    <option value="1020102">
                      1020102 - Fabricacao de conservas de peixes, crustaceos e moluscos
                    </option>
                    <option value="1031700">1031700 - Fabricacao de conservas de frutas</option>
                    <option value="1032501">1032501 - Fabricacao de conservas de palmito</option>
                    <option value="1032599">
                      1032599 - Fabricacao de conservas de legumes e outros vegetais, exceto palmito
                    </option>
                    <option value="1033301">
                      1033301 - Fabricacao de sucos concentrados de frutas, hortalicas e legumes
                    </option>
                    <option value="1033302">
                      1033302 - Fabricacao de sucos de frutas, hortalicas e legumes, exceto concentrados
                    </option>
                    <option value="1041400">
                      1041400 - Fabricacao de oleos vegetais em bruto, exceto oleo de milho
                    </option>
                    <option value="1042200">
                      1042200 - Fabricacao de oleos vegetais refinados, exceto oleo de milho
                    </option>
                    <option value="1043100">
                      1043100 - Fabricacao de margarina e outras gorduras vegetais e de oleos nao-comestiveis
                      de animais
                    </option>
                    <option value="1051100">1051100 - Preparacao do leite</option>
                    <option value="1052000">1052000 - Fabricacao de laticinios</option>
                    <option value="1053800">
                      1053800 - Fabricacao de sorvetes e outros gelados comestiveis
                    </option>
                    <option value="1061901">1061901 - Beneficiamento de arroz</option>
                    <option value="1061902">1061902 - Fabricacao de produtos do arroz</option>
                    <option value="1062700">1062700 - moagem de trigo e Fabricacao de derivados</option>
                    <option value="1063500">1063500 - Fabricacao de farinha de mandioca e derivados</option>
                    <option value="1064300">
                      1064300 - Fabricacao de farinha de milho e derivados, exceto oleos de milho
                    </option>
                    <option value="1065101">1065101 - Fabricacao de amidos e feculas de vegetais</option>
                    <option value="1065102">1065102 - Fabricacao de oleo de milho em bruto</option>
                    <option value="1065103">1065103 - Fabricacao de oleo de milho refinado</option>
                    <option value="1066000">1066000 - Fabricacao de alimentos para animais</option>
                    <option value="1069400">
                      1069400 - moagem e Fabricacao de produtos de origem vegetal nao especificados
                      anteriormente
                    </option>
                    <option value="1071600">1071600 - Fabricacao de acucar em bruto</option>
                    <option value="1072401">1072401 - Fabricacao de acucar de cana refinado</option>
                    <option value="1072402">
                      1072402 - Fabricacao de acucar de cereais (dextrose) e de beterraba
                    </option>
                    <option value="1081301">1081301 - Beneficiamento de cafe</option>
                    <option value="1081302">1081302 - Torrefacao e moagem de cafe</option>
                    <option value="1082100">1082100 - Fabricacao de produtos a base de cafe</option>
                    <option value="1091100">1091100 - Fabricacao de produtos de panificacao</option>
                    <option value="1091101">
                      1091101 - Fabricacao de produtos de panificacao industrial
                    </option>
                    <option value="1091102">
                      1091102 - Fabricacao de produtos de padaria e confeitaria com predominancia de Producao
                      propria
                    </option>
                    <option value="1092900">1092900 - Fabricacao de biscoitos e bolachas</option>
                    <option value="1093701">
                      1093701 - Fabricacao de produtos derivados do cacau e de chocolates
                    </option>
                    <option value="1093702">
                      1093702 - Fabricacao de frutas cristalizadas, balas e semelhantes
                    </option>
                    <option value="1094500">1094500 - Fabricacao de massas alimenticias</option>
                    <option value="1095300">
                      1095300 - Fabricacao de especiarias, molhos, temperos e condimentos
                    </option>
                    <option value="1096100">1096100 - Fabricacao de alimentos e pratos prontos</option>
                    <option value="1099601">1099601 - Fabricacao de vinagres</option>
                    <option value="1099602">1099602 - Fabricacao de pos alimenticios</option>
                    <option value="1099603">1099603 - Fabricacao de fermentos e leveduras</option>
                    <option value="1099604">1099604 - Fabricacao de gelo comum</option>
                    <option value="1099605">
                      1099605 - Fabricacao de produtos para infusao (cha, mate, etc.)
                    </option>
                    <option value="1099606">1099606 - Fabricacao de adocantes naturais e artificiais</option>
                    <option value="1099607">
                      1099607 - Fabricacao de alimentos dieteticos e complementos alimentares
                    </option>
                    <option value="1099699">
                      1099699 - Fabricacao de outros produtos alimenticios nao especificados anteriormente
                    </option>
                    <option value="1111901">1111901 - Fabricacao de aguardente de cana-de-acucar</option>
                    <option value="1111902">
                      1111902 - Fabricacao de outras aguardentes e bebidas destiladas
                    </option>
                    <option value="1112700">1112700 - Fabricacao de vinho</option>
                    <option value="1113501">1113501 - Fabricacao de malte, inclusive malte uisque</option>
                    <option value="1113502">1113502 - Fabricacao de cervejas e chopes</option>
                    <option value="1121600">1121600 - Fabricacao de aguas envasadas</option>
                    <option value="1122401">1122401 - Fabricacao de refrigerantes</option>
                    <option value="1122402">
                      1122402 - Fabricacao de cha mate e outros chas prontos para consumo
                    </option>
                    <option value="1122403">
                      1122403 - Fabricacao de refrescos, xaropes e pos para refrescos, exceto refrescos de
                      frutas
                    </option>
                    <option value="1122404">1122404 - Fabricacao de bebidas isotonicas</option>
                    <option value="1122499">
                      1122499 - Fabricacao de outras bebidas nao-alcoolicas nao especificadas anteriormente
                    </option>
                    <option value="1210700">1210700 - Processamento industrial do fumo</option>
                    <option value="1220401">1220401 - Fabricacao de cigarros</option>
                    <option value="1220402">1220402 - Fabricacao de cigarrilhas e charutos</option>
                    <option value="1220403">1220403 - Fabricacao de filtros para cigarros</option>
                    <option value="1220499">
                      1220499 - Fabricacao de outros produtos do fumo, exceto cigarros, cigarrilhas e charutos
                    </option>
                    <option value="1311100">1311100 - Preparacao e fiacao de fibras de algodao</option>
                    <option value="1312000">
                      1312000 - Preparacao e fiacao de fibras texteis naturais, exceto algodao
                    </option>
                    <option value="1313800">1313800 - fiacao de fibras artificiais e sinteticas</option>
                    <option value="1314600">1314600 - Fabricacao de linhas para costurar e bordar</option>
                    <option value="1321900">1321900 - tecelagem de fios de algodao</option>
                    <option value="1322700">
                      1322700 - tecelagem de fios de fibras texteis naturais, exceto algodao
                    </option>
                    <option value="1323500">
                      1323500 - tecelagem de fios de fibras artificiais e sinteticas
                    </option>
                    <option value="1330800">1330800 - Fabricacao de tecidos de malha</option>
                    <option value="1340501">
                      1340501 - estamparia e texturizacao em fios, tecidos, artefatos texteis e pecas do
                      vestuario
                    </option>
                    <option value="1340502">
                      1340502 - alvejamento, tingimento e torcao em fios, tecidos, artefatos texteis e pecas
                      do vestuario
                    </option>
                    <option value="1340599">
                      1340599 - outros Servicos de acabamento em fios, tecidos, artefatos texteis e pecas do
                      vestuario
                    </option>
                    <option value="1351100">
                      1351100 - Fabricacao de artefatos texteis para uso domestico
                    </option>
                    <option value="1352900">1352900 - Fabricacao de artefatos de tapecaria</option>
                    <option value="1353700">1353700 - Fabricacao de artefatos de cordoaria</option>
                    <option value="1354500">
                      1354500 - Fabricacao de tecidos especiais, inclusive artefatos
                    </option>
                    <option value="1359600">
                      1359600 - Fabricacao de outros produtos texteis nao especificados anteriormente
                    </option>
                    <option value="1411801">1411801 - confeccao de roupas intimas</option>
                    <option value="1411802">1411802 - faccao de roupas intimas</option>
                    <option value="1412601">
                      1412601 - confeccao de pecas de vestuario, exceto roupas intimas e as confeccionadas sob
                      medida
                    </option>
                    <option value="1412602">
                      1412602 - confeccao, sob medida, de pecas do vestuario, exceto roupas intimas
                    </option>
                    <option value="1412603">
                      1412603 - faccao de pecas do vestuario, exceto roupas intimas
                    </option>
                    <option value="1413401">
                      1413401 - confeccao de roupas profissionais, exceto sob medida
                    </option>
                    <option value="1413402">1413402 - confeccao, sob medida, de roupas profissionais</option>
                    <option value="1413403">1413403 - faccao de roupas profissionais</option>
                    <option value="1414200">
                      1414200 - Fabricacao de acessorios do vestuario, exceto para seguranca e protecao
                    </option>
                    <option value="1421500">1421500 - Fabricacao de meias</option>
                    <option value="1422300">
                      1422300 - Fabricacao de artigos do vestuario, produzidos em malharias e tricotagens,
                      exceto meias
                    </option>
                    <option value="1510600">1510600 - curtimento e outras preparacoes de couro</option>
                    <option value="1521100">
                      1521100 - Fabricacao de artigos para viagem, bolsas e semelhantes de qualquer material
                    </option>
                    <option value="1529700">
                      1529700 - Fabricacao de artefatos de couro nao especificados anteriormente
                    </option>
                    <option value="1531901">1531901 - Fabricacao de calcados de couro</option>
                    <option value="1531902">1531902 - acabamento de calcados de couro sob contrato</option>
                    <option value="1532700">1532700 - Fabricacao de tenis de qualquer material</option>
                    <option value="1533500">1533500 - Fabricacao de calcados de material sintetico</option>
                    <option value="1539400">
                      1539400 - Fabricacao de calcados de materiais nao especificados anteriormente
                    </option>
                    <option value="1540800">
                      1540800 - Fabricacao de partes para calcados, de qualquer material
                    </option>
                    <option value="1610203">1610203 - serrarias com desdobramento de madeira em bruto</option>
                    <option value="1610204">
                      1610204 - serrarias sem desdobramento de madeira em bruto -resserragem
                    </option>
                    <option value="1610205">
                      1610205 - servico de tratamento de madeira realizado sob contrato
                    </option>
                    <option value="1621800">
                      1621800 - Fabricacao de madeira laminada e de chapas de madeira compensada, prensada e
                      aglomerada
                    </option>
                    <option value="1622601">1622601 - Fabricacao de casas de madeira pre-fabricadas</option>
                    <option value="1622602">
                      1622602 - Fabricacao de esquadrias de madeira e de pecas de madeira para instalacoes
                      industriais e comerciais
                    </option>
                    <option value="1622699">
                      1622699 - Fabricacao de outros artigos de carpintaria para Construcao
                    </option>
                    <option value="1623400">
                      1623400 - Fabricacao de artefatos de tanoaria e de embalagens de madeira
                    </option>
                    <option value="1629301">
                      1629301 - Fabricacao de artefatos diversos de madeira, exceto moveis
                    </option>
                    <option value="1629302">
                      1629302 - Fabricacao de artefatos diversos de cortica, bambu, palha, vime e outros
                      materiais trancados, exceto moveis
                    </option>
                    <option value="1710900">
                      1710900 - Fabricacao de celulose e outras pastas para a Fabricacao de papel
                    </option>
                    <option value="1721400">1721400 - Fabricacao de papel</option>
                    <option value="1722200">1722200 - Fabricacao de cartolina e papel-cartao</option>
                    <option value="1731100">1731100 - Fabricacao de embalagens de papel</option>
                    <option value="1732000">
                      1732000 - Fabricacao de embalagens de cartolina e papel-cartao
                    </option>
                    <option value="1733800">
                      1733800 - Fabricacao de chapas e de embalagens de papelao ondulado
                    </option>
                    <option value="1741901">1741901 - Fabricacao de formularios continuos</option>
                    <option value="1741902">
                      1741902 - Fabricacao de produtos de papel, cartolina, papel cartao e papelao ondulado
                      para uso comercial e de escritorio, exceto formulario continuo
                    </option>
                    <option value="1742701">1742701 - Fabricacao de fraldas descartaveis</option>
                    <option value="1742702">1742702 - Fabricacao de absorventes higienicos</option>
                    <option value="1742799">
                      1742799 - Fabricacao de produtos de papel para uso domestico e higienico-sanitario nao
                      especificados anteriormente
                    </option>
                    <option value="1749400">
                      1749400 - Fabricacao de produtos de pastas celulosicas, papel, cartolina, papel-cartao e
                      papelao ondulado nao especificados anteriormente
                    </option>
                    <option value="1811301">1811301 - impressao de jornais</option>
                    <option value="1811302">
                      1811302 - impressao de livros, revistas e outras publicacoes periodicas
                    </option>
                    <option value="1812100">1812100 - impressao de material de seguranca</option>
                    <option value="1813001">1813001 - impressao de material para uso publicitario</option>
                    <option value="1813099">1813099 - impressao de material para outros usos</option>
                    <option value="1821100">1821100 - Servicos de pre-impressao</option>
                    <option value="1822900">1822900 - Servicos de acabamentos graficos</option>
                    <option value="1822901">1822901 - Servicos de encadernacao e plastificacao</option>
                    <option value="1822999">
                      1822999 - Servicos de acabamentos graficos, exceto encadernacao e plastificacao
                    </option>
                    <option value="1830001">1830001 - ReProducao de som em qualquer suporte</option>
                    <option value="1830002">1830002 - ReProducao de video em qualquer suporte</option>
                    <option value="1830003">1830003 - ReProducao de software em qualquer suporte</option>
                    <option value="1910100">1910100 - coquerias</option>
                    <option value="1921700">1921700 - Fabricacao de produtos do Refino de petroleo</option>
                    <option value="1922501">1922501 - Formulacao de combustiveis</option>
                    <option value="1922502">1922502 - Refino de oleos lubrificantes</option>
                    <option value="1922599">
                      1922599 - Fabricacao de outros produtos derivados do petroleo, exceto produtos do Refino
                    </option>
                    <option value="1931400">1931400 - Fabricacao de alcool</option>
                    <option value="1932200">1932200 - Fabricacao de biocombustiveis, exceto alcool</option>
                    <option value="2011800">2011800 - Fabricacao de cloro e alcalis</option>
                    <option value="2012600">2012600 - Fabricacao de intermediarios para fertilizantes</option>
                    <option value="2013400">2013400 - Fabricacao de adubos e fertilizantes</option>
                    <option value="2013401">
                      2013401 - Fabricacao de adubos e fertilizantes organo-minerais
                    </option>
                    <option value="2013402">
                      2013402 - Fabricacao de adubos e fertilizantes, exceto organo-minerais
                    </option>
                    <option value="2014200">2014200 - Fabricacao de gases industriais</option>
                    <option value="2019301">2019301 - Elaboracao de combustiveis nucleares</option>
                    <option value="2019399">
                      2019399 - Fabricacao de outros produtos quimicos inorganicos nao especificados
                      anteriormente
                    </option>
                    <option value="2021500">2021500 - Fabricacao de produtos petroquimicos basicos</option>
                    <option value="2022300">
                      2022300 - Fabricacao de intermediarios para plastificantes, resinas e fibras
                    </option>
                    <option value="2029100">
                      2029100 - Fabricacao de produtos quimicos organicos nao especificados anteriormente
                    </option>
                    <option value="2031200">2031200 - Fabricacao de resinas termoplasticas</option>
                    <option value="2032100">2032100 - Fabricacao de resinas termofixas</option>
                    <option value="2033900">2033900 - Fabricacao de elastomeros</option>
                    <option value="2040100">2040100 - Fabricacao de fibras artificiais e sinteticas</option>
                    <option value="2051700">2051700 - Fabricacao de defensivos agricolas</option>
                    <option value="2052500">2052500 - Fabricacao de desinfestantes domissanitarios</option>
                    <option value="2061400">2061400 - Fabricacao de saboes e detergentes sinteticos</option>
                    <option value="2062200">2062200 - Fabricacao de produtos de limpeza e polimento</option>
                    <option value="2063100">
                      2063100 - Fabricacao de cosmeticos, produtos de perfumaria e de higiene pessoal
                    </option>
                    <option value="2071100">
                      2071100 - Fabricacao de tintas, vernizes, esmaltes e lacas
                    </option>
                    <option value="2072000">2072000 - Fabricacao de tintas de impressao</option>
                    <option value="2073800">
                      2073800 - Fabricacao de impermeabilizantes, solventes e produtos afins
                    </option>
                    <option value="2091600">2091600 - Fabricacao de adesivos e selantes</option>
                    <option value="2092401">2092401 - Fabricacao de polvoras, explosivos e detonantes</option>
                    <option value="2092402">2092402 - Fabricacao de artigos pirotecnicos</option>
                    <option value="2092403">2092403 - Fabricacao de fosforos de seguranca</option>
                    <option value="2093200">2093200 - Fabricacao de aditivos de uso industrial</option>
                    <option value="2094100">2094100 - Fabricacao de catalisadores</option>
                    <option value="2099101">
                      2099101 - Fabricacao de chapas, filmes, papeis e outros materiais e produtos quimicos
                      para fotografia
                    </option>
                    <option value="2099199">
                      2099199 - Fabricacao de outros produtos quimicos nao especificados anteriormente
                    </option>
                    <option value="2110600">2110600 - Fabricacao de produtos farmoquimicos</option>
                    <option value="2121101">
                      2121101 - Fabricacao de medicamentos alopaticos para uso humano
                    </option>
                    <option value="2121102">
                      2121102 - Fabricacao de medicamentos homeopaticos para uso humano
                    </option>
                    <option value="2121103">
                      2121103 - Fabricacao de medicamentos fitoterapicos para uso humano
                    </option>
                    <option value="2122000">2122000 - Fabricacao de medicamentos para uso veterinario</option>
                    <option value="2123800">2123800 - Fabricacao de preparacoes farmaceuticas</option>
                    <option value="2211100">2211100 - Fabricacao de pneumaticos e de camaras-de-ar</option>
                    <option value="2212900">2212900 - reforma de pneumaticos usados</option>
                    <option value="2219600">
                      2219600 - Fabricacao de artefatos de borracha nao especificados anteriormente
                    </option>
                    <option value="2221800">
                      2221800 - Fabricacao de laminados planos e tubulares de material plastico
                    </option>
                    <option value="2222600">2222600 - Fabricacao de embalagens de material plastico</option>
                    <option value="2223400">
                      2223400 - Fabricacao de tubos e acessorios de material plastico para uso na Construcao
                    </option>
                    <option value="2229301">
                      2229301 - Fabricacao de artefatos de material plastico para uso pessoal e domestico
                    </option>
                    <option value="2229302">
                      2229302 - Fabricacao de artefatos de material plastico para usos industriais
                    </option>
                    <option value="2229303">
                      2229303 - Fabricacao de artefatos de material plastico para uso na Construcao, exceto
                      tubos e acessorios
                    </option>
                    <option value="2229399">
                      2229399 - Fabricacao de artefatos de material plastico para outros usos nao
                      especificados anteriormente
                    </option>
                    <option value="2311700">2311700 - Fabricacao de vidro plano e de seguranca</option>
                    <option value="2312500">2312500 - Fabricacao de embalagens de vidro</option>
                    <option value="2319200">2319200 - Fabricacao de artigos de vidro</option>
                    <option value="2320600">2320600 - Fabricacao de cimento</option>
                    <option value="2330301">
                      2330301 - Fabricacao de estruturas pre-moldadas de concreto armado, em serie e sob
                      encomenda
                    </option>
                    <option value="2330302">
                      2330302 - Fabricacao de artefatos de cimento para uso na Construcao
                    </option>
                    <option value="2330303">
                      2330303 - Fabricacao de artefatos de fibrocimento para uso na Construcao
                    </option>
                    <option value="2330304">2330304 - Fabricacao de casas pre-moldadas de concreto</option>
                    <option value="2330305">
                      2330305 - Preparacao de massa de concreto e argamassa para Construcao
                    </option>
                    <option value="2330399">
                      2330399 - Fabricacao de outros artefatos e produtos de concreto, cimento, fibrocimento,
                      gesso e materiais semelhantes
                    </option>
                    <option value="2341900">2341900 - Fabricacao de produtos ceramicos refratarios</option>
                    <option value="2342701">2342701 - Fabricacao de azulejos e pisos</option>
                    <option value="2342702">
                      2342702 - Fabricacao de artefatos de ceramica e barro cozido para uso na Construcao,
                      exceto azulejos e pisos
                    </option>
                    <option value="2349401">2349401 - Fabricacao de material sanitario de ceramica</option>
                    <option value="2349499">
                      2349499 - Fabricacao de produtos ceramicos nao-refratarios nao especificados
                      anteriormente
                    </option>
                    <option value="2391501">
                      2391501 - Britamento de pedras, exceto associado a Extracao
                    </option>
                    <option value="2391502">
                      2391502 - aparelhamento de pedras para Construcao, exceto associado a Extracao
                    </option>
                    <option value="2391503">
                      2391503 - aparelhamento de placas e execucao de trabalhos em marmore, granito, ardosia e
                      outras pedras
                    </option>
                    <option value="2392300">2392300 - Fabricacao de cal e gesso</option>
                    <option value="2399101">
                      2399101 - decoracao, lapidacao, gravacao, vitrificacao e outros trabalhos em ceramica,
                      louca, vidro e cristal
                    </option>
                    <option value="2399102">2399102 - Fabricacao de abrasivos</option>
                    <option value="2399199">
                      2399199 - Fabricacao de outros produtos de minerais nao-metalicos nao especificados
                      anteriormente
                    </option>
                    <option value="2411300">2411300 - Producao de ferro-gusa</option>
                    <option value="2412100">2412100 - Producao de ferroligas</option>
                    <option value="2421100">2421100 - Producao de semi-acabados de aco</option>
                    <option value="2422901">
                      2422901 - Producao de laminados planos de aco ao carbono, revestidos ou nao
                    </option>
                    <option value="2422902">2422902 - Producao de laminados planos de acos especiais</option>
                    <option value="2423701">2423701 - Producao de tubos de aco sem costura</option>
                    <option value="2423702">
                      2423702 - Producao de laminados longos de aco, exceto tubos
                    </option>
                    <option value="2424501">2424501 - Producao de arames de aco</option>
                    <option value="2424502">
                      2424502 - Producao de relaminados, trefilados e perfilados de aco, exceto arames
                    </option>
                    <option value="2431800">2431800 - Producao de tubos de aco com costura</option>
                    <option value="2439300">2439300 - Producao de outros tubos de ferro e aco</option>
                    <option value="2441501">
                      2441501 - Producao de aluminio e suas ligas em formas primarias
                    </option>
                    <option value="2441502">2441502 - Producao de laminados de aluminio</option>
                    <option value="2442300">2442300 - metalurgia dos metais preciosos</option>
                    <option value="2443100">2443100 - metalurgia do cobre</option>
                    <option value="2449101">2449101 - Producao de zinco em formas primarias</option>
                    <option value="2449102">2449102 - Producao de laminados de zinco</option>
                    <option value="2449103">2449103 - Fabricacao de anodos para galvanoplastia</option>
                    <option value="2449199">
                      2449199 - metalurgia de outros metais nao-ferrosos e suas ligas nao especificados
                      anteriormente
                    </option>
                    <option value="2451200">2451200 - fundicao de ferro e aco</option>
                    <option value="2452100">2452100 - fundicao de metais nao-ferrosos e suas ligas</option>
                    <option value="2511000">2511000 - Fabricacao de estruturas metalicas</option>
                    <option value="2512800">2512800 - Fabricacao de esquadrias de metal</option>
                    <option value="2513600">2513600 - Fabricacao de Obras de caldeiraria pesada</option>
                    <option value="2521700">
                      2521700 - Fabricacao de tanques, reservatorios metalicos e caldeiras para aquecimento
                      central
                    </option>
                    <option value="2522500">
                      2522500 - Fabricacao de caldeiras geradoras de vapor, exceto para aquecimento central e
                      para veiculos
                    </option>
                    <option value="2531401">2531401 - Producao de forjados de aco</option>
                    <option value="2531402">
                      2531402 - Producao de forjados de metais nao-ferrosos e suas ligas
                    </option>
                    <option value="2532201">2532201 - Producao de artefatos estampados de metal</option>
                    <option value="2532202">2532202 - metalurgia do po</option>
                    <option value="2539000">
                      2539000 - Servicos de usinagem, solda, tratamento e revestimento em metais
                    </option>
                    <option value="2539001">2539001 - Servicos de usinagem, tornearia e solda</option>
                    <option value="2539002">2539002 - Servicos de tratamento e revestimento em metais</option>
                    <option value="2541100">2541100 - Fabricacao de artigos de cutelaria</option>
                    <option value="2542000">
                      2542000 - Fabricacao de artigos de serralheria, exceto esquadrias
                    </option>
                    <option value="2543800">2543800 - Fabricacao de ferramentas</option>
                    <option value="2550101">
                      2550101 - Fabricacao de equipamento belico pesado, exceto veiculos militares de combate
                    </option>
                    <option value="2550102">
                      2550102 - Fabricacao de armas de fogo, outras armas e municoes
                    </option>
                    <option value="2591800">2591800 - Fabricacao de embalagens metalicas</option>
                    <option value="2592601">
                      2592601 - Fabricacao de produtos de trefilados de metal padronizados
                    </option>
                    <option value="2592602">
                      2592602 - Fabricacao de produtos de trefilados de metal, exceto padronizados
                    </option>
                    <option value="2593400">
                      2593400 - Fabricacao de artigos de metal para uso domestico e pessoal
                    </option>
                    <option value="2599301">
                      2599301 - Servicos de confeccao de armacoes metalicas para a Construcao
                    </option>
                    <option value="2599302">2599302 - servico de corte e dobra de metais</option>
                    <option value="2599399">
                      2599399 - Fabricacao de outros produtos de metal nao especificados anteriormente
                    </option>
                    <option value="2610800">2610800 - Fabricacao de componentes eletronicos</option>
                    <option value="2621300">2621300 - Fabricacao de equipamentos de informatica</option>
                    <option value="2622100">
                      2622100 - Fabricacao de perifericos para equipamentos de informatica
                    </option>
                    <option value="2631100">
                      2631100 - Fabricacao de equipamentos transmissores de comunicacao, pecas e acessorios
                    </option>
                    <option value="2632900">
                      2632900 - Fabricacao de aparelhos telefonicos e de outros equipamentos de comunicacao,
                      pecas e acessorios
                    </option>
                    <option value="2640000">
                      2640000 - Fabricacao de aparelhos de recepcao, ReProducao, gravacao e amplificacao de
                      audio e video
                    </option>
                    <option value="2651500">
                      2651500 - Fabricacao de aparelhos e equipamentos de medida, teste e controle
                    </option>
                    <option value="2652300">2652300 - Fabricacao de cronometros e relogios</option>
                    <option value="2660400">
                      2660400 - Fabricacao de aparelhos eletromedicos e eletroterapeuticos e equipamentos de
                      irradiacao
                    </option>
                    <option value="2670101">
                      2670101 - Fabricacao de equipamentos e instrumentos opticos, pecas e acessorios
                    </option>
                    <option value="2670102">
                      2670102 - Fabricacao de aparelhos fotograficos e cinematograficos, pecas e acessorios
                    </option>
                    <option value="2680900">
                      2680900 - Fabricacao de midias virgens, magneticas e opticas
                    </option>
                    <option value="2710401">
                      2710401 - Fabricacao de geradores de corrente continua e alternada, pecas e acessorios
                    </option>
                    <option value="2710402">
                      2710402 - Fabricacao de transformadores, indutores, conversores, sincronizadores e
                      semelhantes, pecas e acessorios
                    </option>
                    <option value="2710403">
                      2710403 - Fabricacao de motores eletricos, pecas e acessorios
                    </option>
                    <option value="2721000">
                      2721000 - Fabricacao de pilhas, baterias e acumuladores eletricos, exceto para veiculos
                      automotores
                    </option>
                    <option value="2722801">
                      2722801 - Fabricacao de baterias e acumuladores para veiculos automotores
                    </option>
                    <option value="2722802">
                      2722802 - recondicionamento de baterias e acumuladores para veiculos automotores
                    </option>
                    <option value="2731700">
                      2731700 - Fabricacao de aparelhos e equipamentos para Distribuicao e controle de energia
                      eletrica
                    </option>
                    <option value="2732500">
                      2732500 - Fabricacao de material eletrico para instalacoes em circuito de consumo
                    </option>
                    <option value="2733300">
                      2733300 - Fabricacao de fios, cabos e condutores eletricos isolados
                    </option>
                    <option value="2740601">2740601 - Fabricacao de lampadas</option>
                    <option value="2740602">
                      2740602 - Fabricacao de luminarias e outros equipamentos de iluminacao
                    </option>
                    <option value="2751100">
                      2751100 - Fabricacao de fogoes, refrigeradores e maquinas de lavar e secar para uso
                      domestico, pecas e acessorios
                    </option>
                    <option value="2759701">
                      2759701 - Fabricacao de aparelhos eletricos de uso pessoal, pecas e acessorios
                    </option>
                    <option value="2759799">
                      2759799 - Fabricacao de outros aparelhos eletrodomesticos nao especificados
                      anteriormente, pecas e acessorios
                    </option>
                    <option value="2790201">
                      2790201 - Fabricacao de eletrodos, contatos e outros artigos de carvao e grafita para
                      uso eletrico, eletroimas e isoladores
                    </option>
                    <option value="2790202">
                      2790202 - Fabricacao de equipamentos para sinalizacao e alarme
                    </option>
                    <option value="2790299">
                      2790299 - Fabricacao de outros equipamentos e aparelhos eletricos nao especificados
                      anteriormente
                    </option>
                    <option value="2811900">
                      2811900 - Fabricacao de motores e turbinas, pecas e acessorios, exceto para avioes e
                      veiculos rodoviarios
                    </option>
                    <option value="2812700">
                      2812700 - Fabricacao de equipamentos hidraulicos e pneumaticos, pecas e acessorios,
                      exceto valvulas
                    </option>
                    <option value="2813500">
                      2813500 - Fabricacao de valvulas, registros e dispositivos semelhantes, pecas e
                      acessorios
                    </option>
                    <option value="2814301">
                      2814301 - Fabricacao de compressores para uso industrial, pecas e acessorios
                    </option>
                    <option value="2814302">
                      2814302 - Fabricacao de compressores para uso nao industrial, pecas e acessorios
                    </option>
                    <option value="2815101">2815101 - Fabricacao de rolamentos para fins industriais</option>
                    <option value="2815102">
                      2815102 - Fabricacao de equipamentos de transmissao para fins industriais, exceto
                      rolamentos
                    </option>
                    <option value="2821601">
                      2821601 - Fabricacao de fornos industriais, aparelhos e equipamentos nao-eletricos para
                      instalacoes termicas, pecas e acessorios
                    </option>
                    <option value="2821602">
                      2821602 - Fabricacao de estufas e fornos eletricos para fins industriais, pecas e
                      acessorios
                    </option>
                    <option value="2822401">
                      2822401 - Fabricacao de maquinas, equipamentos e aparelhos para transporte e elevacao de
                      pessoas, pecas e acessorios
                    </option>
                    <option value="2822402">
                      2822402 - Fabricacao de maquinas, equipamentos e aparelhos para transporte e elevacao de
                      cargas, pecas e acessorios
                    </option>
                    <option value="2823200">
                      2823200 - Fabricacao de maquinas e aparelhos de refrigeracao e ventilacao para uso
                      industrial e comercial, pecas e acessorios
                    </option>
                    <option value="2824101">
                      2824101 - Fabricacao de aparelhos e equipamentos de ar condicionado para uso industrial
                    </option>
                    <option value="2824102">
                      2824102 - Fabricacao de aparelhos e equipamentos de ar condicionado para uso
                      nao-industrial
                    </option>
                    <option value="2825900">
                      2825900 - Fabricacao de maquinas e equipamentos para saneamento basico e ambiental,
                      pecas e acessorios
                    </option>
                    <option value="2829101">
                      2829101 - Fabricacao de maquinas de escrever, calcular e outros equipamentos
                      nao-eletronicos para escritorio, pecas e acessorios
                    </option>
                    <option value="2829199">
                      2829199 - Fabricacao de outras maquinas e equipamentos de uso geral nao especificados
                      anteriormente, pecas e acessorios
                    </option>
                    <option value="2831300">
                      2831300 - Fabricacao de tratores agricolas, pecas e acessorios
                    </option>
                    <option value="2832100">
                      2832100 - Fabricacao de equipamentos para irrigacao agricola, pecas e acessorios
                    </option>
                    <option value="2833000">
                      2833000 - Fabricacao de maquinas e equipamentos para a agricultura e pecuaria, pecas e
                      acessorios, exceto para irrigacao
                    </option>
                    <option value="2840200">
                      2840200 - Fabricacao de maquinas-ferramenta, pecas e acessorios
                    </option>
                    <option value="2851800">
                      2851800 - Fabricacao de maquinas e equipamentos para a prospeccao e Extracao de
                      petroleo, pecas e acessorios
                    </option>
                    <option value="2852600">
                      2852600 - Fabricacao de outras maquinas e equipamentos para uso na Extracao mineral,
                      pecas e acessorios, exceto na Extracao de petroleo
                    </option>
                    <option value="2853400">
                      2853400 - Fabricacao de tratores, pecas e acessorios, exceto agricolas
                    </option>
                    <option value="2854200">
                      2854200 - Fabricacao de maquinas e equipamentos para terraplenagem, pavimentacao e
                      Construcao, pecas e acessorios, exceto tratores
                    </option>
                    <option value="2861500">
                      2861500 - Fabricacao de maquinas para a industria metalurgica, pecas e acessorios,
                      exceto maquinas-ferramenta
                    </option>
                    <option value="2862300">
                      2862300 - Fabricacao de maquinas e equipamentos para as industrias de alimentos, bebidas
                      e fumo, pecas e acessorios
                    </option>
                    <option value="2863100">
                      2863100 - Fabricacao de maquinas e equipamentos para a industria textil, pecas e
                      acessorios
                    </option>
                    <option value="2864000">
                      2864000 - Fabricacao de maquinas e equipamentos para as industrias do vestuario, do
                      couro e de calcados, pecas e acessorios
                    </option>
                    <option value="2865800">
                      2865800 - Fabricacao de maquinas e equipamentos para as industrias de celulose, papel e
                      papelao e artefatos, pecas e acessorios
                    </option>
                    <option value="2866600">
                      2866600 - Fabricacao de maquinas e equipamentos para a industria do plastico, pecas e
                      acessorios
                    </option>
                    <option value="2869100">
                      2869100 - Fabricacao de maquinas e equipamentos para uso industrial especifico nao
                      especificados anteriormente, pecas e acessorios
                    </option>
                    <option value="2910701">
                      2910701 - Fabricacao de automoveis, camionetas e utilitarios
                    </option>
                    <option value="2910702">
                      2910702 - Fabricacao de chassis com motor para automoveis, camionetas e utilitarios
                    </option>
                    <option value="2910703">
                      2910703 - Fabricacao de motores para automoveis, camionetas e utilitarios
                    </option>
                    <option value="2920401">2920401 - Fabricacao de caminhoes e onibus</option>
                    <option value="2920402">2920402 - Fabricacao de motores para caminhoes e onibus</option>
                    <option value="2930101">
                      2930101 - Fabricacao de cabines, carrocerias e reboques para caminhoes
                    </option>
                    <option value="2930102">2930102 - Fabricacao de carrocerias para onibus</option>
                    <option value="2930103">
                      2930103 - Fabricacao de cabines, carrocerias e reboques para outros veiculos
                      automotores, exceto caminhoes e onibus
                    </option>
                    <option value="2941700">
                      2941700 - Fabricacao de pecas e acessorios para o sistema motor de veiculos automotores
                    </option>
                    <option value="2942500">
                      2942500 - Fabricacao de pecas e acessorios para os sistemas de marcha e transmissao de
                      veiculos automotores
                    </option>
                    <option value="2943300">
                      2943300 - Fabricacao de pecas e acessorios para o sistema de freios de veiculos
                      automotores
                    </option>
                    <option value="2944100">
                      2944100 - Fabricacao de pecas e acessorios para o sistema de direcao e suspensao de
                      veiculos automotores
                    </option>
                    <option value="2945000">
                      2945000 - Fabricacao de material eletrico e eletronico para veiculos automotores, exceto
                      baterias
                    </option>
                    <option value="2949201">
                      2949201 - Fabricacao de bancos e estofados para veiculos automotores
                    </option>
                    <option value="2949299">
                      2949299 - Fabricacao de outras pecas e acessorios para veiculos automotores nao
                      especificadas anteriormente
                    </option>
                    <option value="2950600">
                      2950600 - recondicionamento e recuperacao de motores para veiculos automotores
                    </option>
                    <option value="3011301">3011301 - Construcao de embarcacoes de grande porte</option>
                    <option value="3011302">
                      3011302 - Construcao de embarcacoes para uso comercial e para usos especiais, exceto de
                      grande porte
                    </option>
                    <option value="3012100">3012100 - Construcao de embarcacoes para esporte e lazer</option>
                    <option value="3031800">
                      3031800 - Fabricacao de locomotivas, vagoes e outros materiais rodantes
                    </option>
                    <option value="3032600">
                      3032600 - Fabricacao de pecas e acessorios para veiculos ferroviarios
                    </option>
                    <option value="3041500">3041500 - Fabricacao de aeronaves</option>
                    <option value="3042300">
                      3042300 - Fabricacao de turbinas, motores e outros componentes e pecas para aeronaves
                    </option>
                    <option value="3050400">3050400 - Fabricacao de veiculos militares de combate</option>
                    <option value="3091101">3091101 - Fabricacao de motocicletas</option>
                    <option value="3091102">
                      3091102 - Fabricacao de pecas e acessorios para motocicletas
                    </option>
                    <option value="3092000">
                      3092000 - Fabricacao de bicicletas e triciclos nao-motorizados, pecas e acessorios
                    </option>
                    <option value="3099700">
                      3099700 - Fabricacao de equipamentos de transporte nao especificados anteriormente
                    </option>
                    <option value="3101200">
                      3101200 - Fabricacao de moveis com predominancia de madeira
                    </option>
                    <option value="3102100">3102100 - Fabricacao de moveis com predominancia de metal</option>
                    <option value="3103900">
                      3103900 - Fabricacao de moveis de outros materiais, exceto madeira e metal
                    </option>
                    <option value="3104700">3104700 - Fabricacao de colchoes</option>
                    <option value="3211601">3211601 - lapidacao de gemas</option>
                    <option value="3211602">
                      3211602 - Fabricacao de artefatos de joalheria e ourivesaria
                    </option>
                    <option value="3211603">3211603 - cunhagem de moedas e medalhas</option>
                    <option value="3212400">
                      3212400 - Fabricacao de bijuterias e artefatos semelhantes
                    </option>
                    <option value="3220500">
                      3220500 - Fabricacao de instrumentos musicais, pecas e acessorios
                    </option>
                    <option value="3230200">3230200 - Fabricacao de artefatos para pesca e esporte</option>
                    <option value="3240001">3240001 - Fabricacao de jogos eletronicos</option>
                    <option value="3240002">
                      3240002 - Fabricacao de mesas de bilhar, de sinuca e acessorios nao associada a locacao
                    </option>
                    <option value="3240003">
                      3240003 - Fabricacao de mesas de bilhar, de sinuca e acessorios associada a locacao
                    </option>
                    <option value="3240099">
                      3240099 - Fabricacao de outros brinquedos e jogos recreativos nao especificados
                      anteriormente
                    </option>
                    <option value="3250701">
                      3250701 - Fabricacao de instrumentos nao-eletronicos e utensilios para uso medico,
                      cirurgico, odontologico e de laboratorio
                    </option>
                    <option value="3250702">
                      3250702 - Fabricacao de mobiliario para uso medico, cirurgico, odontologico e de
                      laboratorio
                    </option>
                    <option value="3250703">
                      3250703 - Fabricacao de aparelhos e utensilios para correcao de defeitos fisicos e
                      aparelhos ortopedicos em geral sob encomenda
                    </option>
                    <option value="3250704">
                      3250704 - Fabricacao de aparelhos e utensilios para correcao de defeitos fisicos e
                      aparelhos ortopedicos em geral, exceto sob encomenda
                    </option>
                    <option value="3250705">
                      3250705 - Fabricacao de materiais para medicina e odontologia
                    </option>
                    <option value="3250706">3250706 - Servicos de protese dentaria</option>
                    <option value="3250707">3250707 - Fabricacao de artigos opticos</option>
                    <option value="3250708">
                      3250708 - Fabricacao de artefatos de tecido nao tecido para uso odonto-medico-hospitalar
                    </option>
                    <option value="3250709">3250709 - servico de laboratorio optico</option>
                    <option value="3291400">3291400 - Fabricacao de escovas, pinceis e vassouras</option>
                    <option value="3292201">
                      3292201 - Fabricacao de roupas de protecao e seguranca e resistentes a fogo
                    </option>
                    <option value="3292202">
                      3292202 - Fabricacao de equipamentos e acessorios para seguranca pessoal e profissional
                    </option>
                    <option value="3299001">3299001 - Fabricacao de guarda-chuvas e similares</option>
                    <option value="3299002">
                      3299002 - Fabricacao de canetas, lapis e outros artigos para escritorio
                    </option>
                    <option value="3299003">
                      3299003 - Fabricacao de letras, letreiros e placas de qualquer material, exceto
                      luminosos
                    </option>
                    <option value="3299004">3299004 - Fabricacao de paineis e letreiros luminosos</option>
                    <option value="3299005">3299005 - Fabricacao de aviamentos para costura</option>
                    <option value="3299006">3299006 - Fabricacao de velas, inclusive decorativas</option>
                    <option value="3299099">
                      3299099 - Fabricacao de produtos diversos nao especificados anteriormente
                    </option>
                    <option value="3311200">
                      3311200 - Manutencao e reparacao de tanques, reservatorios metalicos e caldeiras, exceto
                      para veiculos
                    </option>
                    <option value="3312102">
                      3312102 - Manutencao e reparacao de aparelhos e instrumentos de medida, teste e controle
                    </option>
                    <option value="3312103">
                      3312103 - Manutencao e reparacao de aparelhos eletromedicos e eletroterapeuticos e
                      equipamentos de irradiacao
                    </option>
                    <option value="3312104">
                      3312104 - Manutencao e reparacao de equipamentos e instrumentos opticos
                    </option>
                    <option value="3313901">
                      3313901 - Manutencao e reparacao de geradores, transformadores e motores eletricos
                    </option>
                    <option value="3313902">
                      3313902 - Manutencao e reparacao de baterias e acumuladores eletricos, exceto para
                      veiculos
                    </option>
                    <option value="3313999">
                      3313999 - Manutencao e reparacao de maquinas, aparelhos e materiais eletricos nao
                      especificados anteriormente
                    </option>
                    <option value="3314701">
                      3314701 - Manutencao e reparacao de maquinas motrizes nao-eletricas
                    </option>
                    <option value="3314702">
                      3314702 - Manutencao e reparacao de equipamentos hidraulicos e pneumaticos, exceto
                      valvulas
                    </option>
                    <option value="3314703">3314703 - Manutencao e reparacao de valvulas industriais</option>
                    <option value="3314704">3314704 - Manutencao e reparacao de compressores</option>
                    <option value="3314705">
                      3314705 - Manutencao e reparacao de equipamentos de transmissao para fins industriais
                    </option>
                    <option value="3314706">
                      3314706 - Manutencao e reparacao de maquinas, aparelhos e equipamentos para instalacoes
                      termicas
                    </option>
                    <option value="3314707">
                      3314707 - Manutencao e reparacao de maquinas e aparelhos de refrigeracao e ventilacao
                      para uso industrial e comercial
                    </option>
                    <option value="3314708">
                      3314708 - Manutencao e reparacao de maquinas, equipamentos e aparelhos para transporte e
                      elevacao de cargas
                    </option>
                    <option value="3314709">
                      3314709 - Manutencao e reparacao de maquinas de escrever, calcular e de outros
                      equipamentos nao-eletronicos para escritorio
                    </option>
                    <option value="3314710">
                      3314710 - Manutencao e reparacao de maquinas e equipamentos para uso geral nao
                      especificados anteriormente
                    </option>
                    <option value="3314711">
                      3314711 - Manutencao e reparacao de maquinas e equipamentos para agricultura e pecuaria
                    </option>
                    <option value="3314712">3314712 - Manutencao e reparacao de tratores agricolas</option>
                    <option value="3314713">3314713 - Manutencao e reparacao de maquinas-ferramenta</option>
                    <option value="3314714">
                      3314714 - Manutencao e reparacao de maquinas e equipamentos para a prospeccao e Extracao
                      de petroleo
                    </option>
                    <option value="3314715">
                      3314715 - Manutencao e reparacao de maquinas e equipamentos para uso na Extracao
                      mineral, exceto na Extracao de petroleo
                    </option>
                    <option value="3314716">
                      3314716 - Manutencao e reparacao de tratores, exceto agricolas
                    </option>
                    <option value="3314717">
                      3314717 - Manutencao e reparacao de maquinas e equipamentos de terraplenagem,
                      pavimentacao e Construcao, exceto tratores
                    </option>
                    <option value="3314718">
                      3314718 - Manutencao e reparacao de maquinas para a industria metalurgica, exceto
                      maquinas-ferramenta
                    </option>
                    <option value="3314719">
                      3314719 - Manutencao e reparacao de maquinas e equipamentos para as industrias de
                      alimentos, bebidas e fumo
                    </option>
                    <option value="3314720">
                      3314720 - Manutencao e reparacao de maquinas e equipamentos para a industria textil, do
                      vestuario, do couro e calcados
                    </option>
                    <option value="3314721">
                      3314721 - Manutencao e reparacao de maquinas e aparelhos para a industria de celulose,
                      papel e papelao e artefatos
                    </option>
                    <option value="3314722">
                      3314722 - Manutencao e reparacao de maquinas e aparelhos para a industria do plastico
                    </option>
                    <option value="3314799">
                      3314799 - Manutencao e reparacao de outras maquinas e equipamentos para usos industriais
                      nao especificados anteriormente
                    </option>
                    <option value="3315500">3315500 - Manutencao e reparacao de veiculos ferroviarios</option>
                    <option value="3316301">
                      3316301 - Manutencao e reparacao de aeronaves, exceto a Manutencao na pista
                    </option>
                    <option value="3316302">3316302 - Manutencao de aeronaves na pista</option>
                    <option value="3317101">
                      3317101 - Manutencao e reparacao de embarcacoes e estruturas flutuantes
                    </option>
                    <option value="3317102">
                      3317102 - Manutencao e reparacao de embarcacoes para esporte e lazer
                    </option>
                    <option value="3319800">
                      3319800 - Manutencao e reparacao de equipamentos e produtos nao especificados
                      anteriormente
                    </option>
                    <option value="3321000">
                      3321000 - instalacao de maquinas e equipamentos industriais
                    </option>
                    <option value="3329501">
                      3329501 - Servicos de montagem de moveis de qualquer material
                    </option>
                    <option value="3329599">
                      3329599 - instalacao de outros equipamentos nao especificados anteriormente
                    </option>
                    <option value="3511500">3511500 - geracao de energia eletrica</option>
                    <option value="3511501">3511501 - geracao de energia eletrica</option>
                    <option value="3511502">
                      3511502 - Atividades de coordenacao e controle da operacao da geracao e transmissao de
                      energia eletrica
                    </option>
                    <option value="3512300">3512300 - transmissao de energia eletrica</option>
                    <option value="3513100">3513100 - comercio atacadista de energia eletrica</option>
                    <option value="3514000">3514000 - Distribuicao de energia eletrica</option>
                    <option value="3520401">3520401 - Producao de gas; Processamento de gas natural</option>
                    <option value="3520402">
                      3520402 - Distribuicao de combustiveis gasosos por redes urbanas
                    </option>
                    <option value="3530100">
                      3530100 - Producao e Distribuicao de vapor, agua quente e ar condicionado
                    </option>
                    <option value="3600601">3600601 - captacao, tratamento e Distribuicao de agua</option>
                    <option value="3600602">3600602 - Distribuicao de agua por caminhoes</option>
                    <option value="3701100">3701100 - Gestao de redes de esgoto</option>
                    <option value="3702900">
                      3702900 - Atividades relacionadas a esgoto, exceto a Gestao de redes
                    </option>
                    <option value="3811400">3811400 - coleta de residuos nao-perigosos</option>
                    <option value="3812200">3812200 - coleta de residuos perigosos</option>
                    <option value="3821100">
                      3821100 - tratamento e disposicao de residuos nao-perigosos
                    </option>
                    <option value="3822000">3822000 - tratamento e disposicao de residuos perigosos</option>
                    <option value="3831901">3831901 - recuperacao de sucatas de aluminio</option>
                    <option value="3831999">
                      3831999 - recuperacao de materiais metalicos, exceto aluminio
                    </option>
                    <option value="3832700">3832700 - recuperacao de materiais plasticos</option>
                    <option value="3839401">3839401 - usinas de compostagem</option>
                    <option value="3839499">
                      3839499 - recuperacao de materiais nao especificados anteriormente
                    </option>
                    <option value="3900500">
                      3900500 - descontaminacao e outros Servicos de Gestao de residuos
                    </option>
                    <option value="4110700">4110700 - incorporacao de empreendimentos imobiliarios</option>
                    <option value="4120400">4120400 - Construcao de edificios</option>
                    <option value="4211101">4211101 - Construcao de rodovias e ferrovias</option>
                    <option value="4211102">
                      4211102 - pintura para sinalizacao em pistas rodoviarias e aeroportos
                    </option>
                    <option value="4212000">4212000 - Construcao de Obras de arte especiais</option>
                    <option value="4213800">4213800 - Obras de urbanizacao - ruas, pracas e calcadas</option>
                    <option value="4221901">
                      4221901 - Construcao de barragens e represas para geracao de energia eletrica
                    </option>
                    <option value="4221902">
                      4221902 - Construcao de estacoes e redes de Distribuicao de energia eletrica
                    </option>
                    <option value="4221903">
                      4221903 - Manutencao de redes de Distribuicao de energia eletrica
                    </option>
                    <option value="4221904">
                      4221904 - Construcao de estacoes e redes de telecomunicacoes
                    </option>
                    <option value="4221905">
                      4221905 - Manutencao de estacoes e redes de telecomunicacoes
                    </option>
                    <option value="4222701">
                      4222701 - Construcao de redes de abastecimento de agua, coleta de esgoto e construcoes
                      correlatas, exceto Obras de irrigacao
                    </option>
                    <option value="4222702">4222702 - Obras de irrigacao</option>
                    <option value="4223500">
                      4223500 - Construcao de redes de transportes por dutos, exceto para agua e esgoto
                    </option>
                    <option value="4291000">4291000 - Obras portuarias, maritimas e fluviais</option>
                    <option value="4292801">4292801 - montagem de estruturas metalicas</option>
                    <option value="4292802">4292802 - Obras de montagem industrial</option>
                    <option value="4299501">
                      4299501 - Construcao de instalacoes esportivas e recreativas
                    </option>
                    <option value="4299599">
                      4299599 - outras Obras de engenharia civil nao especificadas anteriormente
                    </option>
                    <option value="4311801">4311801 - demolicao de edificios e outras estruturas</option>
                    <option value="4311802">4311802 - Preparacao de canteiro e limpeza de terreno</option>
                    <option value="4312600">4312600 - perfuracoes e sondagens</option>
                    <option value="4313400">4313400 - Obras de terraplenagem</option>
                    <option value="4319300">
                      4319300 - Servicos de Preparacao do terreno nao especificados anteriormente
                    </option>
                    <option value="4321500">4321500 - instalacao e Manutencao eletrica</option>
                    <option value="4322301">4322301 - instalacoes hidraulicas, sanitarias e de gas</option>
                    <option value="4322302">
                      4322302 - instalacao e Manutencao de sistemas centrais de ar condicionado, de ventilacao
                      e refrigeracao
                    </option>
                    <option value="4322303">
                      4322303 - instalacoes de sistema de prevencao contra incendio
                    </option>
                    <option value="4329101">4329101 - instalacao de paineis publicitarios</option>
                    <option value="4329102">
                      4329102 - instalacao de equipamentos para orientacao a navegacao maritima fluvial e
                      lacustre
                    </option>
                    <option value="4329103">
                      4329103 - instalacao, Manutencao e reparacao de elevadores, escadas e esteiras rolantes
                    </option>
                    <option value="4329104">
                      4329104 - montagem e instalacao de sistemas e equipamentos de iluminacao e sinalizacao
                      em vias publicas, portos e aeroportos
                    </option>
                    <option value="4329105">4329105 - tratamentos termicos, acusticos ou de vibracao</option>
                    <option value="4329199">
                      4329199 - outras Obras de instalacoes em construcoes nao especificadas anteriormente
                    </option>
                    <option value="4330401">4330401 - impermeabilizacao em Obras de engenharia civil</option>
                    <option value="4330402">
                      4330402 - instalacao de portas, janelas, tetos, divisorias e armarios embutidos de
                      qualquer material
                    </option>
                    <option value="4330403">4330403 - Obras de acabamento em gesso e estuque</option>
                    <option value="4330404">4330404 - Servicos de pintura de edificios em geral</option>
                    <option value="4330405">
                      4330405 - aplicacao de revestimentos e de resinas em interiores e exteriores
                    </option>
                    <option value="4330499">4330499 - outras Obras de acabamento da Construcao</option>
                    <option value="4391600">4391600 - Obras de fundacoes</option>
                    <option value="4399101">4399101 - administracao de Obras</option>
                    <option value="4399102">
                      4399102 - montagem e desmontagem de andaimes e outras estruturas temporarias
                    </option>
                    <option value="4399103">4399103 - Obras de alvenaria</option>
                    <option value="4399104">
                      4399104 - Servicos de operacao e fornecimento de equipamentos para transporte e elevacao
                      de cargas e pessoas para uso em Obras
                    </option>
                    <option value="4399105">4399105 - perfuracao e Construcao de pocos de agua</option>
                    <option value="4399199">
                      4399199 - Servicos especializados para Construcao nao especificados anteriormente
                    </option>
                    <option value="4511101">
                      4511101 - comercio a varejo de automoveis, camionetas e utilitarios novos
                    </option>
                    <option value="4511102">
                      4511102 - comercio a varejo de automoveis, camionetas e utilitarios usados
                    </option>
                    <option value="4511103">
                      4511103 - comercio por atacado de automoveis, camionetas e utilitarios novos e usados
                    </option>
                    <option value="4511104">
                      4511104 - comercio por atacado de caminhoes novos e usados
                    </option>
                    <option value="4511105">
                      4511105 - comercio por atacado de reboques e semi-reboques novos e usados
                    </option>
                    <option value="4511106">
                      4511106 - comercio por atacado de onibus e microonibus novos e usados
                    </option>
                    <option value="4512901">
                      4512901 - representantes comerciais e agentes do comercio de veiculos automotores
                    </option>
                    <option value="4512902">
                      4512902 - comercio sob consignacao de veiculos automotores
                    </option>
                    <option value="4520001">
                      4520001 - Servicos de Manutencao e reparacao mecanica de veiculos automotores
                    </option>
                    <option value="4520002">
                      4520002 - Servicos de lanternagem ou funilaria e pintura de veiculos automotores
                    </option>
                    <option value="4520003">
                      4520003 - Servicos de Manutencao e reparacao eletrica de veiculos automotores
                    </option>
                    <option value="4520004">
                      4520004 - Servicos de alinhamento e balanceamento de veiculos automotores
                    </option>
                    <option value="4520005">
                      4520005 - Servicos de lavagem, lubrificacao e polimento de veiculos automotores
                    </option>
                    <option value="4520006">
                      4520006 - Servicos de borracharia para veiculos automotores
                    </option>
                    <option value="4520007">
                      4520007 - Servicos de instalacao, Manutencao e reparacao de acessorios para veiculos
                      automotores
                    </option>
                    <option value="4520008">4520008 - Servicos de capotaria</option>
                    <option value="4530701">
                      4530701 - comercio por atacado de pecas e acessorios novos para veiculos automotores
                    </option>
                    <option value="4530702">
                      4530702 - comercio por atacado de pneumaticos e camaras-de-ar
                    </option>
                    <option value="4530703">
                      4530703 - comercio a varejo de pecas e acessorios novos para veiculos automotores
                    </option>
                    <option value="4530704">
                      4530704 - comercio a varejo de pecas e acessorios usados para veiculos automotores
                    </option>
                    <option value="4530705">
                      4530705 - comercio a varejo de pneumaticos e camaras-de-ar
                    </option>
                    <option value="4530706">
                      4530706 - representantes comerciais e agentes do comercio de pecas e acessorios novos e
                      usados para veiculos automotores
                    </option>
                    <option value="4541201">
                      4541201 - comercio por atacado de motocicletas e motonetas
                    </option>
                    <option value="4541202">
                      4541202 - comercio por atacado de pecas e acessorios para motocicletas e motonetas
                    </option>
                    <option value="4541203">
                      4541203 - comercio a varejo de motocicletas e motonetas novas
                    </option>
                    <option value="4541204">
                      4541204 - comercio a varejo de motocicletas e motonetas usadas
                    </option>
                    <option value="4541205">
                      4541205 - comercio a varejo de pecas e acessorios para motocicletas e motonetas
                    </option>
                    <option value="4541206">
                      4541206 - comercio a varejo de pecas e acessorios novos para motocicletas e motonetas
                    </option>
                    <option value="4541207">
                      4541207 - comercio a varejo de pecas e acessorios usados para motocicletas e motonetas
                    </option>
                    <option value="4542101">
                      4542101 - representantes comerciais e agentes do comercio de motocicletas e motonetas,
                      pecas e acessorios
                    </option>
                    <option value="4542102">
                      4542102 - comercio sob consignacao de motocicletas e motonetas
                    </option>
                    <option value="4543900">
                      4543900 - Manutencao e reparacao de motocicletas e motonetas
                    </option>
                    <option value="4611700">
                      4611700 - representantes comerciais e agentes do comercio de materias-primas agricolas e
                      animais vivos
                    </option>
                    <option value="4612500">
                      4612500 - representantes comerciais e agentes do comercio de combustiveis, minerais,
                      produtos siderurgicos e quimicos
                    </option>
                    <option value="4613300">
                      4613300 - representantes comerciais e agentes do comercio de madeira, material de
                      Construcao e ferragens
                    </option>
                    <option value="4614100">
                      4614100 - representantes comerciais e agentes do comercio de maquinas, equipamentos,
                      embarcacoes e aeronaves
                    </option>
                    <option value="4615000">
                      4615000 - representantes comerciais e agentes do comercio de eletrodomesticos, moveis e
                      artigos de uso domestico
                    </option>
                    <option value="4616800">
                      4616800 - representantes comerciais e agentes do comercio de texteis, vestuario,
                      calcados e artigos de viagem
                    </option>
                    <option value="4617600">
                      4617600 - representantes comerciais e agentes do comercio de produtos alimenticios,
                      bebidas e fumo
                    </option>
                    <option value="4618401">
                      4618401 - representantes comerciais e agentes do comercio de medicamentos, cosmeticos e
                      produtos de perfumaria
                    </option>
                    <option value="4618402">
                      4618402 - representantes comerciais e agentes do comercio de instrumentos e materiais
                      odonto-medico-hospitalares
                    </option>
                    <option value="4618403">
                      4618403 - representantes comerciais e agentes do comercio de jornais, revistas e outras
                      publicacoes
                    </option>
                    <option value="4618499">
                      4618499 - outros representantes comerciais e agentes do comercio especializado em
                      produtos nao especificados anteriormente
                    </option>
                    <option value="4619200">
                      4619200 - representantes comerciais e agentes do comercio de mercadorias em geral nao
                      especializado
                    </option>
                    <option value="4621400">4621400 - comercio atacadista de cafe em grao</option>
                    <option value="4622200">4622200 - comercio atacadista de soja</option>
                    <option value="4623101">4623101 - comercio atacadista de animais vivos</option>
                    <option value="4623102">
                      4623102 - comercio atacadista de couros, las, peles e outros subprodutos nao-comestiveis
                      de origem animal
                    </option>
                    <option value="4623103">4623103 - comercio atacadista de algodao</option>
                    <option value="4623104">
                      4623104 - comercio atacadista de fumo em folha nao beneficiado
                    </option>
                    <option value="4623105">4623105 - comercio atacadista de cacau</option>
                    <option value="4623106">
                      4623106 - comercio atacadista de sementes, flores, plantas e gramas
                    </option>
                    <option value="4623107">4623107 - comercio atacadista de sisal</option>
                    <option value="4623108">
                      4623108 - comercio atacadista de materias-primas agricolas com atividade de
                      fracionamento e acondicionamento associada
                    </option>
                    <option value="4623109">4623109 - comercio atacadista de alimentos para animais</option>
                    <option value="4623199">
                      4623199 - comercio atacadista de materias-primas agricolas nao especificadas
                      anteriormente
                    </option>
                    <option value="4631100">4631100 - comercio atacadista de leite e laticinios</option>
                    <option value="4632001">
                      4632001 - comercio atacadista de cereais e leguminosas beneficiados
                    </option>
                    <option value="4632002">
                      4632002 - comercio atacadista de farinhas, amidos e feculas
                    </option>
                    <option value="4632003">
                      4632003 - comercio atacadista de cereais e leguminosas beneficiados, farinhas, amidos e
                      feculas, com atividade de fracionamento e acondicionamento associada
                    </option>
                    <option value="4633801">
                      4633801 - comercio atacadista de frutas, verduras, raizes, tuberculos, hortalicas e
                      legumes frescos
                    </option>
                    <option value="4633802">4633802 - comercio atacadista de aves vivas e ovos</option>
                    <option value="4633803">
                      4633803 - comercio atacadista de coelhos e outros pequenos animais vivos para
                      alimentacao
                    </option>
                    <option value="4634601">
                      4634601 - comercio atacadista de carnes bovinas e suinas e derivados
                    </option>
                    <option value="4634602">
                      4634602 - comercio atacadista de aves abatidas e derivados
                    </option>
                    <option value="4634603">4634603 - comercio atacadista de pescados e frutos do mar</option>
                    <option value="4634699">
                      4634699 - comercio atacadista de carnes e derivados de outros animais
                    </option>
                    <option value="4635401">4635401 - comercio atacadista de agua mineral</option>
                    <option value="4635402">
                      4635402 - comercio atacadista de cerveja, chope e refrigerante
                    </option>
                    <option value="4635403">
                      4635403 - comercio atacadista de bebidas com atividade de fracionamento e
                      acondicionamento associada
                    </option>
                    <option value="4635499">
                      4635499 - comercio atacadista de bebidas nao especificadas anteriormente
                    </option>
                    <option value="4636201">4636201 - comercio atacadista de fumo beneficiado</option>
                    <option value="4636202">
                      4636202 - comercio atacadista de cigarros, cigarrilhas e charutos
                    </option>
                    <option value="4637101">
                      4637101 - comercio atacadista de cafe torrado, moido e soluvel
                    </option>
                    <option value="4637102">4637102 - comercio atacadista de acucar</option>
                    <option value="4637103">4637103 - comercio atacadista de oleos e gorduras</option>
                    <option value="4637104">
                      4637104 - comercio atacadista de paes, bolos, biscoitos e similares
                    </option>
                    <option value="4637105">4637105 - comercio atacadista de massas alimenticias</option>
                    <option value="4637106">4637106 - comercio atacadista de sorvetes</option>
                    <option value="4637107">
                      4637107 - comercio atacadista de chocolates, confeitos, balas, bombons e semelhantes
                    </option>
                    <option value="4637199">
                      4637199 - comercio atacadista especializado em outros produtos alimenticios nao
                      especificados anteriormente
                    </option>
                    <option value="4639701">
                      4639701 - comercio atacadista de produtos alimenticios em geral
                    </option>
                    <option value="4639702">
                      4639702 - comercio atacadista de produtos alimenticios em geral, com atividade de
                      fracionamento e acondicionamento associada
                    </option>
                    <option value="4641901">4641901 - comercio atacadista de tecidos</option>
                    <option value="4641902">
                      4641902 - comercio atacadista de artigos de cama, mesa e banho
                    </option>
                    <option value="4641903">4641903 - comercio atacadista de artigos de armarinho</option>
                    <option value="4642701">
                      4642701 - comercio atacadista de artigos do vestuario e acessorios, exceto profissionais
                      e de seguranca
                    </option>
                    <option value="4642702">
                      4642702 - comercio atacadista de roupas e acessorios para uso profissional e de
                      seguranca do trabalho
                    </option>
                    <option value="4643501">4643501 - comercio atacadista de calcados</option>
                    <option value="4643502">
                      4643502 - comercio atacadista de bolsas, malas e artigos de viagem
                    </option>
                    <option value="4644301">
                      4644301 - comercio atacadista de medicamentos e drogas de uso humano
                    </option>
                    <option value="4644302">
                      4644302 - comercio atacadista de medicamentos e drogas de uso veterinario
                    </option>
                    <option value="4645101">
                      4645101 - comercio atacadista de instrumentos e materiais para uso medico, cirurgico,
                      hospitalar e de laboratorios
                    </option>
                    <option value="4645102">
                      4645102 - comercio atacadista de proteses e artigos de ortopedia
                    </option>
                    <option value="4645103">4645103 - comercio atacadista de produtos odontologicos</option>
                    <option value="4646001">
                      4646001 - comercio atacadista de cosmeticos e produtos de perfumaria
                    </option>
                    <option value="4646002">
                      4646002 - comercio atacadista de produtos de higiene pessoal
                    </option>
                    <option value="4647801">
                      4647801 - comercio atacadista de artigos de escritorio e de papelaria
                    </option>
                    <option value="4647802">
                      4647802 - comercio atacadista de livros, jornais e outras publicacoes
                    </option>
                    <option value="4649401">
                      4649401 - comercio atacadista de equipamentos eletricos de uso pessoal e domestico
                    </option>
                    <option value="4649402">
                      4649402 - comercio atacadista de aparelhos eletronicos de uso pessoal e domestico
                    </option>
                    <option value="4649403">
                      4649403 - comercio atacadista de bicicletas, triciclos e outros veiculos recreativos
                    </option>
                    <option value="4649404">
                      4649404 - comercio atacadista de moveis e artigos de colchoaria
                    </option>
                    <option value="4649405">
                      4649405 - comercio atacadista de artigos de tapecaria; persianas e cortinas
                    </option>
                    <option value="4649406">
                      4649406 - comercio atacadista de lustres, luminarias e abajures
                    </option>
                    <option value="4649407">
                      4649407 - comercio atacadista de filmes, cds, dvds, fitas e discos
                    </option>
                    <option value="4649408">
                      4649408 - comercio atacadista de produtos de higiene, limpeza e conservacao domiciliar
                    </option>
                    <option value="4649409">
                      4649409 - comercio atacadista de produtos de higiene, limpeza e conservacao domiciliar,
                      com atividade de fracionamento e acondicionamento associada
                    </option>
                    <option value="4649410">
                      4649410 - comercio atacadista de joias, relogios e bijuterias, inclusive pedras
                      preciosas e semipreciosas lapidadas
                    </option>
                    <option value="4649499">
                      4649499 - comercio atacadista de outros equipamentos e artigos de uso pessoal e
                      domestico nao especificados anteriormente
                    </option>
                    <option value="4651601">
                      4651601 - comercio atacadista de equipamentos de informatica
                    </option>
                    <option value="4651602">
                      4651602 - comercio atacadista de suprimentos para informatica
                    </option>
                    <option value="4652400">
                      4652400 - comercio atacadista de componentes eletronicos e equipamentos de telefonia e
                      comunicacao
                    </option>
                    <option value="4661300">
                      4661300 - comercio atacadista de maquinas, aparelhos e equipamentos para uso
                      agropecuario; partes e pecas
                    </option>
                    <option value="4662100">
                      4662100 - comercio atacadista de maquinas, equipamentos para terraplenagem, mineracao e
                      Construcao; partes e pecas
                    </option>
                    <option value="4663000">
                      4663000 - comercio atacadista de maquinas e equipamentos para uso industrial; partes e
                      pecas
                    </option>
                    <option value="4664800">
                      4664800 - comercio atacadista de maquinas, aparelhos e equipamentos para uso
                      odonto-medico-hospitalar; partes e pecas
                    </option>
                    <option value="4665600">
                      4665600 - comercio atacadista de maquinas e equipamentos para uso comercial; partes e
                      pecas
                    </option>
                    <option value="4669901">
                      4669901 - comercio atacadista de bombas e compressores; partes e pecas
                    </option>
                    <option value="4669999">
                      4669999 - comercio atacadista de outras maquinas e equipamentos nao especificados
                      anteriormente; partes e pecas
                    </option>
                    <option value="4671100">
                      4671100 - comercio atacadista de madeira e produtos derivados
                    </option>
                    <option value="4672900">4672900 - comercio atacadista de ferragens e ferramentas</option>
                    <option value="4673700">4673700 - comercio atacadista de material eletrico</option>
                    <option value="4674500">4674500 - comercio atacadista de cimento</option>
                    <option value="4679601">
                      4679601 - comercio atacadista de tintas, vernizes e similares
                    </option>
                    <option value="4679602">4679602 - comercio atacadista de marmores e granitos</option>
                    <option value="4679603">
                      4679603 - comercio atacadista de vidros, espelhos, vitrais e molduras
                    </option>
                    <option value="4679604">
                      4679604 - comercio atacadista especializado de materiais de Construcao nao especificados
                      anteriormente
                    </option>
                    <option value="4679699">
                      4679699 - comercio atacadista de materiais de Construcao em geral
                    </option>
                    <option value="4681801">
                      4681801 - comercio atacadista de alcool carburante, biodiesel, gasolina e demais
                      derivados de petroleo, exceto lubrificantes, nao realizado por transportador retalhista
                      (t.r.r.)
                    </option>
                    <option value="4681802">
                      4681802 - comercio atacadista de combustiveis realizado por transportador retalhista
                      (t.r.r.)
                    </option>
                    <option value="4681803">
                      4681803 - comercio atacadista de combustiveis de origem vegetal, exceto alcool
                      carburante
                    </option>
                    <option value="4681804">
                      4681804 - comercio atacadista de combustiveis de origem mineral em bruto
                    </option>
                    <option value="4681805">4681805 - comercio atacadista de lubrificantes</option>
                    <option value="4682600">
                      4682600 - comercio atacadista de gas liquefeito de petroleo (glp)
                    </option>
                    <option value="4683400">
                      4683400 - comercio atacadista de defensivos agricolas, adubos, fertilizantes e
                      corretivos do solo
                    </option>
                    <option value="4684201">4684201 - comercio atacadista de resinas e elastomeros</option>
                    <option value="4684202">4684202 - comercio atacadista de solventes</option>
                    <option value="4684299">
                      4684299 - comercio atacadista de outros produtos quimicos e petroquimicos nao
                      especificados anteriormente
                    </option>
                    <option value="4685100">
                      4685100 - comercio atacadista de produtos siderurgicos e metalurgicos, exceto para
                      Construcao
                    </option>
                    <option value="4686901">4686901 - comercio atacadista de papel e papelao em bruto</option>
                    <option value="4686902">4686902 - comercio atacadista de embalagens</option>
                    <option value="4687701">
                      4687701 - comercio atacadista de residuos de papel e papelao
                    </option>
                    <option value="4687702">
                      4687702 - comercio atacadista de residuos e sucatas nao-metalicos, exceto de papel e
                      papelao
                    </option>
                    <option value="4687703">
                      4687703 - comercio atacadista de residuos e sucatas metalicos
                    </option>
                    <option value="4689301">
                      4689301 - comercio atacadista de produtos da Extracao mineral, exceto combustiveis
                    </option>
                    <option value="4689302">
                      4689302 - comercio atacadista de fios e fibras beneficiados
                    </option>
                    <option value="4689399">
                      4689399 - comercio atacadista especializado em outros produtos intermediarios nao
                      especificados anteriormente
                    </option>
                    <option value="4691500">
                      4691500 - comercio atacadista de mercadorias em geral, com predominancia de produtos
                      alimenticios
                    </option>
                    <option value="4692300">
                      4692300 - comercio atacadista de mercadorias em geral, com predominancia de insumos
                      agropecuarios
                    </option>
                    <option value="4693100">
                      4693100 - comercio atacadista de mercadorias em geral, sem predominancia de alimentos ou
                      de insumos agropecuarios
                    </option>
                    <option value="4711301">
                      4711301 - comercio varejista de mercadorias em geral, com predominancia de produtos
                      alimenticios - hipermercados
                    </option>
                    <option value="4711302">
                      4711302 - comercio varejista de mercadorias em geral, com predominancia de produtos
                      alimenticios - supermercados
                    </option>
                    <option value="4712100">
                      4712100 - comercio varejista de mercadorias em geral, com predominancia de produtos
                      alimenticios - minimercados, mercearias e armazens
                    </option>
                    <option value="4713002">
                      4713002 - lojas de variedades, exceto lojas de departamentos ou magazines
                    </option>
                    <option value="4713004">
                      4713004 - lojas de departamentos ou magazines, exceto lojas francas (duty free)
                    </option>
                    <option value="4713005">
                      4713005 - lojas francas (duty free) de aeroportos, portos e em fronteiras terrestres
                    </option>
                    <option value="4721101">
                      4721101 - padaria e confeitaria com predominancia de Producao propria
                    </option>
                    <option value="4721102">
                      4721102 - padaria e confeitaria com predominancia de revenda
                    </option>
                    <option value="4721103">4721103 - comercio varejista de laticinios e frios</option>
                    <option value="4721104">
                      4721104 - comercio varejista de doces, balas, bombons e semelhantes
                    </option>
                    <option value="4722901">4722901 - comercio varejista de carnes - acougues</option>
                    <option value="4722902">4722902 - peixaria</option>
                    <option value="4723700">4723700 - comercio varejista de bebidas</option>
                    <option value="4724500">4724500 - comercio varejista de hortifrutigranjeiros</option>
                    <option value="4729601">4729601 - tabacaria</option>
                    <option value="4729602">
                      4729602 - comercio varejista de mercadorias em lojas de conveniencia
                    </option>
                    <option value="4729699">
                      4729699 - comercio varejista de produtos alimenticios em geral ou especializado em
                      produtos alimenticios nao especificados anteriormente
                    </option>
                    <option value="4731800">
                      4731800 - comercio varejista de combustiveis para veiculos automotores
                    </option>
                    <option value="4732600">4732600 - comercio varejista de lubrificantes</option>
                    <option value="4741500">
                      4741500 - comercio varejista de tintas e materiais para pintura
                    </option>
                    <option value="4742300">4742300 - comercio varejista de material eletrico</option>
                    <option value="4743100">4743100 - comercio varejista de vidros</option>
                    <option value="4744001">4744001 - comercio varejista de ferragens e ferramentas</option>
                    <option value="4744002">4744002 - comercio varejista de madeira e artefatos</option>
                    <option value="4744003">4744003 - comercio varejista de materiais hidraulicos</option>
                    <option value="4744004">
                      4744004 - comercio varejista de cal, areia, pedra britada, tijolos e telhas
                    </option>
                    <option value="4744005">
                      4744005 - comercio varejista de materiais de Construcao nao especificados anteriormente
                    </option>
                    <option value="4744006">4744006 - comercio varejista de pedras para revestimento</option>
                    <option value="4744099">
                      4744099 - comercio varejista de materiais de Construcao em geral
                    </option>
                    <option value="4751200">
                      4751200 - comercio varejista especializado de equipamentos e suprimentos de informatica
                    </option>
                    <option value="4751201">
                      4751201 - comercio varejista especializado de equipamentos e suprimentos de informatica
                    </option>
                    <option value="4751202">
                      4751202 - recarga de cartuchos para equipamentos de informatica
                    </option>
                    <option value="4752100">
                      4752100 - comercio varejista especializado de equipamentos de telefonia e comunicacao
                    </option>
                    <option value="4753900">
                      4753900 - comercio varejista especializado de eletrodomesticos e equipamentos de audio e
                      video
                    </option>
                    <option value="4754701">4754701 - comercio varejista de moveis</option>
                    <option value="4754702">4754702 - comercio varejista de artigos de colchoaria</option>
                    <option value="4754703">4754703 - comercio varejista de artigos de iluminacao</option>
                    <option value="4755501">4755501 - comercio varejista de tecidos</option>
                    <option value="4755502">4755502 - comercio varejista de artigos de armarinho</option>
                    <option value="4755503">
                      4755503 - comercio varejista de artigos de cama, mesa e banho
                    </option>
                    <option value="4756300">
                      4756300 - comercio varejista especializado de instrumentos musicais e acessorios
                    </option>
                    <option value="4757100">
                      4757100 - comercio varejista especializado de pecas e acessorios para aparelhos
                      eletroeletronicos para uso domestico, exceto informatica e comunicacao
                    </option>
                    <option value="4759801">
                      4759801 - comercio varejista de artigos de tapecaria, cortinas e persianas
                    </option>
                    <option value="4759899">
                      4759899 - comercio varejista de outros artigos de uso pessoal e domestico nao
                      especificados anteriormente
                    </option>
                    <option value="4761000">4761000 - atividade nao informada</option>
                    <option value="4761001">4761001 - comercio varejista de livros</option>
                    <option value="4761002">4761002 - comercio varejista de jornais e revistas</option>
                    <option value="4761003">4761003 - comercio varejista de artigos de papelaria</option>
                    <option value="4762800">4762800 - comercio varejista de discos, cds, dvds e fitas</option>
                    <option value="4763601">
                      4763601 - comercio varejista de brinquedos e artigos recreativos
                    </option>
                    <option value="4763602">4763602 - comercio varejista de artigos esportivos</option>
                    <option value="4763603">
                      4763603 - comercio varejista de bicicletas e triciclos; pecas e acessorios
                    </option>
                    <option value="4763604">
                      4763604 - comercio varejista de artigos de caca, pesca e camping
                    </option>
                    <option value="4763605">
                      4763605 - comercio varejista de embarcacoes e outros veiculos recreativos; pecas e
                      acessorios
                    </option>
                    <option value="4771701">
                      4771701 - comercio varejista de produtos farmaceuticos, sem manipulacao de formulas
                    </option>
                    <option value="4771702">
                      4771702 - comercio varejista de produtos farmaceuticos, com manipulacao de formulas
                    </option>
                    <option value="4771703">
                      4771703 - comercio varejista de produtos farmaceuticos homeopaticos
                    </option>
                    <option value="4771704">4771704 - comercio varejista de medicamentos veterinarios</option>
                    <option value="4772500">
                      4772500 - comercio varejista de cosmeticos, produtos de perfumaria e de higiene pessoal
                    </option>
                    <option value="4773300">
                      4773300 - comercio varejista de artigos medicos e ortopedicos
                    </option>
                    <option value="4774100">4774100 - comercio varejista de artigos de optica</option>
                    <option value="4781400">
                      4781400 - comercio varejista de artigos do vestuario e acessorios
                    </option>
                    <option value="4782201">4782201 - comercio varejista de calcados</option>
                    <option value="4782202">4782202 - comercio varejista de artigos de viagem</option>
                    <option value="4783101">4783101 - comercio varejista de artigos de joalheria</option>
                    <option value="4783102">4783102 - comercio varejista de artigos de relojoaria</option>
                    <option value="4784900">
                      4784900 - comercio varejista de gas liquefeito de petroleo (glp)
                    </option>
                    <option value="4785701">4785701 - comercio varejista de antiguidades</option>
                    <option value="4785799">4785799 - comercio varejista de outros artigos usados</option>
                    <option value="4789001">
                      4789001 - comercio varejista de suvenires, bijuterias e artesanatos
                    </option>
                    <option value="4789002">4789002 - comercio varejista de plantas e flores naturais</option>
                    <option value="4789003">4789003 - comercio varejista de objetos de arte</option>
                    <option value="4789004">
                      4789004 - comercio varejista de animais vivos e de artigos e alimentos para animais de
                      estimacao
                    </option>
                    <option value="4789005">
                      4789005 - comercio varejista de produtos saneantes domissanitarios
                    </option>
                    <option value="4789006">
                      4789006 - comercio varejista de fogos de artificio e artigos pirotecnicos
                    </option>
                    <option value="4789007">
                      4789007 - comercio varejista de equipamentos para escritorio
                    </option>
                    <option value="4789008">
                      4789008 - comercio varejista de artigos fotograficos e para filmagem
                    </option>
                    <option value="4789009">4789009 - comercio varejista de armas e municoes</option>
                    <option value="4789099">
                      4789099 - comercio varejista de outros produtos nao especificados anteriormente
                    </option>
                    <option value="4911600">4911600 - transporte ferroviario de carga</option>
                    <option value="4912401">
                      4912401 - transporte ferroviario de passageiros intermunicipal e interestadual
                    </option>
                    <option value="4912402">
                      4912402 - transporte ferroviario de passageiros municipal e em regiao metropolitana
                    </option>
                    <option value="4912403">4912403 - transporte metroviario</option>
                    <option value="4921301">
                      4921301 - transporte rodoviario coletivo de passageiros, com itinerario fixo, municipal
                    </option>
                    <option value="4921302">
                      4921302 - transporte rodoviario coletivo de passageiros, com itinerario fixo,
                      intermunicipal em regiao metropolitana
                    </option>
                    <option value="4922101">
                      4922101 - transporte rodoviario coletivo de passageiros, com itinerario fixo,
                      intermunicipal, exceto em regiao metropolitana
                    </option>
                    <option value="4922102">
                      4922102 - transporte rodoviario coletivo de passageiros, com itinerario fixo,
                      interestadual
                    </option>
                    <option value="4922103">
                      4922103 - transporte rodoviario coletivo de passageiros, com itinerario fixo,
                      internacional
                    </option>
                    <option value="4923001">4923001 - servico de taxi</option>
                    <option value="4923002">
                      4923002 - servico de transporte de passageiros - locacao de automoveis com motorista
                    </option>
                    <option value="4924800">4924800 - transporte escolar</option>
                    <option value="4929901">
                      4929901 - transporte rodoviario coletivo de passageiros, sob regime de fretamento,
                      municipal
                    </option>
                    <option value="4929902">
                      4929902 - transporte rodoviario coletivo de passageiros, sob regime de fretamento,
                      intermunicipal, interestadual e internacional
                    </option>
                    <option value="4929903">
                      4929903 - organizacao de excursoes em veiculos rodoviarios proprios, municipal
                    </option>
                    <option value="4929904">
                      4929904 - organizacao de excursoes em veiculos rodoviarios proprios, intermunicipal,
                      interestadual e internacional
                    </option>
                    <option value="4929999">
                      4929999 - outros transportes rodoviarios de passageiros nao especificados anteriormente
                    </option>
                    <option value="4930201">
                      4930201 - transporte rodoviario de carga, exceto produtos perigosos e mudancas,
                      municipal.
                    </option>
                    <option value="4930202">
                      4930202 - transporte rodoviario de carga, exceto produtos perigosos e mudancas,
                      intermunicipal, interestadual e internacional
                    </option>
                    <option value="4930203">4930203 - transporte rodoviario de produtos perigosos</option>
                    <option value="4930204">4930204 - transporte rodoviario de mudancas</option>
                    <option value="4940000">4940000 - transporte dutoviario</option>
                    <option value="4950700">4950700 - trens turisticos, telefericos e similares</option>
                    <option value="5011401">5011401 - transporte maritimo de cabotagem - carga</option>
                    <option value="5011402">5011402 - transporte maritimo de cabotagem - passageiros</option>
                    <option value="5012201">5012201 - transporte maritimo de longo curso - carga</option>
                    <option value="5012202">
                      5012202 - transporte maritimo de longo curso - passageiros
                    </option>
                    <option value="5021101">
                      5021101 - transporte por navegacao interior de carga, municipal, exceto travessia
                    </option>
                    <option value="5021102">
                      5021102 - transporte por navegacao interior de carga, intermunicipal, interestadual e
                      internacional, exceto travessia
                    </option>
                    <option value="5022001">
                      5022001 - transporte por navegacao interior de passageiros em linhas regulares,
                      municipal, exceto travessia
                    </option>
                    <option value="5022002">
                      5022002 - transporte por navegacao interior de passageiros em linhas regulares,
                      intermunicipal, interestadual e internacional, exceto travessia
                    </option>
                    <option value="5030101">5030101 - navegacao de apoio maritimo</option>
                    <option value="5030102">5030102 - navegacao de apoio portuario</option>
                    <option value="5030103">5030103 - servico de rebocadores e empurradores</option>
                    <option value="5091201">
                      5091201 - transporte por navegacao de travessia, municipal
                    </option>
                    <option value="5091202">
                      5091202 - transporte por navegacao de travessia intermunicipal, interestadual e
                      internacional
                    </option>
                    <option value="5099801">5099801 - transporte aquaviario para passeios turisticos</option>
                    <option value="5099899">
                      5099899 - outros transportes aquaviarios nao especificados anteriormente
                    </option>
                    <option value="5111100">5111100 - transporte aereo de passageiros regular</option>
                    <option value="5112901">
                      5112901 - servico de taxi aereo e locacao de aeronaves com tripulacao
                    </option>
                    <option value="5112999">
                      5112999 - outros Servicos de transporte aereo de passageiros nao-regular
                    </option>
                    <option value="5120000">5120000 - transporte aereo de carga</option>
                    <option value="5130700">5130700 - transporte espacial</option>
                    <option value="5211701">5211701 - armazens gerais - emissao de warrant</option>
                    <option value="5211702">5211702 - guarda-moveis</option>
                    <option value="5211799">
                      5211799 - depositos de mercadorias para terceiros, exceto armazens gerais e
                      guarda-moveis
                    </option>
                    <option value="5212500">5212500 - carga e descarga</option>
                    <option value="5221400">
                      5221400 - concessionarias de rodovias, pontes, tuneis e Servicos relacionados
                    </option>
                    <option value="5222200">5222200 - terminais rodoviarios e ferroviarios</option>
                    <option value="5223100">5223100 - estacionamento de veiculos</option>
                    <option value="5229001">
                      5229001 - Servicos de apoio ao transporte por taxi, inclusive centrais de chamada
                    </option>
                    <option value="5229002">5229002 - Servicos de reboque de veiculos</option>
                    <option value="5229099">
                      5229099 - outras Atividades auxiliares dos transportes terrestres nao especificadas
                      anteriormente
                    </option>
                    <option value="5231101">5231101 - administracao da infra-estrutura portuaria</option>
                    <option value="5231102">5231102 - Atividades do operador portuario</option>
                    <option value="5231103">5231103 - Gestao de terminais aquaviarios</option>
                    <option value="5232000">5232000 - Atividades de agenciamento maritimo</option>
                    <option value="5239700">
                      5239700 - Atividades auxiliares dos transportes aquaviarios nao especificadas
                      anteriormente
                    </option>
                    <option value="5239701">5239701 - Servicos de praticagem</option>
                    <option value="5239799">
                      5239799 - Atividades auxiliares dos transportes aquaviarios nao especificadas
                      anteriormente
                    </option>
                    <option value="5240101">
                      5240101 - operacao dos aeroportos e campos de aterrissagem
                    </option>
                    <option value="5240199">
                      5240199 - Atividades auxiliares dos transportes aereos, exceto operacao dos aeroportos e
                      campos de aterrissagem
                    </option>
                    <option value="5250801">5250801 - comissaria de despachos</option>
                    <option value="5250802">5250802 - Atividades de despachantes aduaneiros</option>
                    <option value="5250803">
                      5250803 - agenciamento de cargas, exceto para o transporte maritimo
                    </option>
                    <option value="5250804">5250804 - organizacao logistica do transporte de carga</option>
                    <option value="5250805">5250805 - operador de transporte multimodal - otm</option>
                    <option value="5310501">5310501 - Atividades do correio nacional</option>
                    <option value="5310502">5310502 - Atividades de franqueadas do correio nacional</option>
                    <option value="5320201">
                      5320201 - Servicos de malote nao realizados pelo correio nacional
                    </option>
                    <option value="5320202">5320202 - Servicos de entrega rapida</option>
                    <option value="5510801">5510801 - hoteis</option>
                    <option value="5510802">5510802 - apart-hoteis</option>
                    <option value="5510803">5510803 - moteis</option>
                    <option value="5590601">5590601 - albergues, exceto assistenciais</option>
                    <option value="5590602">5590602 - campings</option>
                    <option value="5590603">5590603 - pensoes (alojamento)</option>
                    <option value="5590699">
                      5590699 - outros alojamentos nao especificados anteriormente
                    </option>
                    <option value="5611201">5611201 - restaurantes e similares</option>
                    <option value="5611202">
                      5611202 - bares e outros estabelecimentos especializados em servir bebidas
                    </option>
                    <option value="5611203">5611203 - lanchonetes, casas de cha, de sucos e similares</option>
                    <option value="5611204">
                      5611204 - bares e outros estabelecimentos especializados em servir bebidas, sem
                      entretenimento
                    </option>
                    <option value="5611205">
                      5611205 - bares e outros estabelecimentos especializados em servir bebidas, com
                      entretenimento
                    </option>
                    <option value="5612100">5612100 - Servicos ambulantes de alimentacao</option>
                    <option value="5620101">
                      5620101 - fornecimento de alimentos preparados preponderantemente para empresas
                    </option>
                    <option value="5620102">
                      5620102 - Servicos de alimentacao para eventos e recepcoes - bufe
                    </option>
                    <option value="5620103">5620103 - cantinas - Servicos de alimentacao privativos</option>
                    <option value="5620104">
                      5620104 - fornecimento de alimentos preparados preponderantemente para consumo
                      domiciliar
                    </option>
                    <option value="5811500">5811500 - edicao de livros</option>
                    <option value="5812300">5812300 - edicao de jornais</option>
                    <option value="5812301">5812301 - edicao de jornais diarios</option>
                    <option value="5812302">5812302 - edicao de jornais nao diarios</option>
                    <option value="5813100">5813100 - edicao de revistas</option>
                    <option value="5819100">
                      5819100 - edicao de cadastros, listas e de outros produtos graficos
                    </option>
                    <option value="5821200">5821200 - edicao integrada a impressao de livros</option>
                    <option value="5822100">5822100 - edicao integrada a impressao de jornais</option>
                    <option value="5822101">5822101 - edicao integrada a impressao de jornais diarios</option>
                    <option value="5822102">
                      5822102 - edicao integrada a impressao de jornais nao diarios
                    </option>
                    <option value="5823900">5823900 - edicao integrada a impressao de revistas</option>
                    <option value="5829800">
                      5829800 - edicao integrada a impressao de cadastros, listas e de outros produtos
                      graficos
                    </option>
                    <option value="5911101">5911101 - estudios cinematograficos</option>
                    <option value="5911102">5911102 - Producao de filmes para publicidade</option>
                    <option value="5911199">
                      5911199 - Atividades de Producao cinematografica, de videos e de programas de televisao
                      nao especificadas anteriormente
                    </option>
                    <option value="5912001">5912001 - Servicos de dublagem</option>
                    <option value="5912002">
                      5912002 - Servicos de mixagem sonora em Producao audiovisual
                    </option>
                    <option value="5912099">
                      5912099 - Atividades de pos-Producao cinematografica, de videos e de programas de
                      televisao nao especificadas anteriormente
                    </option>
                    <option value="5913800">
                      5913800 - Distribuicao cinematografica, de video e de programas de televisao
                    </option>
                    <option value="5914600">5914600 - Atividades de exibicao cinematografica</option>
                    <option value="5920100">
                      5920100 - Atividades de gravacao de som e de edicao de musica
                    </option>
                    <option value="6010100">6010100 - Atividades de radio</option>
                    <option value="6021700">6021700 - Atividades de televisao aberta</option>
                    <option value="6022501">6022501 - programadoras</option>
                    <option value="6022502">
                      6022502 - Atividades relacionadas a televisao por assinatura, exceto programadoras
                    </option>
                    <option value="6110801">6110801 - Servicos de telefonia fixa comutada - stfc</option>
                    <option value="6110802">
                      6110802 - Servicos de redes de transportes de telecomunicacoes - srtt
                    </option>
                    <option value="6110803">6110803 - Servicos de comunicacao multimidia - scm</option>
                    <option value="6110899">
                      6110899 - Servicos de telecomunicacoes por fio nao especificados anteriormente
                    </option>
                    <option value="6120501">6120501 - telefonia movel celular</option>
                    <option value="6120502">6120502 - servico movel especializado - sme</option>
                    <option value="6120599">
                      6120599 - Servicos de telecomunicacoes sem fio nao especificados anteriormente
                    </option>
                    <option value="6130200">6130200 - telecomunicacoes por satelite</option>
                    <option value="6141800">6141800 - operadoras de televisao por assinatura por cabo</option>
                    <option value="6142600">
                      6142600 - operadoras de televisao por assinatura por microondas
                    </option>
                    <option value="6143400">
                      6143400 - operadoras de televisao por assinatura por satelite
                    </option>
                    <option value="6190601">6190601 - provedores de acesso as redes de comunicacoes</option>
                    <option value="6190602">
                      6190602 - provedores de voz sobre protocolo internet - voip
                    </option>
                    <option value="6190699">
                      6190699 - outras Atividades de telecomunicacoes nao especificadas anteriormente
                    </option>
                    <option value="6201500">
                      6201500 - desenvolvimento de programas de computador sob encomenda
                    </option>
                    <option value="6201501">
                      6201501 - desenvolvimento de programas de computador sob encomenda
                    </option>
                    <option value="6201502">6201502 - web design</option>
                    <option value="6202100">6202100 - atividade desconhecida</option>
                    <option value="6202300">
                      6202300 - desenvolvimento e licenciamento de programas de computador customizaveis
                    </option>
                    <option value="6203100">
                      6203100 - desenvolvimento e licenciamento de programas de computador nao-customizaveis
                    </option>
                    <option value="6204000">6204000 - consultoria em tecnologia da informacao</option>
                    <option value="6209100">
                      6209100 - suporte tecnico, Manutencao e outros Servicos em tecnologia da informacao
                    </option>
                    <option value="6311900">
                      6311900 - tratamento de dados, provedores de Servicos de aplicacao e Servicos de
                      hospedagem na internet
                    </option>
                    <option value="6319400">
                      6319400 - portais, provedores de conteudo e outros Servicos de informacao na internet
                    </option>
                    <option value="6391700">6391700 - agencias de noticias</option>
                    <option value="6399200">
                      6399200 - outras Atividades de prestacao de Servicos de informacao nao especificadas
                      anteriormente
                    </option>
                    <option value="6410700">6410700 - banco central</option>
                    <option value="6421200">6421200 - bancos comerciais</option>
                    <option value="6422100">6422100 - bancos multiplos, com carteira comercial</option>
                    <option value="6423900">6423900 - caixas economicas</option>
                    <option value="6424701">6424701 - bancos cooperativos</option>
                    <option value="6424702">6424702 - cooperativas centrais de credito</option>
                    <option value="6424703">6424703 - cooperativas de credito mutuo</option>
                    <option value="6424704">6424704 - cooperativas de credito rural</option>
                    <option value="6431000">6431000 - bancos multiplos, sem carteira comercial</option>
                    <option value="6432800">6432800 - bancos de investimento</option>
                    <option value="6433600">6433600 - bancos de desenvolvimento</option>
                    <option value="6434400">6434400 - agencias de fomento</option>
                    <option value="6435201">6435201 - sociedades de credito imobiliario</option>
                    <option value="6435202">6435202 - associacoes de poupanca e emprestimo</option>
                    <option value="6435203">6435203 - companhias hipotecarias</option>
                    <option value="6436100">
                      6436100 - sociedades de credito, financiamento e investimento - financeiras
                    </option>
                    <option value="6437900">6437900 - sociedades de credito ao microempreendedor</option>
                    <option value="6438701">6438701 - bancos de cambio</option>
                    <option value="6438799">
                      6438799 - outras instituicoes de intermediacao nao-monetaria nao especificadas
                      anteriormente
                    </option>
                    <option value="6440900">6440900 - arrendamento mercantil</option>
                    <option value="6450600">6450600 - sociedades de capitalizacao</option>
                    <option value="6461100">6461100 - holdings de instituicoes financeiras</option>
                    <option value="6462000">6462000 - holdings de instituicoes nao-financeiras</option>
                    <option value="6463800">
                      6463800 - outras sociedades de participacao, exceto holdings
                    </option>
                    <option value="6470101">
                      6470101 - fundos de investimento, exceto previdenciarios e imobiliarios
                    </option>
                    <option value="6470102">6470102 - fundos de investimento previdenciarios</option>
                    <option value="6470103">6470103 - fundos de investimento imobiliarios</option>
                    <option value="6491300">6491300 - sociedades de fomento mercantil - factoring</option>
                    <option value="6492100">6492100 - securitizacao de creditos</option>
                    <option value="6493000">
                      6493000 - administracao de consorcios para aquisicao de bens e direitos
                    </option>
                    <option value="6499901">6499901 - clubes de investimento</option>
                    <option value="6499902">6499902 - sociedades de investimento</option>
                    <option value="6499903">6499903 - fundo garantidor de credito</option>
                    <option value="6499904">6499904 - caixas de financiamento de corporacoes</option>
                    <option value="6499905">6499905 - concessao de credito pelas oscip</option>
                    <option value="6499999">
                      6499999 - outras Atividades de Servicos financeiros nao especificadas anteriormente
                    </option>
                    <option value="6511101">6511101 - sociedade seguradora de seguros vida</option>
                    <option value="6511102">6511102 - planos de auxilio-funeral</option>
                    <option value="6512000">6512000 - sociedade seguradora de seguros nao vida</option>
                    <option value="6520100">6520100 - sociedade seguradora de seguros saude</option>
                    <option value="6530800">6530800 - resseguros</option>
                    <option value="6541300">6541300 - previdencia complementar fechada</option>
                    <option value="6542100">6542100 - previdencia complementar aberta</option>
                    <option value="6550200">6550200 - planos de saude</option>
                    <option value="6611801">6611801 - bolsa de valores</option>
                    <option value="6611802">6611802 - bolsa de mercadorias</option>
                    <option value="6611803">6611803 - bolsa de mercadorias e futuros</option>
                    <option value="6611804">6611804 - administracao de mercados de balcao organizados</option>
                    <option value="6612601">6612601 - corretoras de titulos e valores mobiliarios</option>
                    <option value="6612602">6612602 - distribuidoras de titulos e valores mobiliarios</option>
                    <option value="6612603">6612603 - corretoras de cambio</option>
                    <option value="6612604">6612604 - corretoras de contratos de mercadorias</option>
                    <option value="6612605">
                      6612605 - agentes de investimentos em aplicacoes financeiras
                    </option>
                    <option value="6613400">6613400 - administracao de cartoes de credito</option>
                    <option value="6619301">6619301 - Servicos de liquidacao e custodia</option>
                    <option value="6619302">6619302 - correspondentes de instituicoes financeiras</option>
                    <option value="6619303">6619303 - representacoes de bancos estrangeiros</option>
                    <option value="6619304">6619304 - caixas eletronicos</option>
                    <option value="6619305">6619305 - operadoras de cartoes de debito</option>
                    <option value="6619399">
                      6619399 - outras Atividades auxiliares dos Servicos financeiros nao especificadas
                      anteriormente
                    </option>
                    <option value="6621501">6621501 - peritos e avaliadores de seguros</option>
                    <option value="6621502">6621502 - auditoria e consultoria atuarial</option>
                    <option value="6622300">
                      6622300 - corretores e agentes de seguros, de planos de previdencia complementar e de
                      saude
                    </option>
                    <option value="6629100">
                      6629100 - Atividades auxiliares dos seguros, da previdencia complementar e dos planos de
                      saude nao especificadas anteriormente
                    </option>
                    <option value="6630400">
                      6630400 - Atividades de administracao de fundos por contrato ou comissao
                    </option>
                    <option value="6810201">6810201 - compra e venda de imoveis proprios</option>
                    <option value="6810202">6810202 - aluguel de imoveis proprios</option>
                    <option value="6810203">6810203 - loteamento de imoveis proprios</option>
                    <option value="6821801">
                      6821801 - corretagem na compra e venda e avaliacao de imoveis
                    </option>
                    <option value="6821802">6821802 - corretagem no aluguel de imoveis</option>
                    <option value="6822600">
                      6822600 - Gestao e administracao da propriedade imobiliaria
                    </option>
                    <option value="6911701">6911701 - Servicos advocaticios</option>
                    <option value="6911702">6911702 - Atividades auxiliares da justica</option>
                    <option value="6911703">6911703 - agente de propriedade industrial</option>
                    <option value="6912500">6912500 - cartorios</option>
                    <option value="6920601">6920601 - Atividades de contabilidade</option>
                    <option value="6920602">
                      6920602 - Atividades de consultoria e auditoria contabil e tributaria
                    </option>
                    <option value="7020400">
                      7020400 - Atividades de consultoria em Gestao empresarial, exceto consultoria tecnica
                      especifica
                    </option>
                    <option value="7111100">7111100 - Servicos de arquitetura</option>
                    <option value="7112000">7112000 - Servicos de engenharia</option>
                    <option value="7119701">7119701 - Servicos de cartografia, topografia e geodesia</option>
                    <option value="7119702">7119702 - Atividades de estudos geologicos</option>
                    <option value="7119703">
                      7119703 - Servicos de desenho tecnico relacionados a arquitetura e engenharia
                    </option>
                    <option value="7119704">
                      7119704 - Servicos de pericia tecnica relacionados a seguranca do trabalho
                    </option>
                    <option value="7119799">
                      7119799 - Atividades tecnicas relacionadas a engenharia e arquitetura nao especificadas
                      anteriormente
                    </option>
                    <option value="7120100">7120100 - testes e analises tecnicas</option>
                    <option value="7210000">
                      7210000 - pesquisa e desenvolvimento experimental em ciencias fisicas e naturais
                    </option>
                    <option value="7220700">
                      7220700 - pesquisa e desenvolvimento experimental em ciencias sociais e humanas
                    </option>
                    <option value="7311400">7311400 - agencias de publicidade</option>
                    <option value="7312200">
                      7312200 - agenciamento de espacos para publicidade, exceto em veiculos de comunicacao
                    </option>
                    <option value="7319001">7319001 - criacao de estandes para feiras e exposicoes</option>
                    <option value="7319002">7319002 - promocao de vendas</option>
                    <option value="7319003">7319003 - marketing direto</option>
                    <option value="7319004">7319004 - consultoria em publicidade</option>
                    <option value="7319099">
                      7319099 - outras Atividades de publicidade nao especificadas anteriormente
                    </option>
                    <option value="7320300">7320300 - pesquisas de mercado e de opiniao publica</option>
                    <option value="7410201">7410201 - design</option>
                    <option value="7410202">7410202 - design de interiores</option>
                    <option value="7410203">7410203 - design de produto</option>
                    <option value="7410299">
                      7410299 - Atividades de design nao especificadas anteriormente
                    </option>
                    <option value="7420001">
                      7420001 - Atividades de Producao de fotografias, exceto aerea e submarina
                    </option>
                    <option value="7420002">
                      7420002 - Atividades de Producao de fotografias aereas e submarinas
                    </option>
                    <option value="7420003">7420003 - laboratorios fotograficos</option>
                    <option value="7420004">7420004 - filmagem de festas e eventos</option>
                    <option value="7420005">7420005 - Servicos de microfilmagem</option>
                    <option value="7490101">7490101 - Servicos de traducao, interpretacao e similares</option>
                    <option value="7490102">7490102 - escafandria e mergulho</option>
                    <option value="7490103">
                      7490103 - Servicos de agronomia e de consultoria as Atividades agricolas e pecuarias
                    </option>
                    <option value="7490104">
                      7490104 - Atividades de intermediacao e agenciamento de Servicos e negocios em geral,
                      exceto imobiliarios
                    </option>
                    <option value="7490105">
                      7490105 - agenciamento de profissionais para Atividades esportivas, culturais e
                      artisticas
                    </option>
                    <option value="7490199">
                      7490199 - outras Atividades profissionais, cientificas e tecnicas nao especificadas
                      anteriormente
                    </option>
                    <option value="7500100">7500100 - Atividades veterinarias</option>
                    <option value="7711000">7711000 - locacao de automoveis sem condutor</option>
                    <option value="7719501">
                      7719501 - locacao de embarcacoes sem tripulacao, exceto para fins recreativos
                    </option>
                    <option value="7719502">7719502 - locacao de aeronaves sem tripulacao</option>
                    <option value="7719599">
                      7719599 - locacao de outros meios de transporte nao especificados anteriormente, sem
                      condutor
                    </option>
                    <option value="7721700">
                      7721700 - aluguel de equipamentos recreativos e esportivos
                    </option>
                    <option value="7722500">7722500 - aluguel de fitas de video, dvds e similares</option>
                    <option value="7723300">
                      7723300 - aluguel de objetos do vestuario, joias e acessorios
                    </option>
                    <option value="7729201">7729201 - aluguel de aparelhos de jogos eletronicos</option>
                    <option value="7729202">
                      7729202 - aluguel de moveis, utensilios e aparelhos de uso domestico e pessoal;
                      instrumentos musicais
                    </option>
                    <option value="7729203">7729203 - aluguel de material medico</option>
                    <option value="7729299">
                      7729299 - aluguel de outros objetos pessoais e domesticos nao especificados
                      anteriormente
                    </option>
                    <option value="7731400">
                      7731400 - aluguel de maquinas e equipamentos agricolas sem operador
                    </option>
                    <option value="7732201">
                      7732201 - aluguel de maquinas e equipamentos para Construcao sem operador, exceto
                      andaimes
                    </option>
                    <option value="7732202">7732202 - aluguel de andaimes</option>
                    <option value="7733100">
                      7733100 - aluguel de maquinas e equipamentos para escritorios
                    </option>
                    <option value="7739001">
                      7739001 - aluguel de maquinas e equipamentos para Extracao de minerios e petroleo, sem
                      operador
                    </option>
                    <option value="7739002">
                      7739002 - aluguel de equipamentos cientificos, medicos e hospitalares, sem operador
                    </option>
                    <option value="7739003">
                      7739003 - aluguel de palcos, coberturas e outras estruturas de uso temporario, exceto
                      andaimes
                    </option>
                    <option value="7739099">
                      7739099 - aluguel de outras maquinas e equipamentos comerciais e industriais nao
                      especificados anteriormente, sem operador
                    </option>
                    <option value="7740300">7740300 - Gestao de ativos intangiveis nao-financeiros</option>
                    <option value="7810800">7810800 - selecao e agenciamento de mao-de-obra</option>
                    <option value="7820500">7820500 - locacao de mao-de-obra temporaria</option>
                    <option value="7830200">
                      7830200 - fornecimento e Gestao de recursos humanos para terceiros
                    </option>
                    <option value="7911200">7911200 - agencias de viagens</option>
                    <option value="7912100">7912100 - operadores turisticos</option>
                    <option value="7990200">
                      7990200 - Servicos de reservas e outros Servicos de turismo nao especificados
                      anteriormente
                    </option>
                    <option value="8011101">8011101 - Atividades de vigilancia e seguranca privada</option>
                    <option value="8011102">8011102 - Servicos de adestramento de caes de guarda</option>
                    <option value="8012900">8012900 - Atividades de transporte de valores</option>
                    <option value="8020000">
                      8020000 - Atividades de monitoramento de sistemas de seguranca
                    </option>
                    <option value="8020001">
                      8020001 - Atividades de monitoramento de sistemas de seguranca eletronico
                    </option>
                    <option value="8020002">8020002 - outras Atividades de Servicos de seguranca</option>
                    <option value="8030700">8030700 - Atividades de investigacao particular</option>
                    <option value="8111700">
                      8111700 - Servicos combinados para apoio a edificios, exceto condominios prediais
                    </option>
                    <option value="8112500">8112500 - condominios prediais</option>
                    <option value="8121400">8121400 - limpeza em predios e em domicilios</option>
                    <option value="8122200">8122200 - imunizacao e controle de pragas urbanas</option>
                    <option value="8129000">
                      8129000 - Atividades de limpeza nao especificadas anteriormente
                    </option>
                    <option value="8130300">8130300 - Atividades paisagisticas</option>
                    <option value="8211300">
                      8211300 - Servicos combinados de escritorio e apoio administrativo
                    </option>
                    <option value="8219901">8219901 - fotocopias</option>
                    <option value="8219999">
                      8219999 - Preparacao de documentos e Servicos especializados de apoio administrativo nao
                      especificados anteriormente
                    </option>
                    <option value="8220200">8220200 - Atividades de teleatendimento</option>
                    <option value="8230001">
                      8230001 - Servicos de organizacao de feiras, congressos, exposicoes e festas
                    </option>
                    <option value="8230002">8230002 - casas de festas e eventos</option>
                    <option value="8291100">
                      8291100 - Atividades de cobrancas e informacoes cadastrais
                    </option>
                    <option value="8292000">8292000 - envasamento e empacotamento sob contrato</option>
                    <option value="8299701">
                      8299701 - medicao de consumo de energia eletrica, gas e agua
                    </option>
                    <option value="8299702">
                      8299702 - emissao de vales-alimentacao, vales-transporte e similares
                    </option>
                    <option value="8299703">
                      8299703 - Servicos de gravacao de carimbos, exceto confeccao
                    </option>
                    <option value="8299704">8299704 - leiloeiros independentes</option>
                    <option value="8299705">8299705 - Servicos de levantamento de fundos sob contrato</option>
                    <option value="8299706">8299706 - casas lotericas</option>
                    <option value="8299707">8299707 - salas de acesso a internet</option>
                    <option value="8299799">
                      8299799 - outras Atividades de Servicos prestados principalmente as empresas nao
                      especificadas anteriormente
                    </option>
                    <option value="8411600">8411600 - administracao publica em geral</option>
                    <option value="8412400">
                      8412400 - regulacao das Atividades de saude, educacao, Servicos culturais e outros
                      Servicos sociais
                    </option>
                    <option value="8413200">8413200 - regulacao das Atividades economicas</option>
                    <option value="8421300">8421300 - relacoes exteriores</option>
                    <option value="8422100">8422100 - defesa</option>
                    <option value="8423000">8423000 - justica</option>
                    <option value="8424800">8424800 - seguranca e ordem publica</option>
                    <option value="8425600">8425600 - defesa civil</option>
                    <option value="8430200">8430200 - seguridade social obrigatoria</option>
                    <option value="8511200">8511200 - educacao infantil - creche</option>
                    <option value="8512100">8512100 - educacao infantil - pre-escola</option>
                    <option value="8513900">8513900 - ensino fundamental</option>
                    <option value="8520100">8520100 - ensino medio</option>
                    <option value="8531700">8531700 - educacao superior - graduacao</option>
                    <option value="8532500">8532500 - educacao superior - graduacao e pos-graduacao</option>
                    <option value="8533300">8533300 - educacao superior - pos-graduacao e extensao</option>
                    <option value="8541400">8541400 - educacao profissional de nivel tecnico</option>
                    <option value="8542200">8542200 - educacao profissional de nivel tecnologico</option>
                    <option value="8550301">8550301 - administracao de caixas escolares</option>
                    <option value="8550302">
                      8550302 - Atividades de apoio a educacao, exceto caixas escolares
                    </option>
                    <option value="8591100">8591100 - ensino de esportes</option>
                    <option value="8592901">8592901 - ensino de danca</option>
                    <option value="8592902">8592902 - ensino de artes cenicas, exceto danca</option>
                    <option value="8592903">8592903 - ensino de musica</option>
                    <option value="8592999">
                      8592999 - ensino de arte e cultura nao especificado anteriormente
                    </option>
                    <option value="8593700">8593700 - ensino de idiomas</option>
                    <option value="8599601">8599601 - formacao de condutores</option>
                    <option value="8599602">8599602 - cursos de pilotagem</option>
                    <option value="8599603">8599603 - treinamento em informatica</option>
                    <option value="8599604">
                      8599604 - treinamento em desenvolvimento profissional e gerencial
                    </option>
                    <option value="8599605">8599605 - cursos preparatorios para concursos</option>
                    <option value="8599699">
                      8599699 - outras Atividades de ensino nao especificadas anteriormente
                    </option>
                    <option value="8610101">
                      8610101 - Atividades de atendimento hospitalar, exceto pronto-socorro e unidades para
                      atendimento a urgencias
                    </option>
                    <option value="8610102">
                      8610102 - Atividades de atendimento em pronto-socorro e unidades hospitalares para
                      atendimento a urgencias
                    </option>
                    <option value="8621601">8621601 - uti movel</option>
                    <option value="8621602">
                      8621602 - Servicos moveis de atendimento a urgencias, exceto por uti movel
                    </option>
                    <option value="8622400">
                      8622400 - Servicos de remocao de pacientes, exceto os Servicos moveis de atendimento a
                      urgencias
                    </option>
                    <option value="8630501">
                      8630501 - atividade medica ambulatorial com recursos para realizacao de procedimentos
                      cirurgicos
                    </option>
                    <option value="8630502">
                      8630502 - atividade medica ambulatorial com recursos para realizacao de exames
                      complementares
                    </option>
                    <option value="8630503">
                      8630503 - atividade medica ambulatorial restrita a consultas
                    </option>
                    <option value="8630504">8630504 - atividade odontologica</option>
                    <option value="8630506">8630506 - Servicos de vacinacao e imunizacao humana</option>
                    <option value="8630507">8630507 - Atividades de ReProducao humana assistida</option>
                    <option value="8630599">
                      8630599 - Atividades de atencao ambulatorial nao especificadas anteriormente
                    </option>
                    <option value="8640201">
                      8640201 - laboratorios de anatomia patologica e citologica
                    </option>
                    <option value="8640202">8640202 - laboratorios clinicos</option>
                    <option value="8640203">8640203 - Servicos de dialise e nefrologia</option>
                    <option value="8640204">8640204 - Servicos de tomografia</option>
                    <option value="8640205">
                      8640205 - Servicos de diagnostico por imagem com uso de radiacao ionizante, exceto
                      tomografia
                    </option>
                    <option value="8640206">8640206 - Servicos de ressonancia magnetica</option>
                    <option value="8640207">
                      8640207 - Servicos de diagnostico por imagem sem uso de radiacao ionizante, exceto
                      ressonancia magnetica
                    </option>
                    <option value="8640208">
                      8640208 - Servicos de diagnostico por registro grafico - ecg, eeg e outros exames
                      analogos
                    </option>
                    <option value="8640209">
                      8640209 - Servicos de diagnostico por metodos opticos - endoscopia e outros exames
                      analogos
                    </option>
                    <option value="8640210">8640210 - Servicos de quimioterapia</option>
                    <option value="8640211">8640211 - Servicos de radioterapia</option>
                    <option value="8640212">8640212 - Servicos de hemoterapia</option>
                    <option value="8640213">8640213 - Servicos de litotripcia</option>
                    <option value="8640214">8640214 - Servicos de bancos de celulas e tecidos humanos</option>
                    <option value="8640299">
                      8640299 - Atividades de Servicos de complementacao diagnostica e terapeutica nao
                      especificadas anteriormente
                    </option>
                    <option value="8650001">8650001 - Atividades de enfermagem</option>
                    <option value="8650002">8650002 - Atividades de profissionais da nutricao</option>
                    <option value="8650003">8650003 - Atividades de psicologia e psicanalise</option>
                    <option value="8650004">8650004 - Atividades de fisioterapia</option>
                    <option value="8650005">8650005 - Atividades de terapia ocupacional</option>
                    <option value="8650006">8650006 - Atividades de fonoaudiologia</option>
                    <option value="8650007">
                      8650007 - Atividades de terapia de nutricao enteral e parenteral
                    </option>
                    <option value="8650099">
                      8650099 - Atividades de profissionais da area de saude nao especificadas anteriormente
                    </option>
                    <option value="8660700">8660700 - Atividades de apoio a Gestao de saude</option>
                    <option value="8690901">
                      8690901 - Atividades de praticas integrativas e complementares em saude humana
                    </option>
                    <option value="8690902">8690902 - Atividades de banco de leite humano</option>
                    <option value="8690903">8690903 - Atividades de acupuntura</option>
                    <option value="8690904">8690904 - Atividades de podologia</option>
                    <option value="8690999">
                      8690999 - outras Atividades de atencao a saude humana nao especificadas anteriormente
                    </option>
                    <option value="8711501">8711501 - clinicas e residencias geriatricas</option>
                    <option value="8711502">8711502 - instituicoes de longa permanencia para idosos</option>
                    <option value="8711503">
                      8711503 - Atividades de assistencia a deficientes fisicos, imunodeprimidos e
                      convalescentes
                    </option>
                    <option value="8711504">
                      8711504 - centros de apoio a pacientes com cancer e com aids
                    </option>
                    <option value="8711505">
                      8711505 - condominios residenciais para idosos e deficientes fisicos
                    </option>
                    <option value="8712300">
                      8712300 - Atividades de fornecimento de infra-estrutura de apoio e assistencia a
                      paciente no domicilio
                    </option>
                    <option value="8720401">
                      8720401 - Atividades de centros de assistencia psicossocial
                    </option>
                    <option value="8720499">
                      8720499 - Atividades de assistencia psicossocial e a saude a portadores de disturbios
                      psiquicos, deficiencia mental e dependencia quimica e grupos similares nao especificadas
                      anteriormente
                    </option>
                    <option value="8730101">8730101 - orfanatos</option>
                    <option value="8730102">8730102 - albergues assistenciais</option>
                    <option value="8730199">
                      8730199 - Atividades de assistencia social prestadas em residencias coletivas e
                      particulares nao especificadas anteriormente
                    </option>
                    <option value="8800600">8800600 - Servicos de assistencia social sem alojamento</option>
                    <option value="9001901">9001901 - Producao teatral</option>
                    <option value="9001902">9001902 - Producao musical</option>
                    <option value="9001903">9001903 - Producao de espetaculos de danca</option>
                    <option value="9001904">
                      9001904 - Producao de espetaculos circenses, de marionetes e similares
                    </option>
                    <option value="9001905">
                      9001905 - Producao de espetaculos de rodeios, vaquejadas e similares
                    </option>
                    <option value="9001906">9001906 - Atividades de sonorizacao e de iluminacao</option>
                    <option value="9001999">
                      9001999 - artes cenicas, espetaculos e Atividades complementares nao especificadas
                      anteriormente
                    </option>
                    <option value="9002701">
                      9002701 - Atividades de artistas plasticos, jornalistas independentes e escritores
                    </option>
                    <option value="9002702">9002702 - restauracao de Obras-de-arte</option>
                    <option value="9003500">
                      9003500 - Gestao de espacos para artes cenicas, espetaculos e outras Atividades
                      artisticas
                    </option>
                    <option value="9101500">9101500 - Atividades de bibliotecas e arquivos</option>
                    <option value="9102301">
                      9102301 - Atividades de museus e de exploracao de lugares e predios historicos e
                      atracoes similares
                    </option>
                    <option value="9102302">
                      9102302 - restauracao e conservacao de lugares e predios historicos
                    </option>
                    <option value="9103100">
                      9103100 - Atividades de jardins botanicos, zoologicos, parques nacionais, reservas
                      ecologicas e areas de protecao ambiental
                    </option>
                    <option value="9200301">9200301 - casas de bingo</option>
                    <option value="9200302">9200302 - exploracao de apostas em corridas de cavalos</option>
                    <option value="9200399">
                      9200399 - exploracao de jogos de azar e apostas nao especificados anteriormente
                    </option>
                    <option value="9311500">9311500 - Gestao de instalacoes de esportes</option>
                    <option value="9312300">9312300 - clubes sociais, esportivos e similares</option>
                    <option value="9313100">9313100 - Atividades de condicionamento fisico</option>
                    <option value="9319101">9319101 - Producao e promocao de eventos esportivos</option>
                    <option value="9319199">
                      9319199 - outras Atividades esportivas nao especificadas anteriormente
                    </option>
                    <option value="9321200">9321200 - parques de diversao e parques tematicos</option>
                    <option value="9329801">
                      9329801 - discotecas, danceterias, saloes de danca e similares
                    </option>
                    <option value="9329802">9329802 - exploracao de boliches</option>
                    <option value="9329803">
                      9329803 - exploracao de jogos de sinuca, bilhar e similares
                    </option>
                    <option value="9329804">9329804 - exploracao de jogos eletronicos recreativos</option>
                    <option value="9329899">
                      9329899 - outras Atividades de recreacao e lazer nao especificadas anteriormente
                    </option>
                    <option value="9411100">
                      9411100 - Atividades de organizacoes associativas patronais e empresariais
                    </option>
                    <option value="9412001">9412001 - Atividades de fiscalizacao profissional</option>
                    <option value="9412099">9412099 - outras Atividades associativas profissionais</option>
                    <option value="9420100">9420100 - Atividades de organizacoes sindicais</option>
                    <option value="9430800">
                      9430800 - Atividades de associacoes de defesa de direitos sociais
                    </option>
                    <option value="9491000">
                      9491000 - Atividades de organizacoes religiosas ou filosoficas
                    </option>
                    <option value="9492800">9492800 - Atividades de organizacoes politicas</option>
                    <option value="9493600">
                      9493600 - Atividades de organizacoes associativas ligadas a cultura e a arte
                    </option>
                    <option value="9499500">
                      9499500 - Atividades associativas nao especificadas anteriormente
                    </option>
                    <option value="9511800">
                      9511800 - reparacao e Manutencao de computadores e de equipamentos perifericos
                    </option>
                    <option value="9512600">
                      9512600 - reparacao e Manutencao de equipamentos de comunicacao
                    </option>
                    <option value="9521500">
                      9521500 - reparacao e Manutencao de equipamentos eletroeletronicos de uso pessoal e
                      domestico
                    </option>
                    <option value="9529101">
                      9529101 - reparacao de calcados, bolsas e artigos de viagem
                    </option>
                    <option value="9529102">9529102 - chaveiros</option>
                    <option value="9529103">9529103 - reparacao de relogios</option>
                    <option value="9529104">
                      9529104 - reparacao de bicicletas, triciclos e outros veiculos nao-motorizados
                    </option>
                    <option value="9529105">9529105 - reparacao de artigos do mobiliario</option>
                    <option value="9529106">9529106 - reparacao de joias</option>
                    <option value="9529199">
                      9529199 - reparacao e Manutencao de outros objetos e equipamentos pessoais e domesticos
                      nao especificados anteriormente
                    </option>
                    <option value="9601701">9601701 - lavanderias</option>
                    <option value="9601702">9601702 - tinturarias</option>
                    <option value="9601703">9601703 - toalheiros</option>
                    <option value="9602501">9602501 - cabeleireiros, manicure e pedicure</option>
                    <option value="9602502">
                      9602502 - Atividades de estetica e outros Servicos de cuidados com a beleza
                    </option>
                    <option value="9603301">9603301 - Gestao e Manutencao de cemiterios</option>
                    <option value="9603302">9603302 - Servicos de cremacao</option>
                    <option value="9603303">9603303 - Servicos de sepultamento</option>
                    <option value="9603304">9603304 - Servicos de funerarias</option>
                    <option value="9603305">9603305 - Servicos de somatoconservacao</option>
                    <option value="9603399">
                      9603399 - Atividades funerarias e Servicos relacionados nao especificados anteriormente
                    </option>
                    <option value="9609201">9609201 - clinicas de estetica e similares</option>
                    <option value="9609202">9609202 - agencias matrimoniais</option>
                    <option value="9609203">9609203 - alojamento, higiene e embelezamento de animais</option>
                    <option value="9609204">
                      9609204 - exploracao de maquinas de Servicos pessoais acionadas por moeda
                    </option>
                    <option value="9609205">9609205 - Atividades de sauna e banhos</option>
                    <option value="9609206">9609206 - Servicos de tatuagem e colocacao de piercing</option>
                    <option value="9609207">9609207 - alojamento de animais domesticos</option>
                    <option value="9609208">9609208 - higiene e embelezamento de animais domesticos</option>
                    <option value="9609299">
                      9609299 - outras Atividades de Servicos pessoais nao especificadas anteriormente
                    </option>
                    <option value="9700500">9700500 - Servicos domesticos</option>
                    <option value="9900800">
                      9900800 - organismos internacionais e outras instituicoes extraterritoriais
                    </option>
                  </Select>
                </Stack>
                <Stack>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    mb="-2px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                  >
                    Natureza Jurídica
                  </FormLabel>
                  <Select
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    type="autor"
                    placeholder="Selecione"
                    mb="24px"
                    fontWeight="500"
                    size="md"
                    name="natureza_juridica"
                    value={natureza_juridica}
                    onChange={handleQueryChange}
                  >
                    <option value="1015">1015 - orgao publico do poder executivo federal</option>
                    <option value="1023">
                      1023 - orgao publico do poder executivo estadual ou do distrito federal
                    </option>
                    <option value="1031">1031 - orgao publico do poder executivo municipal</option>
                    <option value="1040">1040 - orgao publico do poder legislativo federal</option>
                    <option value="1058">
                      1058 - orgao publico do poder legislativo estadual ou do distrito federal
                    </option>
                    <option value="1066">1066 - orgao publico do poder legislativo municipal</option>
                    <option value="1074">1074 - orgao publico do poder judiciario federal</option>
                    <option value="1082">1082 - orgao publico do poder judiciario estadual</option>
                    <option value="1104">1104 - autarquia federal</option>
                    <option value="1112">1112 - autarquia estadual ou do distrito federal</option>
                    <option value="1120">1120 - autarquia municipal</option>
                    <option value="1139">1139 - fundacao publica de direito publico federal</option>
                    <option value="1147">1147 - fundacao pub. de direito pub. est. ou do df</option>
                    <option value="1155">1155 - fundacao publica de direito publico municipal</option>
                    <option value="1163">1163 - orgao publico autonomo federal</option>
                    <option value="1171">
                      1171 - orgao publico autonomo estadual ou do distrito federal
                    </option>
                    <option value="1180">1180 - orgao publico autonomo municipal</option>
                    <option value="1198">1198 - comissao polinacional</option>
                    <option value="1210">1210 - consorcio pub.de direito pub. (ass. pub.)</option>
                    <option value="1228">1228 - consorcio publico de direito privado</option>
                    <option value="1236">1236 - estado ou distrito federal</option>
                    <option value="1244">1244 - municipio</option>
                    <option value="1252">1252 - fundacao publica de direito privado federal</option>
                    <option value="1260">
                      1260 - fundacao publica de direito privado estadual ou do distrito federal
                    </option>
                    <option value="1279">1279 - fundacao publica de direito privado municipal</option>
                    <option value="1287">1287 - fundo publico da administracao indireta federal</option>
                    <option value="1295">
                      1295 - fundo publico da administracao indireta estadual ou do distrito federal
                    </option>
                    <option value="1309">1309 - fundo publico da administracao indireta municipal</option>
                    <option value="1317">1317 - fundo publico da administracao direta federal</option>
                    <option value="1325">
                      1325 - fundo publico da administracao direta estadual ou do distrito federal
                    </option>
                    <option value="1333">1333 - fundo publico da administracao direta municipal</option>
                    <option value="1341">1341 - uniao</option>
                    <option value="2011">2011 - empresa publica</option>
                    <option value="2038">2038 - sociedade de economia mista</option>
                    <option value="2046">2046 - sociedade anonima aberta</option>
                    <option value="2054">2054 - sociedade anonima fechada</option>
                    <option value="2062">2062 - sociedade empresaria limitada</option>
                    <option value="2070">2070 - sociedade empresaria em nome coletivo</option>
                    <option value="2089">2089 - sociedade empresaria em comandita simples</option>
                    <option value="2097">2097 - sociedade empresaria em comandita por acoes</option>
                    <option value="2100">2100 - sociedade mercantil de capital e industria</option>
                    <option value="2127">2127 - sociedade em conta de participacao</option>
                    <option value="2135">2135 - empresario (individual)</option>
                    <option value="2143">2143 - cooperativa</option>
                    <option value="2151">2151 - consorcio de sociedades</option>
                    <option value="2160">2160 - grupo de sociedades</option>
                    <option value="2178">2178 - estabelecimento, no brasil, de sociedade estrangeira</option>
                    <option value="2216">2216 - empresa domiciliada no exterior</option>
                    <option value="2224">2224 - clube/fundo de investimento</option>
                    <option value="2232">2232 - sociedade simples pura</option>
                    <option value="2240">2240 - sociedade simples limitada</option>
                    <option value="2259">2259 - sociedade simples em nome coletivo</option>
                    <option value="2267">2267 - sociedade simples em comandita simples</option>
                    <option value="2275">2275 - empresa binacional</option>
                    <option value="2283">2283 - consorcio de empregadores</option>
                    <option value="2291">2291 - consorcio simples</option>
                    <option value="2305">
                      2305 - empresa individual de resp.limitada (de natureza empresaria)
                    </option>
                    <option value="2313">
                      2313 - empresa individual de responsabilidade limitada (de natureza simples)
                    </option>
                    <option value="2321">2321 - natureza juridica invalida</option>
                    <option value="2330">2330 - cooperativas de consumo</option>
                    <option value="2348">2348 - empresa simples de inovacao</option>
                    <option value="3034">3034 - servico notarial e registral (cartorio)</option>
                    <option value="3069">3069 - fundacao privada</option>
                    <option value="3077">3077 - servico social autonomo</option>
                    <option value="3085">3085 - condominio edilicio</option>
                    <option value="3107">3107 - comissao de conciliacao previa</option>
                    <option value="3115">3115 - entidade de mediacao e arbitragem</option>
                    <option value="3131">3131 - entidade sindical</option>
                    <option value="3204">
                      3204 - estabelecimento, no brasil, de fundacao ou associacao estrangeiras
                    </option>
                    <option value="3212">3212 - fundacao ou associacao domiciliada no exterior</option>
                    <option value="3220">3220 - organizacao religiosa</option>
                    <option value="3239">3239 - comunidade indigena</option>
                    <option value="3247">3247 - fundo privado</option>
                    <option value="3255">3255 - orgao de direcao nacional de partido politico</option>
                    <option value="3263">3263 - orgao de direcao regional de partido politico</option>
                    <option value="3271">3271 - orgao de direcao local de partido politico</option>
                    <option value="3280">3280 - comite financeiro de partido politico</option>
                    <option value="3298">3298 - frente plebiscitaria ou referendaria</option>
                    <option value="3301">3301 - organizacao social (os)</option>
                    <option value="3328">
                      3328 - plano de beneficios de previdencia complementar fechada
                    </option>
                    <option value="3999">3999 - associacao privada</option>
                    <option value="4014">4014 - empresa individual imobiliaria</option>
                    <option value="4090">4090 - candidato a cargo politico eletivo</option>
                    <option value="4120">4120 - produtor rural (pessoa fisica)</option>
                    <option value="5010">5010 - organizacao internacional</option>
                    <option value="5029">5029 - representacao diplomatica estrangeira</option>
                    <option value="5037">5037 - outras instituicoes extraterritoriais</option>
                    <option value="8885">8885 - natureza juridica nao informada</option>
                  </Select>
                </Stack>

                <Stack w="100%">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    mb="-2px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                  >
                    Situação Cadastral
                  </FormLabel>
                  <Select
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    type="autor"
                    placeholder="Selecione"
                    mb="24px"
                    fontWeight="500"
                    size="md"
                    name="situacao_cadastral"
                    value={situacao_cadastral}
                    defaultValue={'ATIVA'}
                    onChange={handleQueryChange}
                  >
                    <option value="ATIVA">Ativa</option>
                    <option value="BAIXADA">Baixada</option>
                    <option value="INAPTA">Inapta</option>
                    <option value="SUSPENSA">Suspensa</option>
                    <option value="NULA">Nula</option>
                  </Select>
                </Stack>

                <Stack>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    mb="-2px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                  >
                    Data de Abertura
                  </FormLabel>
                  <Flex spacing={2} direction="row" my={3} justifyContent="space-between">
                    <Input
                      value={formData.data_abertura_lte}
                      name="data_abertura_lte"
                      onChange={handleChange}
                      type="date"
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="De"
                      fontWeight="500"
                      size="md"
                      w="49%"
                    />
                    <Input
                      value={formData.data_abertura_gte}
                      name="data_abertura_gte"
                      onChange={handleChange}
                      type="date"
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="Até"
                      fontWeight="500"
                      size="md"
                      w="49%"
                    />
                  </Flex>
                </Stack>
                <Stack>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    mb="-2px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                  >
                    Capital Social
                  </FormLabel>
                  <Flex spacing={2} direction="row" my={3} justifyContent="space-between">
                    <Input
                      value={formData.capital_social_lte}
                      name="capital_social_lte"
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="De"
                      fontWeight="500"
                      size="md"
                      w="49%"
                    />
                    <Input
                      value={formData.capital_social_gte}
                      name="capital_social_gte"
                      onChange={handleChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="Até"
                      fontWeight="500"
                      size="md"
                      w="49%"
                    />
                  </Flex>
                </Stack>
                <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={{ base: '20px', xl: '20px' }}>
                  <Stack>
                    <FormLabel
                      display="flex"
                      ms="4px"
                      mb="-2px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                    >
                      UF
                    </FormLabel>
                    <Select
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      type="autor"
                      placeholder="Selecione"
                      fontWeight="500"
                      size="md"
                      name="uf"
                      value={uf}
                      onChange={handleQueryChange}
                    >
                      <option value="AC">AC</option>
                      <option value="AL">AL</option>
                      <option value="AP">AP</option>
                      <option value="AM">AM</option>
                      <option value="BA">BA</option>
                      <option value="CE">CE</option>
                      <option value="ES">ES</option>
                      <option value="GO">GO</option>
                      <option value="MA">MA</option>
                      <option value="MT">MT</option>
                      <option value="MS">MS</option>
                      <option value="MG">MG</option>
                      <option value="PA">PA</option>
                      <option value="PB">PB</option>
                      <option value="PR">PR</option>
                      <option value="PE">PE</option>
                      <option value="PI">PI</option>
                      <option value="RJ">RJ</option>
                      <option value="RN">RN</option>
                      <option value="RS">RS</option>
                      <option value="RO">RO</option>
                      <option value="RR">RR</option>
                      <option value="SC">SC</option>
                      <option value="SP">SP</option>
                      <option value="SE">SE</option>
                      <option value="TO">TO</option>
                    </Select>
                  </Stack>
                  <Stack>
                    <FormLabel
                      display="flex"
                      ms="4px"
                      mb="-2px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                    >
                      Município
                    </FormLabel>
                    <Input
                      value={municipio}
                      name="municipio"
                      onChange={handleQueryChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="Selecione"
                      fontWeight="500"
                      size="md"
                    />
                  </Stack>
                </SimpleGrid>

                <Flex
                  spacing={{ base: '20px', xl: '20px' }}
                  direction="row"
                  justifyContent="space-between"
                  mb="24px"
                >
                  <Stack w={'49%'}>
                    <FormLabel
                      display="flex"
                      ms="4px"
                      mb="-2px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                    >
                      CEP
                    </FormLabel>
                    <Input
                      value={cep}
                      name="cep"
                      onChange={handleQueryChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="00000000"
                      mb="24px"
                      fontWeight="500"
                      size="md"
                    />
                  </Stack>
                  <Stack w={'49%'}>
                    <FormLabel
                      display="flex"
                      ms="4px"
                      mb="-2px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                    >
                      DDD
                    </FormLabel>
                    <Input
                      value={formData.query.ddd}
                      name="ddd"
                      onChange={handleQueryChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="DDD"
                      mb="24px"
                      fontWeight="500"
                      size="md"
                    />
                  </Stack>
                </Flex>
                <Stack w="100%">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    mb="-2px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                  >
                    Razão Social
                  </FormLabel>
                  <Input
                    value={termo}
                    name="termo"
                    onChange={handleQueryChange}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    placeholder="Digite"
                    mb="24px"
                    fontWeight="500"
                    size="md"
                  />
                </Stack>
                <Stack w="100%">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    mb="-2px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                  >
                    Consulta CNPJ
                  </FormLabel>
                  <InputGroup>
                    <Input
                      value={cnpj}
                      name="cnpj"
                      onChange={handleCnpjConsultaChange}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      placeholder="Digite"
                      mb="24px"
                      fontWeight="500"
                      size="md"
                    />
                    <InputRightElement width="2.7rem">
                      <IconButton
                        colorScheme={formData.telefone1 ? 'green' : 'gray'}
                        aria-label="Call Segun"
                        size="sm"
                        onClick={() => setTrigger(cnpj)}
                        icon={<SearchIcon />}
                      />
                    </InputRightElement>
                  </InputGroup>
                </Stack>
              </SimpleGrid>
              {trigger && <ModalConsultaCnpj cnpj={trigger} />}
              <SimpleGrid
                // spacing={2} my={3}
                alignItems="center"
                columns={{ sm: 2, md: 4, xl: 10 }}
                justifyContent="center"
              >
                <Stack spacing={1} direction="row" my={1}>
                  <Switch
                    id="somente_matriz"
                    name="somente_matriz"
                    checked={formData.extras.somente_matriz}
                    onChange={handleExtraChange}
                  />
                  <FormLabel
                    display="flex"
                    ms="4px"
                    mb="-2px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    // w='120px'
                    htmlFor="somente_matriz"
                  >
                    Matriz
                  </FormLabel>
                </Stack>
                <Stack spacing={1} direction="row" my={1}>
                  <Switch
                    id="somente_filial"
                    name="somente_filial"
                    checked={formData.extras.somente_filial}
                    onChange={handleExtraChange}
                  />
                  <FormLabel
                    display="flex"
                    ms="4px"
                    mb="-2px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    // w='120px'
                    htmlFor="somente_filial"
                  >
                    Filial
                  </FormLabel>
                </Stack>
                <Stack spacing={1} direction="row" my={1}>
                  <Switch
                    id="somente_mei"
                    name="somente_mei"
                    checked={formData.extras.somente_mei}
                    onChange={handleExtraChange}
                  />
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                    // w='120px'
                    htmlFor="somente_mei"
                  >
                    MEIs
                  </FormLabel>
                </Stack>
                <Stack spacing={1} direction="row" my={1}>
                  <Switch
                    id="excluir_mei"
                    name="excluir_mei"
                    checked={formData.extras.excluir_mei}
                    defaultChecked={true}
                    onChange={handleExtraChange}
                  />

                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                    // w='120px'
                    htmlFor="excluir_mei"
                  >
                    Excluir MEI
                  </FormLabel>
                </Stack>
                <Stack spacing={1} direction="row" my={1}>
                  <Switch
                    id="com_email"
                    name="com_email"
                    checked={formData.extras.com_email}
                    defaultChecked={true}
                    onChange={handleExtraChange}
                  />
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                    // w='120px'
                    htmlFor="com_email"
                  >
                    Email
                  </FormLabel>
                </Stack>
                <Stack spacing={1} direction="row" my={1}>
                  <Switch
                    id="com_contato_telefonico"
                    name="com_contato_telefonico"
                    checked={formData.extras.com_contato_telefonico}
                    defaultChecked={true}
                    onChange={handleExtraChange}
                  />
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                    // w='120px'
                    htmlFor="com_contato_telefonico"
                  >
                    Telefone
                  </FormLabel>
                </Stack>
                <Stack spacing={1} direction="row" my={1}>
                  <Switch
                    id="somente_celular"
                    name="somente_celular"
                    checked={formData.extras.somente_celular}
                    onChange={handleExtraChange}
                  />
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                    // w='120px'
                    htmlFor="somente_celular"
                  >
                    Celular
                  </FormLabel>
                </Stack>
                <Stack spacing={2} direction="row" verticalAlign="center">
                  <Switch
                    id="somente_fixo"
                    name="somente_fixo"
                    checked={formData.extras.somente_fixo}
                    onChange={handleExtraChange}
                  />
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                    htmlFor="somente_fixo"
                  >
                    Fixo
                  </FormLabel>
                </Stack>
                {/* <Stack spacing={2} direction='row' verticalAlign='center'> */}
                <Button
                  type="submit"
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  h="50"
                  borderRadius="100px"
                >
                  Gerar lista
                </Button>
                <Box
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  h="50"
                  w="auto"
                  padding="15px 20px"
                  borderRadius="100px"
                  style={{
                    backgroundColor: '#3965ff',
                    color: '#ffffff',
                    cursor: 'pointer',
                    justifySelf: 'center'
                  }}
                  onClick={() => {
                    setFormData({
                      query: {
                        atividade_principal: [],
                        bairro: [],
                        cep: [],
                        ddd: [],
                        municipio: [],
                        natureza_juridica: [],
                        situacao_cadastral: 'ATIVA',
                        termo: [],
                        uf: []
                      },
                      page: 0,
                      extras: {
                        com_contato_telefonico: false,
                        com_email: false,
                        excluir_mei: false,
                        incluir_atividade_secundaria: false,
                        somente_celular: false,
                        somente_filial: false,
                        somente_fixo: false,
                        somente_matriz: false,
                        somente_mei: false
                      },
                      range_query: {
                        capital_social: {
                          lte: capital_social_lte,
                          gte: capital_social_gte
                        },
                        data_abertura: {
                          lte: data_abertura_lte,
                          gte: data_abertura_gte
                        }
                      }
                    })
                  }}
                >
                  Limpar
                </Box>
              </SimpleGrid>
            </FormControl>
          </form>
        </Card>

        {resultado && resultado.length > 0 && (
          <Card>
            <Stack>
              <Text>{}</Text>
              <TableConsulta tableData={resultado || []} />
            </Stack>

            <Button onClick={handleLoadMore} disabled={loading}>
              Carregar Mais
            </Button>
          </Card>
        )}
      </SimpleGrid>
    </Box>
  )
}
