import React, { useEffect, useMemo, useState } from 'react'
import {
  Avatar,
  Center,
  Flex,
  Link,
  Icon,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button
} from '@chakra-ui/react'
import { MdEditCalendar, MdCheck, MdClose } from 'react-icons/md'
import { app } from 'api/feathers'
import { getUserAuthenticationRole } from 'hooks/authService'

// Custom components
import Card from 'components/card/Card'
// import Menu from 'components/menu/MainMenu'
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import authHeader from 'hooks/authHeader'
import { FaPlus } from 'react-icons/fa'
const userRole = getUserAuthenticationRole()

// export default function CalendarCareTable() {
//   const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data })

//   return (
//     <table {...getTableProps()}>
//       <thead>
//         {headerGroups.map((headerGroup) => (
//           <tr {...headerGroup.getHeaderGroupProps()}>
//             {headerGroup.headers.map((column) => (
//               <th {...column.getHeaderProps()}>{column.render('Header')}</th>
//             ))}
//           </tr>
//         ))}
//       </thead>
//       <tbody {...getTableBodyProps()}>
//         {rows.map((row) => {
//           prepareRow(row)
//           return (
//             <tr {...row.getRowProps()}>
//               {row.cells.map((cell) => (
//                 <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
//               ))}
//             </tr>
//           )
//         })}
//       </tbody>
//     </table>
//   )
// }

// subject: '',
// description: '',
// account_id: account_id,
// priority: 5,
// tags: {
//   Atendimento: [],
//   Especies: [],
//   Idade: [],
//   'Status do Cliente': []
// },
// status: 'active',
// start_date: '',
// end_date: ''

// Definindo os dados da planilha
const testTableData = [
  {
    Ano: 'Filhotes ano',
    Mes: 'Janeiro',
    RotinaMensal: 'Verificação de peso e crescimento',
    QtdAnimais: 17
  },
  { Ano: 'Filhotes 1 ano', Mes: 'Fevereiro', RotinaMensal: 'Desparasitação e check-up', QtdAnimais: 18 },
  { Ano: 'Filhotes 1 ano', Mes: 'Março', RotinaMensal: 'Verificação de pele e pelagem', QtdAnimais: 22 },
  { Ano: 'Filhotes 1 ano', Mes: 'Abril', RotinaMensal: 'Vacinação de reforço', QtdAnimais: 52 },
  {
    Ano: 'Filhotes 1 ano',
    Mes: 'Maio',
    RotinaMensal: 'Monitoramento do comportamento alimentar',
    QtdAnimais: 17
  },
  { Ano: 'Filhotes 1 ano', Mes: 'Junho', RotinaMensal: 'Consultas veterinárias', QtdAnimais: 35 },
  { Ano: 'Filhotes 1 ano', Mes: 'Julho', RotinaMensal: 'Exames de fezes', QtdAnimais: 8 },
  { Ano: 'Filhotes 1 ano', Mes: 'Agosto', RotinaMensal: 'Desparasitação e check-up', QtdAnimais: 12 },
  { Ano: 'Filhotes 1 ano', Mes: 'Setembro', RotinaMensal: 'Verificação de atividade física', QtdAnimais: 16 },
  { Ano: 'Filhotes 1 ano', Mes: 'Outubro', RotinaMensal: 'Monitoramento de hidratação', QtdAnimais: 22 },
  { Ano: 'Filhotes 1 ano', Mes: 'Novembro', RotinaMensal: 'Avaliação de saúde bucal', QtdAnimais: 14 },
  {
    Ano: 'Filhotes 1 ano',
    Mes: 'Dezembro',
    RotinaMensal: 'Consulta de revisão e planejamento do próximo ano',
    QtdAnimais: 25
  },
  {
    Ano: 'Filhotes 2 anos',
    Mes: 'Janeiro',
    RotinaMensal: 'Verificação de peso e crescimento',
    QtdAnimais: 18
  },
  { Ano: 'Filhotes 2 anos', Mes: 'Fevereiro', RotinaMensal: 'Desparasitação e check-up', QtdAnimais: 41 },
  { Ano: 'Filhotes 2 anos', Mes: 'Março', RotinaMensal: 'Verificação de pele e pelagem', QtdAnimais: 33 },
  { Ano: 'Filhotes 2 anos', Mes: 'Abril', RotinaMensal: 'Vacinação de reforço', QtdAnimais: 75 },
  {
    Ano: 'Filhotes 2 anos',
    Mes: 'Maio',
    RotinaMensal: 'Monitoramento do comportamento alimentar',
    QtdAnimais: 15
  },
  { Ano: 'Filhotes 2 anos', Mes: 'Junho', RotinaMensal: 'Consultas veterinárias', QtdAnimais: 56 },
  { Ano: 'Filhotes 2 anos', Mes: 'Julho', RotinaMensal: 'Exames de fezes', QtdAnimais: 12 },
  { Ano: 'Filhotes 2 anos', Mes: 'Agosto', RotinaMensal: 'Desparasitação e check-up', QtdAnimais: 34 },
  {
    Ano: 'Filhotes 2 anos',
    Mes: 'Setembro',
    RotinaMensal: 'Verificação de atividade física',
    QtdAnimais: 36
  },
  { Ano: 'Filhotes 2 anos', Mes: 'Outubro', RotinaMensal: 'Monitoramento de hidratação', QtdAnimais: 8 },
  { Ano: 'Filhotes 2 anos', Mes: 'Novembro', RotinaMensal: 'Avaliação de saúde bucal', QtdAnimais: 25 },
  {
    Ano: 'Filhotes 2 anos',
    Mes: 'Dezembro',
    RotinaMensal: 'Consulta de revisão e planejamento do próximo ano',
    QtdAnimais: 28
  },
  {
    Ano: 'Adultos 3-6 anos',
    Mes: 'Janeiro',
    RotinaMensal: 'Verificação de peso e ajustes nutricionais',
    QtdAnimais: 4
  },
  { Ano: 'Adultos 3-6 anos', Mes: 'Fevereiro', RotinaMensal: 'Desparasitação e check-up', QtdAnimais: 27 },
  { Ano: 'Adultos 3-6 anos', Mes: 'Março', RotinaMensal: 'Verificação de pele e pelagem', QtdAnimais: 35 },
  { Ano: 'Adultos 3-6 anos', Mes: 'Abril', RotinaMensal: 'Vacinação de reforço', QtdAnimais: 15 },
  {
    Ano: 'Adultos 3-6 anos',
    Mes: 'Maio',
    RotinaMensal: 'Monitoramento do comportamento e energia',
    QtdAnimais: 36
  },
  { Ano: 'Adultos 3-6 anos', Mes: 'Junho', RotinaMensal: 'Consultas veterinárias', QtdAnimais: 26 },
  { Ano: 'Adultos 3-6 anos', Mes: 'Julho', RotinaMensal: 'Exames de sangue e urina', QtdAnimais: 12 },
  { Ano: 'Adultos 3-6 anos', Mes: 'Agosto', RotinaMensal: 'Desparasitação e check-up', QtdAnimais: 45 },
  {
    Ano: 'Adultos 3-6 anos',
    Mes: 'Setembro',
    RotinaMensal: 'Verificação de atividade física',
    QtdAnimais: 8
  },
  { Ano: 'Adultos 3-6 anos', Mes: 'Outubro', RotinaMensal: 'Monitoramento de hidratação', QtdAnimais: 43 },
  { Ano: 'Adultos 3-6 anos', Mes: 'Novembro', RotinaMensal: 'Avaliação de saúde bucal', QtdAnimais: 12 },
  {
    Ano: 'Adultos 3-6 anos',
    Mes: 'Dezembro',
    RotinaMensal: 'Consulta de revisão e planejamento do próximo ano',
    QtdAnimais: 17
  },
  {
    Ano: 'Idosos 7-10 anos',
    Mes: 'Janeiro',
    RotinaMensal: 'Verificação de peso e ajustes nutricionais',
    QtdAnimais: 23
  },
  { Ano: 'Idosos 7-10 anos', Mes: 'Fevereiro', RotinaMensal: 'Desparasitação e check-up', QtdAnimais: 14 },
  { Ano: 'Idosos 7-10 anos', Mes: 'Março', RotinaMensal: 'Verificação de pele e pelagem', QtdAnimais: 57 },
  { Ano: 'Idosos 7-10 anos', Mes: 'Abril', RotinaMensal: 'Vacinação de reforço', QtdAnimais: 3 },
  {
    Ano: 'Idosos 7-10 anos',
    Mes: 'Maio',
    RotinaMensal: 'Monitoramento do comportamento e energia',
    QtdAnimais: 4
  },
  { Ano: 'Idosos 7-10 anos', Mes: 'Junho', RotinaMensal: 'Consultas veterinárias', QtdAnimais: 25 },
  {
    Ano: 'Idosos 7-10 anos',
    Mes: 'Julho',
    RotinaMensal: 'Exames de sangue e urina semestrais',
    QtdAnimais: 12
  },
  { Ano: 'Idosos 7-10 anos', Mes: 'Agosto', RotinaMensal: 'Desparasitação e check-up', QtdAnimais: 25 },
  {
    Ano: 'Idosos 7-10 anos',
    Mes: 'Setembro',
    RotinaMensal: 'Verificação de atividade física',
    QtdAnimais: 54
  },
  { Ano: 'Idosos 7-10 anos', Mes: 'Outubro', RotinaMensal: 'Monitoramento de hidratação', QtdAnimais: 25 },
  { Ano: 'Idosos 7-10 anos', Mes: 'Novembro', RotinaMensal: 'Avaliação de saúde bucal', QtdAnimais: 25 },
  {
    Ano: 'Idosos 7-10 anos',
    Mes: 'Dezembro',
    RotinaMensal: 'Consulta de revisão e planejamento do próximo ano',
    QtdAnimais: 25
  }
]

const testColumnsData = [
  { Header: 'Idade', accessor: 'Ano' },
  { Header: 'Mês', accessor: 'Mes' },
  { Header: 'Monitoramento', accessor: 'RotinaMensal' },
  { Header: 'Animais', accessor: 'QtdAnimais' }
]

const columnsData = [
  { Header: 'Campanha', accessor: 'QtdAnimais' },
  { Header: 'Idade', accessor: 'tags.Idade[0]' },
  { Header: 'Atendimento', accessor: 'atendimento' },
  { Header: 'Especies', accessor: 'RotinaMensal' },
  { Header: 'Status', accessor: '' }
]

export default function CampaignsTable() {
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    async function buscarCampanhas() {
      try {
        const { data } = await app.service('campaigns').find({
          headers: authHeader()
        })
        setTableData(data)
      } catch (error) {
        console.error('Erro ao buscar campanhas:', error)
      }
    }

    buscarCampanhas()
  }, [])

  const columns = useMemo(() => columnsData, [columnsData])
  const data = useMemo(() => tableData, [tableData])

  const tableInstance = useTable(
    {
      columns,
      data
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, initialState } = tableInstance
  initialState.pageSize = 150

  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const iconColor = useColorModeValue('secondaryGray.500', 'white')
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100')
  return (
    <Card direction="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          Campanhas
        </Text>
        <Flex gap={5} alignItems="center">
          <Link href="/admin/new-campaign">
            <Button
              leftIcon={<FaPlus fontWeight={'500'} size={'18px'} />}
              colorScheme="gray"
              color="gray.400"
              variant="solid"
              fontWeight={'500'}
              borderRadius={'50px'}
              size="md"
            >
              Nova
            </Button>
          </Link>
        </Flex>
        {/* <Menu /> */}
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color="gray.400"
                  >
                    {column.render('Header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row)
            // console.log('row', row)
            return (
              <Tr {...row.getRowProps()} key={index}>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Link href={`/admin/campaign/${row.original.id}`}>
                    <Text color={textColor} fontSize="sm" fontWeight="600">
                      {row.original.subject}
                    </Text>
                    <Text>{row.original.description}</Text>
                  </Link>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Flex alignItems="center">
                    <Text color={textColor} fontSize="sm" fontWeight="700">
                      {row.original.tags.Idade.map((idade) => (
                        <Tag colorScheme="green" key={idade} borderRadius="full" size="sm" mr="px" mb="4px">
                          {idade}
                        </Tag>
                      ))}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Flex align="center">
                    <Text me="10px" color={textColor} fontSize="sm" fontWeight="400">
                      {row.original.tags.Atendimento.map((atendimento) => (
                        <Tag
                          colorScheme="green"
                          key={atendimento}
                          borderRadius="full"
                          size="sm"
                          mr="px"
                          mb="4px"
                        >
                          {atendimento}
                        </Tag>
                      ))}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Flex align="center">
                    <Text me="10px" color={textColor} fontSize="sm" fontWeight="400">
                      {row.original.tags.Especies.map((especie) => (
                        <Tag colorScheme="green" key={especie} borderRadius="full" size="sm" mr="px" mb="4px">
                          {especie}
                        </Tag>
                      ))}
                    </Text>
                  </Flex>
                </Td>

                <Td
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor="transparent"
                >
                  <Text color={textColor} fontSize="sm" fontWeight="400">
                    {row.original.status === 'active' ? (
                      <Icon as={MdCheck} color="green" w="20px" h="20px" />
                    ) : (
                      <Icon as={MdClose} color="red" w="20px" h="20px" />
                    )}
                  </Text>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Card>
  )
}
