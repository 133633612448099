// import React, { useEffect, useState } from 'react'
// import axios from 'axios'
// import ReactMarkdown from 'react-markdown'

// // Chakra imports
// import { Box, Fade, Flex, Text, Select, useColorModeValue, Spinner } from '@chakra-ui/react'
// import Card from 'components/card/Card.js'

// export default function ClientsDash(props) {
//   const { height, weight, labels, ...rest } = props

//   const [loading, setLoading] = useState(true)

//   const [fact, setFact] = useState()

//   useEffect(() => {
//     async function getDogsFacts() {
//       try {
//         const { data } = await axios.get('https://dogapi.dog/api/v2/facts')
//         const { body: dogFact } = data?.data[0].attributes
//         console.table('response', dogFact)

//         const response = await axios.post(
//           `https://generativelanguage.googleapis.com/v1beta/models/gemini-pro:generateContent?key=AIzaSyCbGYGn_zCFLHNxsEVmmcbU9Q6tQVvGICE`,
//           {
//             contents: [
//               {
//                 parts: [
//                   {
//                     text: `traduza para português: ${dogFact} e adicione um título curioso.`
//                   }
//                 ]
//               }
//             ]
//           }
//         )

//         setFact(response.data.candidates[0].content.parts[0].text)
//         setLoading(false)
//       } catch (error) {
//         console.log('Erro: ', error.response.data)
//       }
//     }

//     getDogsFacts()
//   }, [])

//   // Chakra Color Mode
//   const textColor = useColorModeValue('secondaryGray.900', 'white')
//   const cardColor = useColorModeValue('white', 'navy.700')
//   const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset')
//   return (
//     <Flex bg={'white'} borderRadius={'20px'} padding={'20px'} height={'full'}>
//       {loading && <Spinner />}
//       <Fade in={fact}>
//         <Text color={textColor}>
//           <ReactMarkdown>{fact}</ReactMarkdown>
//         </Text>
//       </Fade>
//     </Flex>
//   )
// }

import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import ReactMarkdown from 'react-markdown'

// Chakra imports
import { Box, Fade, Flex, Text, useColorModeValue, Spinner } from '@chakra-ui/react'
import Card from 'components/card/Card.js'

export default function ClientsDash(props) {
  const { height, weight, labels, ...rest } = props

  const [loading, setLoading] = useState(true)
  const [fact, setFact] = useState()
  const textRef = useRef(null) // Referência para o elemento de texto

  useEffect(() => {
    async function getDogsFacts() {
      try {
        const { data } = await axios.get('https://dogapi.dog/api/v2/facts')
        const { body: dogFact } = data?.data[0].attributes

        const response = await axios.post(
          `https://generativelanguage.googleapis.com/v1beta/models/gemini-pro:generateContent?key=AIzaSyCbGYGn_zCFLHNxsEVmmcbU9Q6tQVvGICE`,
          {
            contents: [
              {
                parts: [
                  {
                    text: `traduza para português: ${dogFact} e adicione um título curioso.`
                  }
                ]
              }
            ]
          }
        )

        setFact(response.data.candidates[0].content.parts[0].text)
        setLoading(false)
      } catch (error) {
        console.log('Erro: ', error.response.data)
      }
    }

    getDogsFacts()
  }, [])

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const cardColor = useColorModeValue('white', 'navy.700')
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset')

  return (
    <Flex bg={'white'} borderRadius={'20px'} padding={'20px'} height={'full'}>
      {loading && <Spinner />}
      <Fade in={fact}>
        <Box
          overflowY="auto"
          maxHeight="160px" // Altura máxima do componente
          css={{
            '&::-webkit-scrollbar': {
              width: '8px'
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent'
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'gray.300',
              borderRadius: '8px'
            }
          }}
        >
          <Text color={textColor}>
            <ReactMarkdown>{fact}</ReactMarkdown>
          </Text>
        </Box>
      </Fade>
    </Flex>
  )
}
