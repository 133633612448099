export function formatDateTime(dateString) {
  const date = new Date(dateString)

  // Obtem o dia e o mês
  const day = date.getDate()
  const month = date.toLocaleString('default', { month: 'short' }) // Formato curto do mês (Jul)

  // Formata a hora no formato 19:56:47
  const time = date.toLocaleTimeString('default', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false // Para manter no formato 24h
  })

  // Monta a string final no formato 27/Jul • 19:56:47
  return `${day} ${month} • ${time}`
}
