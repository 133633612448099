import React, { useState, useEffect } from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Flex,
  HStack,
  Icon,
  Input,
  Link,
  Textarea,
  SimpleGrid,
  Select,
  Stack,
  useToast,
  useColorModeValue,
  Text,
  IconButton,
  Tag
} from '@chakra-ui/react'
import { useParams, useLocation } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import Card from 'components/card/Card'
import AnimalsForm from './animaisForm'
import { getUserAccountId } from 'hooks/authService'
import { FaDog, FaEdit, FaPlus } from 'react-icons/fa'
import { formatPhoneNumber } from 'hooks/formatPhoneNumber'

export default function ClientForm() {
  const textColor = useColorModeValue('navy.700', 'white')

  const location = useLocation()
  const addAnimalForm = location.pathname.includes('newAnimal')
  // console.log('addAnimalForm', addAnimalForm)

  const account_id = getUserAccountId()
  const [editable, setEditable] = useState(false)
  const [addAnimals, setAddAnimals] = useState(false)
  const [addClientAnimal, setAddClientAnimal] = useState(false)

  const { client_id: clientIdFromParams } = useParams() || ''
  const [client_id, setClientId] = useState(clientIdFromParams)

  // console.log('useParams', clientIdFromParams)

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    account_id: account_id,
    whatsapp: '',
    email: '',
    owner_data: {
      profile: []
    }
  })

  useEffect(() => {
    if (addAnimalForm) {
      setAddAnimals(true)
    }
  }, [])

  useEffect(() => {
    if (!clientIdFromParams) {
      setEditable(true)
    }
  }, [addClientAnimal, account_id, client_id, formData, clientIdFromParams])

  useEffect(() => {
    async function getClientData() {
      try {
        const { data } = await axios.get(`/clients/${client_id}`, { headers: authHeader() })
        setFormData(data)
      } catch (error) {
        console.log('Erro ao carregar dados do cliente: ', error)
      }
    }

    if (client_id) {
      getClientData()
    }
  }, [])

  const [sendAlert, setSendAlert] = useState('')

  const handleCheckboxProfileChange = (event) => {
    const value = event.target.value
    setFormData((prevContactProfile) => {
      const newClientProfile = JSON.parse(JSON.stringify(prevContactProfile))

      if (event.target.checked) {
        newClientProfile.owner_data.profile.push(value)
      } else {
        newClientProfile.owner_data.profile = newClientProfile.owner_data.profile.filter(
          (item) => item !== value
        )
      }

      return newClientProfile
    })
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (client_id) {
      try {
        const response = await axios.patch(`/clients/${client_id}`, formData, {
          headers: authHeader()
        })
        console.log('Dados atualizados com sucesso!', response.data)
        setSendAlert('success')
        setAddClientAnimal(true)
      } catch (error) {
        setSendAlert('error')
        console.error('Ocorreu um erro ao enviar os dados:', error.response.data)
      }
    } else {
      try {
        const response = await axios.post('/clients', formData, {
          headers: authHeader()
        })
        console.log('Cliente cadastrado com sucesso!', response.data)
        setClientId(response.data.id)
        setAddClientAnimal(true)
        setSendAlert('success')
        setTimeout(() => {
          window.location.href = `/admin/client/${response.data.id}/addAnimal` // Corrigido: usar response.data.id
        }, 1000)
      } catch (error) {
        console.error('Ocorreu um erro ao cadastrar o cliente:', error.response.data)
        setSendAlert('error')
      }
    }
    console.log(formData)
  }

  return (
    <Flex gap={'20px'} flexDirection={'column'}>
      <Card>
        <Flex justifyContent={'space-between'} mb="8px">
          <Flex gap={'10px'} alignItems={'center'}>
            <Text display="flex" fontSize="xl" fontWeight="800" color={textColor}>
              {clientIdFromParams ? `${formData.first_name} ${formData.last_name}` : 'Novo cliente'}
            </Text>
            {clientIdFromParams && (
              <Tag
                colorScheme="green"
                height={'fit-content'}
                p="5px 15px"
                fontSize={'12px'}
                borderRadius={'50px'}
              >
                Ativo
              </Tag>
            )}
          </Flex>
          <Flex gap="10px">
            <IconButton
              colorScheme="blue"
              background="#49BFFF"
              aria-label="Call Segun"
              size="sm"
              p="1px"
              onClick={() => setEditable(!editable)}
              icon={<Icon as={FaEdit} />}
            />
            <IconButton
              colorScheme="blue"
              background="#49BFFF"
              aria-label="Call Segun"
              size="sm"
              p="1px"
              onClick={() => setAddAnimals(!addAnimals)}
              icon={<Icon as={FaPlus} />}
            />
          </Flex>
        </Flex>
        {clientIdFromParams && (
          <SimpleGrid columns={'3'} mb={'20px'}>
            <Flex flexDirection={'column'}>
              <Text>{`${formatPhoneNumber(formData.whatsapp)}`}</Text>
              <Text>{`${formData.email}`}</Text>
            </Flex>
            <Flex flexDirection={'column'}>
              {formData.owner_data.address && formData.owner_data.neighborhood && (
                <Text>{`${formData.owner_data.address} - ${formData.owner_data.neighborhood}`}</Text>
              )}
              {formData.owner_data.zipCode && formData.owner_data.city && (
                <Text>{`${formData.owner_data.zipCode} - ${formData.owner_data.city}, ${formData.owner_data.state}`}</Text>
              )}
            </Flex>
            {/* <Flex flexDirection={'column'}>
              <Text>Último atendimento</Text>
              <Text>Abordagens</Text>
            </Flex> */}
          </SimpleGrid>
        )}
        {editable && (
          <form onSubmit={handleSubmit}>
            <FormControl>
              <SimpleGrid columns={{ base: '1', md: '2' }} gap="0px 20px">
                <Flex direction="column">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Nome
                  </FormLabel>
                  <Input
                    value={formData.first_name}
                    name="first_name"
                    onChange={handleChange}
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    placeholder="Nome"
                    mb="24px"
                    fontWeight="500"
                    size="md"
                  />
                </Flex>
                <Flex direction="column">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Sobrenome
                  </FormLabel>
                  <Input
                    value={formData.last_name}
                    name="last_name"
                    onChange={handleChange}
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    placeholder="Sobrenome"
                    mb="24px"
                    fontWeight="500"
                    size="md"
                  />
                </Flex>
                <Flex direction="column">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    WhatsApp
                  </FormLabel>
                  <Input
                    value={formData.whatsapp}
                    name="whatsapp"
                    onChange={handleChange}
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    placeholder="WhatsApp"
                    mb="24px"
                    fontWeight="500"
                    size="md"
                  />
                </Flex>
                <Flex direction="column">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Email
                  </FormLabel>
                  <Input
                    value={formData.email}
                    name="email"
                    onChange={handleChange}
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: '0px', md: '0px' }}
                    placeholder="email"
                    type="email"
                    mb="24px"
                    fontWeight="500"
                    size="md"
                  />
                </Flex>
              </SimpleGrid>
              <FormLabel display="flex" ms="4px" fontSize="md" fontWeight="800" color={textColor} mb="8px">
                Perfil do tutor
              </FormLabel>
              <Stack
                spacing={1}
                style={{ display: 'grid', gap: '10px' }}
                gridTemplateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
                mb={10}
              >
                {['Banho e Tosa', 'Veterinário', 'Preventivo'].map((value) => (
                  <Checkbox
                    key={value}
                    value={value}
                    isChecked={formData?.owner_data?.profile?.includes(value)}
                    onChange={handleCheckboxProfileChange}
                  >
                    {value}
                  </Checkbox>
                ))}
              </Stack>

              <Button
                type="submit"
                fontSize="sm"
                background="#49BFFF"
                color="white"
                fontWeight="800"
                w="100%"
                h="50"
                mb="24px"
                borderRadius="50px"
              >
                {client_id ? 'Atualizar Cliente' : 'Adicionar Cliente'}
              </Button>
              {sendAlert === 'success' && (
                <Alert status="success">
                  <AlertIcon />
                  <AlertTitle>Sucesso!</AlertTitle>
                  <AlertDescription>Dados enviados com sucesso!</AlertDescription>
                </Alert>
              )}
            </FormControl>
          </form>
        )}
      </Card>

      {addAnimals && <AnimalsForm client_id={client_id} account_id={formData.account_id} />}
    </Flex>
  )
}
