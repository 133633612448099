import React, { useState, useEffect, useRef } from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  FormHelperText,
  Button,
  FormControl,
  FormLabel,
  Flex,
  Textarea,
  SimpleGrid,
  Select,
  useColorModeValue,
  Text
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import axios from 'api/axios'
import authHeader from 'hooks/authHeader'
import Card from 'components/card/Card'
import { getUserAccountId } from 'hooks/authService'
import AddTermToInput from 'components/addTermToInput'

export default function ScriptForm({ campaign_id, campaignName, refreshParent, data }) {
  const textColor = useColorModeValue('navy.700', 'white')
  const account_id = getUserAccountId()

  const [refresh, setRefresh] = useState('')
  const [sendAlert, setSendAlert] = useState('')

  console.log('scriptForm data: ', data)
  const { id: script_id } = (data && data) || {}

  useEffect(() => {
    updateData()
  }, [data])

  useEffect(() => {
    refreshParent(refresh)
  }, [refresh])

  function refreshParent(refresh) {
    setRefresh(refresh)
  }

  function updateData() {
    if (!data) return
    setFormData(data)
    setTextAreaValue(data.message)
  }
  useEffect(() => {
    console.log('formdata: ', formData)
    console.log('scriptForm campaign_id: ', campaign_id)
  }, [data, campaign_id])

  const textareaRef = useRef(null)
  const [textAreaValue, setTextAreaValue] = useState('')

  const [formData, setFormData] = useState({
    subject: campaignName,
    message: '',
    campaign_id: campaign_id,
    account_id: account_id,
    step: ''
  })

  useEffect(() => {
    if (sendAlert === 'success') {
      setTimeout(() => {
        setSendAlert('')
      }, 3000)
    }
  }, [sendAlert])

  const handleChange = (event) => {
    const { name, value } = event.target
    setTextAreaValue(event.target.value)
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))

    if (name === 'step') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        subject: scriptsContext[value - 1].subject
      }))
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (script_id) {
      try {
        const response = await axios.patch(`/scripts/${script_id}`, formData, {
          headers: authHeader()
        })
        setRefresh(response.data)
        console.log('Dados atualizados com sucesso!', response.data)
        setSendAlert('success')
      } catch (error) {
        setSendAlert('error')
        console.error('Ocorreu um erro ao enviar os dados:', error.response.data)
      }
    } else {
      try {
        const response = await axios.post('/scripts', formData, {
          headers: authHeader()
        })
        console.log('Script cadastrado com sucesso!', response.data)
        // setRefresh(response.data)
        refreshParent(response.data)
        setSendAlert('success')
      } catch (error) {
        console.error('Ocorreu um erro ao cadastrar o script:', error.response.data)
        setSendAlert('error')
      }
    }
    console.log(formData)
  }

  const scriptsContext = [
    { subject: 'Abordagem inicial', step: 1 },
    { subject: '2ª Tentativa', step: 2 },
    { subject: '3ª Tentativa', step: 3 },
    { subject: 'Encerramento', step: 4 }
  ]

  return (
    <Card>
      <Text fontWeight={600} mb="12px">
        Nova mensagem
      </Text>
      <FormControl>
        <SimpleGrid rows={2} gap="0px 20px">
          <Flex direction="column">
            <Select
              value={formData.step}
              name="step"
              isRequired={true}
              onChange={handleChange}
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              placeholder="Contexto da abordagem"
              mb="24px"
              fontWeight="500"
              size="md"
              defaultValue={'Primeira abordagem'}
            >
              {scriptsContext.map((script) => (
                <option value={script.step} key={script.step}>
                  {script.subject}
                </option>
              ))}
            </Select>
          </Flex>
        </SimpleGrid>

        <FormControl display="flex" flexDirection={'column'}>
          <FormLabel display="flex" fontSize="sm" color={textColor} mb="8px">
            Menssagem
          </FormLabel>
          <Textarea
            name="message"
            value={textAreaValue}
            onChange={handleChange}
            isRequired={true}
            variant="filled"
            fontSize="sm"
            ms={{ base: '0px', md: '0px' }}
            placeholder="Olá {{TutorNome}}, tudo bem?"
            fontWeight="500"
            size="md"
            ref={textareaRef}
          />
          <FormHelperText mb="24px">
            <AddTermToInput
              textToCopy="{{TutorNome}}"
              textareaRef={textareaRef}
              setTextAreaValue={setTextAreaValue}
              mr="5px"
            />
            <AddTermToInput
              textToCopy="{{AnimalNome}}"
              textareaRef={textareaRef}
              setTextAreaValue={setTextAreaValue}
            />
            <AddTermToInput
              textToCopy="{{AnimalIdade}}"
              textareaRef={textareaRef}
              setTextAreaValue={setTextAreaValue}
            />
            <AddTermToInput
              textToCopy="{{o/a}}"
              textareaRef={textareaRef}
              setTextAreaValue={setTextAreaValue}
            />
            <AddTermToInput
              textToCopy="{{ele/ela}}"
              textareaRef={textareaRef}
              setTextAreaValue={setTextAreaValue}
            />
            <AddTermToInput
              textToCopy="{{NomeEmpresa}}"
              textareaRef={textareaRef}
              setTextAreaValue={setTextAreaValue}
            />
            <Text fontSize={'sm'} mt="-10px" pl="10px">
              Clique nas opções acima para adicionar os termos na sua mensagem.
            </Text>
          </FormHelperText>
        </FormControl>

        <Button
          type="submit"
          fontSize="sm"
          background="#49BFFF"
          color="white"
          fontWeight="800"
          w="100%"
          h="50"
          mb="24px"
          borderRadius="50px"
          onClick={(e) => handleSubmit(e)}
        >
          Salvar
        </Button>
        {sendAlert === 'success' && (
          <Alert status="success">
            <AlertIcon />
            <AlertTitle>Sucesso!</AlertTitle>
            <AlertDescription>Dados enviados com sucesso!</AlertDescription>
          </Alert>
        )}
      </FormControl>
    </Card>
  )
}
