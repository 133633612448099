// Chakra imports
import {
  Avatar,
  Box,
  Icon,
  Link,
  Select,
  SimpleGrid,
  Text,
  background,
  useColorModeValue,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Input,
  InputGroup,
  InputRightElement,
  Textarea
} from '@chakra-ui/react'

// Custom components
import MiniCalendar from 'components/calendar/MiniCalendar'
import Tasks from './components/Tasks'
import MiniStatistics from 'components/card/MiniStatistics'
import IconBox from 'components/icons/IconBox'
import React, { useState, useEffect } from 'react'
import { MdAddTask, MdAttachMoney, MdBarChart, MdFileCopy, MdPeople, MdVerifiedUser } from 'react-icons/md'
import { logout, getUserAuthentication, getUserAuthenticationRole } from 'hooks/authService'
import EmergencyContact from './components/EmergencyContact'
import VolunteerProfile from './components/VolunteerProfile'
import WatchedProfile from './components/WatchedProfile'
import WatchedIntro from './components/WatchedIntro'
import TelemedicineRoom from './components/TelemedicineRoom'
import authHeader from 'hooks/authHeader'
import NewAccountForm from '../../admin/formAccount'
import axios from 'api/axios'
import { getUserAccount } from 'hooks/authService'
import Dashboard from 'layouts/admin'
import VetDashboard from './components/LastApproachs'
import LastApproachs from './components/LastApproachs'
import PieChart from 'components/charts/PieChart'
import Conversion from './components/PieCard'
import ClientsDash from './components/ClientsDash'
import AnimalFacts from './components/AnimalsFacts'
import ConectionStatus from './components/ConectionStatus'
import QueueSwitch from './components/QueueSwitch'
import Support from './components/support'
import { Approach } from 'views/admin/formApproach'

export default function AdminDefault() {
  const textColor = useColorModeValue('navy.700', 'white')

  const userData = getUserAuthentication()

  let userRole, main_account_id

  if (userData) {
    userRole = userData?.user?.role
    main_account_id = userData?.user?.user_data?.main_account_id
  }

  const [animalsLists, setAnimalsLists] = useState({})
  const [approachLists, setApproachLists] = useState({})
  const [clientsList, setClientsList] = useState({})
  const [accountData, setAccountData] = useState({})

  useEffect(() => {
    getUserAccount()
    getAccountClients()
    getAccountApproachs()
    getAccountAnimals()
  }, [])

  async function getUserAccount() {
    if (!main_account_id) return
    try {
      const response = await axios.get(`/accounts/${main_account_id}`, { headers: authHeader() })
      setAccountData(response.data)
    } catch (error) {
      console.error('Crie uma nova conta.', error)
    }
  }

  async function getAccountClients() {
    if (!main_account_id) return
    try {
      const response = await axios.get(`/clients?account_id=${main_account_id}`, { headers: authHeader() })
      setClientsList(response.data)
    } catch (error) {
      console.error('Crie uma nova conta.', error)
    }
  }

  async function getAccountAnimals() {
    if (!main_account_id) return
    try {
      const response = await axios.get(`/animals?account_id=${main_account_id}`, {
        query: {
          $limit: 50000
        },
        headers: authHeader()
      })
      setAnimalsLists(response.data)
    } catch (error) {
      console.error('Crie uma nova conta.', error)
    }
  }

  async function getAccountApproachs() {
    if (!main_account_id) return
    try {
      const response = await axios.get(`/approachs?account_id=${main_account_id}`, {
        query: {
          // $limit: 5,
          $sort: {
            id: -1
          }
        },
        headers: authHeader()
      })
      setApproachLists(response.data)
    } catch (error) {
      console.error('Crie uma nova conta.', error)
    }
  }

  useEffect(() => {
    console.log('Conta autenticada:', accountData)
    // console.log('clientsList:', clientsList)
  }, [accountData, clientsList])

  console.log('approachLists', approachLists)

  return (
    <Box pt={{ base: '50px', md: '80px', xl: '80px' }}>
      {accountData && accountData.status === 'active' && (
        <SimpleGrid rows={2} gap="20px">
          <SimpleGrid columns={{ sm: 1, xl: 2 }} gap="20px">
            <ClientsDash clients={clientsList} approachs={approachLists} animals={animalsLists} />
            <SimpleGrid columns={{ sm: 1, xl: 2 }} gap="20px">
              <AnimalFacts />
              <Flex flexDirection={'column'} gap={'20px'}>
                <QueueSwitch />
                <ConectionStatus settings={accountData.settings} />
                <Support />
              </Flex>
            </SimpleGrid>
          </SimpleGrid>

          <>
            <SimpleGrid columns={2} gap="20px">
              <LastApproachs approachs={approachLists} />
              <Tasks accountId={main_account_id} approachs={approachLists} />
              <SimpleGrid columns={1} gap="20px">
                {/* <MiniCalendar h="100%" minW="100%" selectRange={false} /> */}
              </SimpleGrid>
            </SimpleGrid>
            {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} gap="20px">
              <Text>Suporte</Text>
              <Text>Sugestões</Text>
              {/* <Text>Novidades Mundo Pet</Text> */}
            {/* <Text>Anúncios representantes</Text> */}
            {/* <Text>Comunicação Otten Sales: Mídias sociais, contrate um atendente</Text> */}
            {/*</SimpleGrid> */}
          </>
        </SimpleGrid>
      )}

      {accountData && accountData.status === 'new' && <NewAccountForm accountData={accountData} />}
    </Box>
  )
}
