import React from 'react'
import Card from 'components/card/Card'
import { MdAddBusiness, MdImportContacts, MdImportExport, MdPerson, MdPersonAdd } from 'react-icons/md'
import { Avatar, Box, Flex, Link, Icon, Text, useColorModeValue, SimpleGrid } from '@chakra-ui/react'
import CalendarCareTable from './components/calendarCareTable'
import LastActivities from './components/lastActivities'
import NextActivities from './components/nextActivities'
import CampaignForm from '../formCampaing/components/campaignForm'

const App = () => {
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid columns={2} gap={5}>
        <CalendarCareTable />
        <SimpleGrid rows={4} gap={5}>
          <CampaignForm />
          <NextActivities />
          <LastActivities />
          <LastActivities />
        </SimpleGrid>
      </SimpleGrid>
    </Box>
  )
}

export default App
