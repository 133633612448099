import React from 'react'
import ReactApexChart from 'react-apexcharts'

class PieChart extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      chartData: [],
      chartOptions: {},
      h: '',
      w: ''
    }
  }

  componentDidMount() {
    this.setState({
      chartData: this.props.chartData,
      chartOptions: this.props.chartOptions,
      h: this.props.h,
      w: this.props.w
    })
  }

  render() {
    return (
      <ReactApexChart
        options={this.state.chartOptions}
        series={this.state.chartData}
        type="pie"
        width={this.state.w}
        height={this.state.h}
      />
    )
  }
}

export default PieChart
